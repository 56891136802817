import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Tooltip } from 'antd';

import CopySectionIcon from '../../../../../../../../resources/icons/CopySectionIcon';
import { colorPalette } from '../../../../../../../../resources/styles/colorPalette';

import { getTooltipOverlayClass } from '../../../../../../../../utils/helpers/themeHelpers';
import { useSortedOptimizerKeywords } from '../../../../../../../../utils/hooks/useSortedOptimizerKeywords';
import { getOptimizerRightBlockWrapper } from '../optimizerRightBlockHelpers';

import { optimizerKeywordsSortKeys } from '../../../../../../../../constants/optimizerKeywordsSortItems';

import { selectDarkMode } from '../../../../../../../../userBrowserSettings/store/browserSettings.selectors';
import { selectKeywordsSortKey } from '../../../../store/contentOptimizerContent.selectors';

const classes = {
    exportKeywordsBtn: 'optimizer-keywords-icon-btn',
    tooltipOverlay: 'optimizer-keywords-tooltip-overlay',
};

const OptimizerKeywordsCopyButton = () => {
    const darkMode = useSelector(selectDarkMode);

    const sortedKeywords = useSortedOptimizerKeywords();
    const sortKey = useSelector(selectKeywordsSortKey);

    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        const textToCopy = sortedKeywords
            .filter((item) => {
                if (optimizerKeywordsSortKeys.heading === sortKey) {
                    return item.heading_presence > 0;
                } else if (optimizerKeywordsSortKeys.unused === sortKey) {
                    return item.used === 0;
                }

                return true;
            })
            ?.map((item) => {
                return item?.keyword;
            })
            ?.join('\n');

        navigator.clipboard.writeText(textToCopy);
        setIsCopied(true);
    };

    const handleTooltipOpenChange = () => {
        setIsCopied(false);
    };

    return (
        <Tooltip
            title={isCopied ? 'Copied!' : 'Copy to clipboard'}
            overlayClassName={`${classes.tooltipOverlay} ${getTooltipOverlayClass(darkMode)}`}
            placement='top'
            onOpenChange={handleTooltipOpenChange}
            getPopupContainer={getOptimizerRightBlockWrapper}
        >
            <Button
                className={`${classes.exportKeywordsBtn}
                ${darkMode ? 'dark-mode' : ''}`}
                type='primary'
                size='large'
                ghost
                onClick={handleCopy}
            >
                <CopySectionIcon color={colorPalette.colorPrimary} size={18} />
            </Button>
        </Tooltip>
    );
};

export default OptimizerKeywordsCopyButton;
