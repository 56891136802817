import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Popconfirm, Tooltip, Typography } from 'antd';
import moment from 'moment';

import {
    selectCurrentSubscription,
    selectCurrentSubscriptionCancelAtPeriodEnd,
} from '../../store/billingSettings.selectors';
import {
    selectDarkMode,
    selectUserOrganization,
} from '../../../../../../userBrowserSettings/store/browserSettings.selectors';
import { updateCurrentSubscriptionCancelAtPeriodEnd } from '../../store/billingSettings.actions';

import { axiosAPI } from '../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../../../utils/helpers/openNotification';
import { openBasicErrorNotification } from '../../../../../../utils/helpers/openBasicErrorNotification';
import SubscriptionHelper from '../../../../auth/helpers/subscription.helper';

import { paymentsPathes } from '../../../../../../constants/queryPathes';
import { notificationType } from '../../../../../../constants/notificationType';
import { statusCodes } from '../../../../../../constants/statusCodes';

const { Title } = Typography;

const classes = {
    subscriptionInfoWrapper: 'subscription-info-wrapper d-flex flex-center',
    subscriptionWarningWrapper: 'subscription-warning-wrapper',

    textAccent: 'fw-600',
    textSemiAccent: 'fw-500',
    popconfirm: 'delete-popconfirm',
    popconfirmDark: 'delete-popconfirm-dark',

    tooltipOverlay: 'tooltip-overlay legacy-plan-tooltip',
    tooltipOverlayDark: 'tooltip-overlay-dark',

    textDotted: 'text-decoration-dotted',
};

const SubscriptionInfo = () => {
    const currentSubscription = useSelector(selectCurrentSubscription);
    const organization = useSelector(selectUserOrganization);
    const darkMode = useSelector(selectDarkMode);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const isTrial = SubscriptionHelper.isTrial(currentSubscription, organization);

    const currentSubscriptionCanceled = useSelector(selectCurrentSubscriptionCancelAtPeriodEnd);

    if (currentSubscriptionCanceled || !currentSubscription || !currentSubscription?.paid) {
        return null;
    }

    const handleCancelSubscription = () => {
        setIsLoading(true);

        axiosAPI
            .post(`${paymentsPathes.cancelSubscription}`, {}, { ...getAxiosHeaders() })
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    dispatch(
                        updateCurrentSubscriptionCancelAtPeriodEnd(
                            result?.data?.cancel_at_period_end
                        )
                    );
                    openNotification(
                        notificationType.success,
                        'Success',
                        'Subscription successfully canceled'
                    );
                }
            })
            .catch(() => {
                openBasicErrorNotification();
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div>
            <div className={classes.subscriptionInfoWrapper}>
                <Title level={5}>
                    You're subscribed to{' '}
                    {isTrial ? (
                        <>
                            a <span className={classes.textAccent}>free trial </span>
                            of{' '}
                        </>
                    ) : null}
                    <span className={classes.textAccent}>
                        {currentSubscription?.plan?.name || 'Starter'}
                    </span>{' '}
                    for{' '}
                    <span className={classes.textAccent}>
                        ${currentSubscription?.plan?.amount / 100 || 0}/
                        {currentSubscription?.plan?.interval}
                    </span>
                    . The next billing period starts on{' '}
                    <span className={classes.textAccent}>
                        {currentSubscription
                            ? moment(currentSubscription?.current_period_end).format('LL')
                            : ''}
                        .
                    </span>
                </Title>
                {isTrial && (
                    <Popconfirm
                        placement='bottomRight'
                        title='Are you sure you want to cancel your subscription?'
                        onConfirm={handleCancelSubscription}
                        okText='Yes'
                        okButtonProps={{ loading: isLoading }}
                        cancelText='Cancel'
                        overlayClassName={`${classes.popconfirm} ${
                            darkMode ? classes.popconfirmDark : ''
                        }`}
                        autoAdjustOverflow
                    >
                        {' '}
                        <Button type='primary' loading={isLoading}>
                            Cancel
                        </Button>
                    </Popconfirm>
                )}
            </div>
            {currentSubscription.plan.name.includes('Legacy') && (
                <>
                    <div
                        className={`${classes.subscriptionInfoWrapper} ${classes.subscriptionWarningWrapper}`}
                    >
                        <Title level={5}>
                            You’re currently on a legacy plan that is no longer available.{' '}
                            <Tooltip
                                placement='top'
                                title='We may occasionally discontinue plans as our features improve. However, we truly appreciate your business and you can keep your current plan for now. If your plan is set to change, we’ll notify you in advance. Our latest plans include additional AI credits, allowing you to access and use more of our AI features. To upgrade or switch your plan, please see our current options below.'
                                trigger={['hover']}
                                overlayClassName={`${classes.tooltipOverlay} ${
                                    darkMode ? classes.tooltipOverlayDark : ''
                                }`}
                            >
                                <span className={`${classes.textDotted} ${classes.textSemiAccent}`}>
                                    View Details
                                </span>
                            </Tooltip>
                        </Title>
                        {isTrial && (
                            <Popconfirm
                                placement='bottomRight'
                                title='Are you sure you want to cancel your subscription?'
                                onConfirm={handleCancelSubscription}
                                okText='Yes'
                                okButtonProps={{ loading: isLoading }}
                                cancelText='Cancel'
                                overlayClassName={`${classes.popconfirm}
                                               ${darkMode ? classes.popconfirmDark : ''}`}
                                autoAdjustOverflow
                            >
                                {' '}
                                <Button type='primary' loading={isLoading}>
                                    Cancel
                                </Button>
                            </Popconfirm>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default SubscriptionInfo;
