import { useEffect, useRef } from 'react';

const manageModalWrapperScroll = (currentRef = null, scrollPaddingValue = 20, maxHeight = 440) => {
    if (currentRef) {
        const maxWrapperHeight = parseInt(maxHeight, 10);
        const wrapperHeight = currentRef.offsetHeight;

        currentRef.style.setProperty('max-height', `${maxWrapperHeight}px`);
        currentRef.style.setProperty(
            'padding-right',
            `${wrapperHeight >= maxWrapperHeight ? scrollPaddingValue : 0}px`
        );
    }
};

export const useDynamicPaddingRef = (maxHeight = null, paddingValue = 20) => {
    const formWrapperRef = useRef(null);

    useEffect(() => {
        manageModalWrapperScroll(formWrapperRef?.current, paddingValue, maxHeight);
    }, [formWrapperRef?.current?.offsetHeight, paddingValue, maxHeight]);

    return formWrapperRef;
};
