export const creditType = {
    AI: 'AI',
    OPTIMIZER: 'OPTIMIZER',
};

export const optimizerCreditsOptions = [
    {
        value: '5',
        label: '5',
    },
    {
        value: '10',
        label: '10',
    },
    {
        value: '15',
        label: '15',
    },
    {
        value: '20',
        label: '20',
    },
    {
        value: '25',
        label: '25',
    },
    {
        value: '30',
        label: '30',
    },
    {
        value: '35',
        label: '35',
    },
    {
        value: '40',
        label: '40',
    },
    {
        value: '45',
        label: '45',
    },
    {
        value: '50',
        label: '50',
    },
];

export const aiCreditsOptions = [
    {
        value: '10',
        label: '10',
    },
    {
        value: '20',
        label: '20',
    },
    {
        value: '30',
        label: '30',
    },
    {
        value: '40',
        label: '40',
    },
    {
        value: '50',
        label: '50',
    },
    {
        value: '60',
        label: '60',
    },
    {
        value: '70',
        label: '70',
    },
    {
        value: '80',
        label: '80',
    },
    {
        value: '90',
        label: '90',
    },
    {
        value: '100',
        label: '100',
    },
];
