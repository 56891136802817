export const colorPalette = {
    colorPrimary: '#327EEF',
    colorError: '#EF3E32',
    colorSuccess: '#20C752',
    colorBadgeGray: '#AAAAAA',
    colorSelectedHighlight: '#327EEF66',
    basicGray: '#728192',
    layoutBackground: {
        light: '#F6F8FA',
        dark: '#0B0A0F',
    },
    cardBackground: {
        light: '#FFFFFF',
        dark: '#16161C',
    },
    inputBackground: {
        light: '#FFFFFF',
        dark: '#ffffff0f',
    },
    modalBackground: {
        light: '#FFFFFF',
        dark: '#0B0A0F',
    },
    radioBackground: {
        light: '#FFFFFF',
        dark: 'transparent',
    },
    modalMask: {
        light: '#72819240',
        dark: '#16161d66',
    },
    modalBoxShadow: {
        light: '0px 20px 70px #1f1f1fcc',
        dark: '0px 20px 170px #ffffff21',
    },
    dropdownBackground: {
        light: '#FFFFFF',
        dark: '#0B0A0F',
    },
    switchSecondaryBackground: {
        light: '#9199AD',
        dark: '#242429',
    },
    switchHoverBackground: {
        light: '#327eef1a',
        dark: '#327eef1a',
    },
    segmentedBackground: {
        light: '#DFE3EC',
        dark: '#16161C',
    },
    segmentedElevated: {
        light: '#FFFFFF',
        dark: '#ffffff0f',
    },
    tableHeaderBackground: {
        light: '#F6F8FA',
        dark: '#ffffff1a',
    },
    borderColor: {
        light: '#E5E7EB',
        dark: '#2A2D2F',
    },
    colorPrimaryBg: {
        light: '#F3F7FD',
        dark: '#1b2131',
    },
    colorPrimaryBgDisabled: {
        light: '#C3CFE4',
        dark: '#2D2D33',
    },
    colorPrimaryBgHover: {
        light: '#000E4C',
        dark: '#000E4C',
    },
    textColor: {
        light: '#728192',
        dark: '#9D9D9F',
    },
    textAccentColor: {
        light: '#1F1F1F',
        dark: '#FFFFFF',
    },
    textDisabledColor: {
        light: '#C3CFE4',
        dark: '#5C5C5C',
    },
    textDisabledBtnColor: '#FFFFFF',
    iconColor: {
        light: '#9199AD',
        dark: '#9D9D9F',
    },
    noScoreBadgeColor: {
        light: '#E4E7EB',
        dark: '#9D9D9F',
    },
    secondaryBadgeBg: {
        light: '#DFE3EC',
        dark: '#FFFFFF1A',
    },
    drawerBackground: {
        light: '#FFFFFF',
        dark: '#16161C',
    },
    trendGraphColor: {
        increase: '#21CC90',
        decrease: '#CC4621',
        neutral: '#9D9D9F',
        gradient: {
            firstColor: {
                increase: {
                    light: '#21CC90',
                    dark: '#1EA11D',
                },
                decrease: {
                    light: '#CC4621',
                    dark: '#D52624',
                },
                neutral: {
                    light: '#9D9D9F',
                    dark: '#9D9D9F',
                },
            },
            secondColor: {
                increase: {
                    light: '#21CC90',
                    dark: '#21CC90',
                },
                decrease: {
                    light: '#CC4621',
                    dark: '#CC4621',
                },
                neutral: {
                    light: '#9D9D9F',
                    dark: '#9D9D9F',
                },
            },
        },
    },
    usageChartColor: {
        firstChart: {
            first: '#21CC90',
            second: '#993399',
        },
        secondChart: {
            first: '#DEB87E',
            second: '#008080',
        },
    },
    helpActiveIcon: {
        light: '#FFFFFF',
        dark: '#16161D',
    },
};
