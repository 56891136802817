import { getArrayOptionsFromObject } from './getArrayOptionsFromObject';
import { geolocationConstants } from '../../constants/geolocationConsts';

const EMPTY_VALUE = '';
const SEARCH_ENGINE_LINK_EXTRACTION_PATTERN = /\(([^)]+)\)/;
const LOCATION_NAME_EXTRACTION_PATTERN = /\s*\(.*?\)$/;

const getCountryDetails = (countryCode, countries = {}, featuredCountries = []) => {
    if (!countryCode || typeof countries !== 'object' || !Object.keys(countries).length) {
        return {};
    }

    const arrayOptions = getArrayOptionsFromObject({
        values: countries,
        featured: featuredCountries,
    });

    return arrayOptions?.find((option) => isMatchingCountryCode(option, countryCode)) || {};
};

const extractSearchEngineLink = (label) => {
    if (!label || typeof label !== 'string') {
        return geolocationConstants.NOT_AVAILABLE_VALUE;
    }

    return (
        label.match(SEARCH_ENGINE_LINK_EXTRACTION_PATTERN)?.[1] ||
        geolocationConstants.NOT_AVAILABLE_VALUE
    );
};

const extractConcreteLocationName = (defaultLabel, concreteLocation) => {
    const concreteLocationName = concreteLocation?.[geolocationConstants.CANONICAL_NAME_FIELD];

    if (concreteLocationName) {
        return concreteLocationName;
    }

    if (!defaultLabel || typeof defaultLabel !== 'string') {
        return EMPTY_VALUE;
    }

    return defaultLabel.replace(LOCATION_NAME_EXTRACTION_PATTERN, EMPTY_VALUE) || EMPTY_VALUE;
};

/**
 * Checks if a country option matches the given country code
 * @param {Object} option - Country option object containing value field
 * @param {string} countryCode - Country code to match against
 * @returns {boolean} - Whether the country codes match (case-insensitive)
 */
export const isMatchingCountryCode = (option, countryCode = '') =>
    option?.value?.toUpperCase() === countryCode.toUpperCase();

/**
 * Creates a formatted version of location.
 *
 * @param {string} countryCode - Location's country code.
 * @param {object} concreteLocation - Optimizer's geolocation object (present if a particular city was specified during optimizer's creation).
 * @param {object} countries - Dictionary which represents a set of countries and their search engine links (an example of key-value pair -> US: 'United States (google.com)').
 * @param {object} featuredCountries - Array which represents a set of country codes which are marked as featured.
 * @returns {string} - A formatted location's name.
 */
export const formatLocation = (countryCode, concreteLocation, countries, featuredCountries) => {
    const countryDetails = getCountryDetails(countryCode, countries, featuredCountries);
    const { label = EMPTY_VALUE } = countryDetails;
    const concreteLocationName = extractConcreteLocationName(label, concreteLocation);
    const searchEngineLink = extractSearchEngineLink(label);

    return `${concreteLocationName} (${searchEngineLink})`;
};
