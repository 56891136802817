export const editorEvents = {
    selectionUpdate: 'selectionUpdate',
};

export const editorCommands = {
    undo: 'undo',
    redo: 'redo',
};

export const editorVersionAutoCreationDelay = 900000;
