import { geolocationConstants } from '../constants/geolocationConsts';
import { contentOptimizerPathes } from '../constants/queryPathes';

import { axiosAPI } from '../utils/axiosAPI';
import { getAxiosHeaders } from '../utils/helpers/getAxiosHeaders';

const ContentOptimizerService = {
    getContentOptimizerVersions: (contentOptimizerId, organizationSlug) => {
        return axiosAPI.get(
            `${contentOptimizerPathes.getContentOptimizers}/${contentOptimizerId}/versions/`,
            {},
            {
                ...getAxiosHeaders(),
                params: { slug: organizationSlug },
            }
        );
    },

    getGeoTargets: (value) => {
        value = value.trim().replace(/\s+/g, ' ').replace(',', '');

        return axiosAPI
            .get(`${contentOptimizerPathes.getGeoTargets}?search_param=${value}`, {
                ...getAxiosHeaders(),
            })
            .then((result) => {
                return result.data.map((x, i) => ({
                    key: `${x['Country Code']}_${x['Criteria ID']}`,
                    label: x[geolocationConstants.CANONICAL_NAME_FIELD],
                    value: `${x['Country Code']}_${x['Criteria ID']}`,
                }));
            });
    },
};

export default ContentOptimizerService;
