import { isString } from 'lodash';

import { billingPlanTypes } from '../../../../../constants/billingPlanConsts';
import { billingSettingsActions } from './billingSettings.actions';

const billingSettingsInitialState = {
    paymentMethod: null,
    currentSubscription: null,
    isCurrentSubscriptionLoading: null,
    subscriptionLimits: null,
    planToSwitch: null,
    planType: billingPlanTypes.monthly.value,
    creditsPurchaseType: null,
    isPaymentMethodModalOpen: false,
};

export const billingSettingsReducer = (state = billingSettingsInitialState, { type, payload }) => {
    switch (type) {
        case billingSettingsActions.SET_PAYMENT_METHOD: {
            return {
                ...state,
                paymentMethod: payload,
            };
        }

        case billingSettingsActions.SET_CURRENT_SUBSCRIPTION: {
            return {
                ...state,
                currentSubscription: payload,
            };
        }

        case billingSettingsActions.SET_CURRENT_SUBSCRIPTION_LOADING: {
            return {
                ...state,
                isCurrentSubscriptionLoading: payload,
            };
        }

        case billingSettingsActions.SET_PLAN_TO_SWITCH: {
            return {
                ...state,
                planToSwitch: payload,
            };
        }

        case billingSettingsActions.UPDATE_PLAN_TYPE: {
            return {
                ...state,
                planType: payload,
            };
        }

        case billingSettingsActions.UPDATE_BILLING_EMAIL: {
            const updatedPaymentMethod = state.paymentMethod
                ? {
                      ...state.paymentMethod,
                      billing_details: {
                          ...state.paymentMethod.billing_details,
                          email: payload,
                      },
                  }
                : null;

            const updatedCurrentSubscription = state.currentSubscription
                ? {
                      ...state.currentSubscription,
                      customer: {
                          ...state.currentSubscription.customer,
                          email: payload,
                      },
                  }
                : null;

            return {
                ...state,
                paymentMethod: updatedPaymentMethod,
                currentSubscription: updatedCurrentSubscription,
            };
        }

        case billingSettingsActions.SET_SUBSCRIPTION_LIMITS: {
            return {
                ...state,
                subscriptionLimits: {
                    ...payload,
                    optimizers_added_source: JSON.parse(
                        payload.optimizers_added_source && isString(payload.optimizers_added_source)
                            ? payload.optimizers_added_source
                            : '[]'
                    ),
                    openai_briefs_added_source: JSON.parse(
                        payload.openai_briefs_added_source &&
                            isString(payload.openai_briefs_added_source)
                            ? payload.openai_briefs_added_source
                            : '[]'
                    ),
                },
            };
        }

        case billingSettingsActions.INCREASE_SUBSCRIPTION_LIMITS: {
            return {
                ...state,
                subscriptionLimits: {
                    ...state.subscriptionLimits,
                    [payload?.limit]: state.subscriptionLimits[payload?.limit] + payload?.value,
                },
            };
        }

        case billingSettingsActions.DECREASE_SUBSCRIPTION_LIMITS: {
            return {
                ...state,
                subscriptionLimits: {
                    ...state.subscriptionLimits,
                    [payload?.limit]: state.subscriptionLimits[payload?.limit] - payload?.value,
                },
            };
        }

        case billingSettingsActions.UPDATE_CURRENT_SUBSCRIPTION_CANCEL_AT_PERIOD_END: {
            return {
                ...state,
                currentSubscription: {
                    ...state.currentSubscription,
                    cancel_at_period_end: payload,
                },
            };
        }

        case billingSettingsActions.UPDATE_OPTIMIZERS_ADDED: {
            return {
                ...state,
                subscriptionLimits: {
                    ...state.subscriptionLimits,
                    optimizers_added: payload,
                },
            };
        }

        case billingSettingsActions.UPDATE_OPEN_AI_BRIEFS_USED: {
            return {
                ...state,
                subscriptionLimits: {
                    ...state.subscriptionLimits,
                    openai_briefs_used: payload,
                },
            };
        }

        case billingSettingsActions.UPDATE_OPTIMIZERS_ADDED_SOURCE: {
            return {
                ...state,
                subscriptionLimits: {
                    ...state.subscriptionLimits,
                    optimizers_added_source: JSON.parse(payload.optimizers || '[]'),
                    optimizers_added: payload.optimizers_added,
                },
            };
        }

        case billingSettingsActions.UPDATE_AI_ADDED_SOURCE: {
            return {
                ...state,
                subscriptionLimits: {
                    ...state.subscriptionLimits,
                    openai_briefs_added_source: JSON.parse(payload.ai || '[]'),
                    openai_briefs_added: payload.ai_added,
                },
            };
        }

        case billingSettingsActions.TOGGLE_PAYMENT_METHOD_MODAL: {
            return {
                ...state,
                isPaymentMethodModalOpen: !state.isPaymentMethodModalOpen,
            };
        }

        case billingSettingsActions.CLEAR_BILLING_SETTINGS: {
            return {
                ...billingSettingsInitialState,
            };
        }

        case billingSettingsActions.SET_CREDITS_PURCHASE_TYPE: {
            return {
                ...state,
                creditsPurchaseType: payload,
            };
        }

        default:
            return state;
    }
};
