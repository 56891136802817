import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { Form } from 'antd';

import { axiosAPI } from '../../../utils/axiosAPI';
import geoLocationSelectHelper from '../../../utils/helpers/geoLocationSelectHelper';
import { getArrayOptionsFromObject } from '../../../utils/helpers/getArrayOptionsFromObject';
import { getAxiosHeaders } from '../../../utils/helpers/getAxiosHeaders';
import {
    getKeywordLengthErrorMessage,
    getKeywordUrlErrorMessage,
    isKeywordLengthValid,
    normalizeKeyword,
} from '../../../utils/helpers/keywordHelpers';
import { openNotification } from '../../../utils/helpers/openNotification';
import { isUrlValid, removeUrlExtraSpaces } from '../../../utils/helpers/urlHelpers';
import { useOpen } from '../../../utils/hooks/useOpen';

import { countries, defaultCountry, featuredCountries } from '../../../constants/countries';
import { defaultLanguage, featuredLanguages, languages } from '../../../constants/languages';
import { linkSearchParams } from '../../../constants/linkSearchParams';
import { errorNotificationMessage, notificationType } from '../../../constants/notificationType';
import { contentBriefPathes } from '../../../constants/queryPathes';
import { statusCodes } from '../../../constants/statusCodes';
import { subscriptionLimits } from '../../../constants/subscriptionLimits';

import { KEY_CODES } from '../../../constants/constants';
import { increaseSubscriptionLimits } from '../../pages/account/billing/store/billingSettings.actions';
import {
    selectOptimizersAdded,
    selectOptimizersLimit,
    selectOptimizersUsed,
} from '../../pages/account/billing/store/billingSettings.selectors';
import {
    setContentOptimizerForUpdate,
    setContentOptimizerMainTotalCount,
} from '../../pages/contentOptimizer/mainPage/store/contentOptimizerMain.actions';
import {
    selectContentOptimizerData,
    selectContentOptimizerMainQueryParams,
    selectIsImportURLActive,
    selectOptimizerForUpdate,
} from '../../pages/contentOptimizer/mainPage/store/contentOptimizerMain.selectors';

import KeywordSearchInput from '../inputs/KeywordSearchInput';
import DebounceSelect from '../selects/DebounceSelect';
import KeywordSelect from '../selects/KeywordSelect';
import DuplicateOptimizerModal from './DuplicateOptimizerModal';
import ExportCheckbox from './ExportCheckbox';
import NoCreditsModal from './NoCreditsModal';
import RunOptimizerButton from './RunOptimizerButton';

import {
    selectUserGoogleSearchEnginePreference,
    selectUserLanguagePreference,
} from '../../../userBrowserSettings/store/browserSettings.selectors';

import ContentOptimizerService from '../../../services/contentOptimizer.service';
import { checkRecentIdenticalOptimizerPresence } from '../../../utils/helpers/checkRecentIdenticalOptimizersPresence';

import './CreateNew.scss';

const classes = {
    keywordHeaderWrapper: 'keyword-header-wrapper brief-header d-flex w-100',
    keywordLocationSelect: 'keyword-location-select',
    keywordLanguageSelect: 'keyword-language-select',
    runBriefBtn: 'run-brief-btn',
    changeURLFormItemWrapper: 'change-url-form-item-wrapper',
    breakRow: 'flex-break-row',
};

const CreateSingleSection = ({ getOptimizers, runBtnText, handleCreateSingle }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [searchParams] = useSearchParams();

    const existingOptimizers = useSelector(selectContentOptimizerData);
    const queryParams = useSelector(selectContentOptimizerMainQueryParams);
    const optimizersLimit = useSelector(selectOptimizersLimit);
    const optimizersUsed = useSelector(selectOptimizersUsed);
    const optimizersAdded = useSelector(selectOptimizersAdded);
    const isImportURLActive = useSelector(selectIsImportURLActive);
    const optimizerForUpdate = useSelector(selectOptimizerForUpdate);
    const userLanguagePreference = useSelector(selectUserLanguagePreference) || defaultLanguage;
    const userSearchEnginePreference =
        useSelector(selectUserGoogleSearchEnginePreference) || defaultCountry;

    const [isKeywordInputError, setIsKeywordInputError] = useState(false);
    const [isUrlInputError, setIsUrlInputError] = useState(false);
    const [keywordSearchData, setKeywordSearchData] = useState({
        keywords: '',
        country_code: userSearchEnginePreference,
        language_code: userLanguagePreference,
        url: '',
        export_nlp: false,
    });
    const [location, setLocation] = useState(userSearchEnginePreference);
    const [isLoading, setIsLoading] = useState(false);
    const { isOpen: isNoCreditModalOpen, handleOpen: handleNoCreditModalOpen } = useOpen();
    const { isOpen: isDuplicateOptimizerModalOpen, handleOpen: handleDuplicateOptimizerModalOpen } =
        useOpen();

    useEffect(() => {
        if (keywordSearchData.keywords?.includes(',')) {
            form.setFields([
                {
                    name: 'keywords',
                    errors: ['You can use only one keyword for this type of brief'],
                },
            ]);
        } else {
            form.setFields([
                {
                    name: 'keywords',
                    errors: [],
                },
            ]);
        }

        // eslint-disable-next-line
    }, [keywordSearchData.keywords]);

    useEffect(() => {
        const serchedKeyword = searchParams?.get(linkSearchParams.keyword.param)?.replace('+', ' ');

        if (serchedKeyword) {
            setKeywordSearchData((prev) => ({
                ...prev,
                keywords: serchedKeyword,
            }));

            form.setFieldsValue({
                keywords: serchedKeyword,
            });
        }

        // eslint-disable-next-line
    }, [searchParams]);

    useEffect(() => {
        if (optimizerForUpdate) {
            setKeywordSearchData((prev) => ({
                ...prev,
                keywords: optimizerForUpdate,
            }));

            form.setFieldsValue({
                keywords: optimizerForUpdate,
            });

            dispatch(setContentOptimizerForUpdate(null));
        }

        // eslint-disable-next-line
    }, []);

    const handleChangeKeyword = (event) => {
        const eventValue = normalizeKeyword(event?.target?.value);

        setKeywordSearchData((prev) => ({
            ...prev,
            keywords: eventValue,
        }));

        setIsKeywordInputError(false);
        form.setFields([
            {
                name: 'keywords',
                errors: [],
            },
        ]);
    };

    const handleChangeURL = (event) => {
        const eventValue = removeUrlExtraSpaces(event?.target?.value);

        setKeywordSearchData((prev) => ({
            ...prev,
            url: eventValue,
        }));

        setIsUrlInputError(false);
        form.setFields([
            {
                name: 'url',
                errors: [],
            },
        ]);
    };

    const handleChangeNlpExport = () => {
        setKeywordSearchData((prev) => ({
            ...prev,
            export_nlp: !prev.export_nlp,
        }));
    };

    const handleChangeLocation = (newLocation) => {
        const { country_code, geo_location } = geoLocationSelectHelper.getGeoData(newLocation);

        setKeywordSearchData((prev) => ({
            ...prev,
            country_code,
            geo_location,
        }));

        const newValue = geo_location ? `${country_code}_${geo_location}` : country_code;

        setLocation(newValue);
    };

    const handleChangeLanguage = (value) => {
        setKeywordSearchData((prev) => ({
            ...prev,
            language_code: value,
        }));
    };

    const validateRequestData = (isImportURLActive = false) => {
        if (!keywordSearchData.keywords) {
            setIsKeywordInputError(true);

            return false;
        }

        if (!isKeywordLengthValid(keywordSearchData.keywords)) {
            setIsKeywordInputError(true);
            form.setFields([
                {
                    name: 'keywords',
                    errors: [getKeywordLengthErrorMessage()],
                },
            ]);

            return false;
        }

        if (isImportURLActive) {
            if (!keywordSearchData.url) {
                setIsUrlInputError(true);

                return false;
            }

            const isURLValid = isUrlValid(keywordSearchData.url);

            if (!isURLValid) {
                form.setFields([
                    {
                        name: 'url',
                        errors: [getKeywordUrlErrorMessage()],
                    },
                ]);
            }

            return isURLValid;
        }

        return true;
    };

    const createContentBrief = () => {
        const isFormValid = validateRequestData(isImportURLActive);

        if (isFormValid) {
            setIsLoading(true);

            const { export_nlp, ...dataToSend } = keywordSearchData;

            axiosAPI
                .post(
                    contentBriefPathes.createContentBrief,
                    {
                        ...dataToSend,
                        user_created_at_time: new Date().toISOString(),
                    },
                    {
                        ...getAxiosHeaders(),
                        params: {
                            get_optimizers: getOptimizers,
                            export_nlp: export_nlp,
                            per_page: queryParams?.per_page,
                        },
                    }
                )
                .then((result) => {
                    if (result?.status === statusCodes.create) {
                        handleCreateSingle(result?.data);
                        dispatch(
                            increaseSubscriptionLimits({
                                limit: subscriptionLimits.optimizersUsed,
                                value: 1,
                            })
                        );
                        dispatch(
                            setContentOptimizerMainTotalCount(
                                result?.headers?.['x-total-pages'] * queryParams?.per_page
                            )
                        );
                        setKeywordSearchData((prev) => ({
                            ...prev,
                            keywords: '',
                            url: '',
                            export_nlp: false,
                        }));

                        form.setFieldValue('keywords', '');
                        form.setFieldValue('url', '');
                    }
                })
                .catch(({ response }) => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        response?.data?.message || errorNotificationMessage
                    );
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const handleRunBrief = () => {
        if (isLoading) {
            return;
        }

        if (optimizersUsed >= optimizersLimit + optimizersAdded || !optimizersLimit) {
            handleNoCreditModalOpen();

            return;
        }

        const isRecentIdenticalOptimizerPresent = checkRecentIdenticalOptimizerPresence(
            existingOptimizers,
            keywordSearchData,
            location
        );

        if (isRecentIdenticalOptimizerPresent) {
            handleDuplicateOptimizerModalOpen();

            return;
        }

        createContentBrief();
    };

    const handleKeyDown = (event) => {
        if (event.key === KEY_CODES.ENTER) {
            handleRunBrief();
        }
    };

    return (
        <>
            <Form form={form} onKeyDown={handleKeyDown}>
                <div className={classes.keywordHeaderWrapper}>
                    <Form.Item name='keywords'>
                        <KeywordSearchInput
                            placeholder='Enter keyword or phrase you want to rank for (e.g. best running shoes)...'
                            value={keywordSearchData.keywords}
                            onChange={handleChangeKeyword}
                            isInputError={isKeywordInputError}
                        />
                    </Form.Item>
                    <div className={classes.keywordLocationSelect}>
                        <DebounceSelect
                            placeholder='Enter city, state/province, or country'
                            onChange={handleChangeLocation}
                            showSearch={true}
                            value={location}
                            setValue={setLocation}
                            defaultValue={keywordSearchData.country_code}
                            fetchOptions={(value) => ContentOptimizerService.getGeoTargets(value)}
                            initialOptions={getArrayOptionsFromObject({
                                values: countries,
                                featured: featuredCountries,
                            })}
                        />
                    </div>
                    <div className={classes.keywordLanguageSelect}>
                        <KeywordSelect
                            placeholder='Select Language'
                            options={getArrayOptionsFromObject({
                                values: languages,
                                featured: featuredLanguages,
                            })}
                            defaultValue={keywordSearchData.language_code}
                            onChange={handleChangeLanguage}
                        />
                    </div>
                    <Form.Item shouldUpdate>
                        {() => (
                            <RunOptimizerButton
                                isLoading={isLoading}
                                runBtnText={runBtnText}
                                handleRunBrief={handleRunBrief}
                            />
                        )}
                    </Form.Item>

                    {isImportURLActive && (
                        <>
                            <div className={classes.breakRow} />
                            <Form.Item name='url' className={classes.changeURLFormItemWrapper}>
                                <KeywordSearchInput
                                    isInputError={isUrlInputError}
                                    placeholder='Enter URL to import existing content (e.g. https://www.domain.com/best-running-shoes)...'
                                    value={keywordSearchData.url}
                                    onChange={handleChangeURL}
                                />
                            </Form.Item>
                        </>
                    )}
                    <ExportCheckbox
                        value={keywordSearchData.export_nlp}
                        onChange={handleChangeNlpExport}
                    />
                </div>
            </Form>
            <DuplicateOptimizerModal
                isOpen={isDuplicateOptimizerModalOpen}
                optimizerData={{
                    keyword: keywordSearchData.keywords,
                    countryCode: keywordSearchData.country_code,
                    languageCode: keywordSearchData.language_code,
                }}
                handleOpen={handleDuplicateOptimizerModalOpen}
                handleRunOptimizer={createContentBrief}
            />
            <NoCreditsModal isOpen={isNoCreditModalOpen} handleOpen={handleNoCreditModalOpen} />
        </>
    );
};

export default CreateSingleSection;
