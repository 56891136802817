import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Typography } from 'antd';

import {
    selectDarkMode,
    selectUserOrganization,
} from '../../../../../userBrowserSettings/store/browserSettings.selectors';
import {
    selectCreditsPurchaseType,
    selectCurrentSubscription,
} from '../store/billingSettings.selectors';

import SubscriptionHelper from '../../../auth/helpers/subscription.helper';

import CreditsCard from './content/CreditsCard';

import { creditType } from '../../../../../constants/creditsConsts';

import './CreditsPageContent.scss';

const { Title } = Typography;

const classes = {
    creditsContentWrapper: 'credits-wrapper d-flex flex-column align-items-center w-100 h-100',
    creditsContentWrapperDark: 'credits-wrapper-dark',
};

const CreditsPageContent = () => {
    const navigate = useNavigate();
    const darkMode = useSelector(selectDarkMode);
    const currentSubscription = useSelector(selectCurrentSubscription);
    const organization = useSelector(selectUserOrganization);
    const creditPurchaseType = useSelector(selectCreditsPurchaseType);

    useEffect(() => {
        if (SubscriptionHelper.isTrial(currentSubscription, organization) || !creditPurchaseType) {
            navigate('/');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSubscription, organization, creditPurchaseType]);

    return (
        <div
            className={`${classes.creditsContentWrapper} 
                        ${darkMode ? classes.creditsContentWrapperDark : ''}`}
        >
            <Title level={2}>
                Purchase {creditPurchaseType === creditType.AI ? 'AI' : 'Optimizer'} Credits
            </Title>
            <CreditsCard />
        </div>
    );
};

export default CreditsPageContent;
