import { batch, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { contentBriefPathes } from '../../../../../../../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../../../../../../../constants/statusCodes';

import { axiosAPI } from '../../../../../../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../../../../../../utils/helpers/getAxiosHeaders';
import { openBasicErrorNotification } from '../../../../../../../../../../../utils/helpers/openBasicErrorNotification';

import {
    addOutlineVersion,
    setOutlineVersionLoading,
    updateOutlineVersions,
} from '../../../store/contentBriefsOutline.actions';
import { setCurrentBriefId } from '../../../store/contentBriefsOutline.actions';
import { selectCurrentOutline } from '../../../store/contentBriefsOutline.selectors';

const useOutlineVersions = () => {
    const dispatch = useDispatch();

    const { contentOptimizerId } = useParams();

    const currentOutline = useSelector(selectCurrentOutline);

    const createNewOutlineVersion = () => {
        if (contentOptimizerId) {
            dispatch(setOutlineVersionLoading(true));

            axiosAPI
                .post(
                    `${contentBriefPathes.getListOfContentBriefs}/${contentOptimizerId}/outlines`,
                    {
                        outline: JSON.stringify(currentOutline || []),
                    },
                    {
                        ...getAxiosHeaders(),
                    }
                )
                .then((result) => {
                    if (result) {
                        dispatch(addOutlineVersion(result.data));
                    }
                })
                .catch(() => {
                    openBasicErrorNotification();
                })
                .finally(() => {
                    dispatch(setOutlineVersionLoading(false));
                });
        }
    };

    const fetchOutlineVersions = () => {
        if (contentOptimizerId) {
            axiosAPI
                .get(
                    `${contentBriefPathes.getListOfContentBriefs}/${contentOptimizerId}/outlines`,
                    {
                        ...getAxiosHeaders(),
                    }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        if (result?.data?.length !== 0) {
                            batch(() => {
                                dispatch(updateOutlineVersions(result?.data));
                                dispatch(setCurrentBriefId(Number.parseInt(contentOptimizerId)));
                            });
                        } else {
                            createNewOutlineVersion();
                        }
                    }
                })
                .catch(() => {
                    openBasicErrorNotification();
                })
                .finally(() => {
                    dispatch(setOutlineVersionLoading(false));
                });
        }
    };

    return {
        contentOptimizerId,
        createNewOutlineVersion,
        fetchOutlineVersions,
    };
};

export default useOutlineVersions;
