import React from 'react';
import { useSelector } from 'react-redux';

import { Tooltip } from 'antd';

import {
    selectCurrentUserRoles,
    selectDarkMode,
} from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { checkIsGlobalTechAdmin } from '../../../../utils/helpers/checkIsGlobalTechAdmin';
import { getTooltipOverlayClass } from '../../../../utils/helpers/themeHelpers';

import AccountNavDropdown from './AccountNavDropdown';
import CreditsRemaining from './CreditsRemaining';
import DarkModeSwitch from './DarkModeSwitch';
import HelpSetting from './HelpSetting';
import MastermindCountdown from './MastermindCountdown';

const classes = {
    siderSettingsWrapper: 'sider-settings',
    siderSettingsItem: 'sider-settings-item',
    siderAccSettingsItem: 'sider-acc-settings-item d-flex align-items-center',
};

const SiderSettings = ({ collapsed }) => {
    const userRoles = useSelector(selectCurrentUserRoles);
    const darkMode = useSelector(selectDarkMode);

    return (
        <div>
            <div className={classes.siderSettingsWrapper}>
                <div>
                    <div className={classes.siderSettingsItem}>
                        <Tooltip
                            trigger={['hover']}
                            arrowPointAtCenter={true}
                            title={collapsed && `Help`}
                            placement={'right'}
                            overlayClassName={getTooltipOverlayClass(darkMode)}
                        >
                            <div>
                                <HelpSetting collapsed={collapsed} />
                            </div>
                        </Tooltip>
                    </div>
                    <div className={classes.siderSettingsItem}>
                        <Tooltip
                            trigger={['hover']}
                            arrowPointAtCenter={true}
                            title={collapsed && `Live Mastermind`}
                            placement={'right'}
                            overlayClassName={getTooltipOverlayClass(darkMode)}
                        >
                            <div>
                                <MastermindCountdown collapsed={collapsed} />
                            </div>
                        </Tooltip>
                    </div>
                    {!checkIsGlobalTechAdmin(userRoles) && (
                        <div className={classes.siderSettingsItem}>
                            <CreditsRemaining collapsed={collapsed} />
                        </div>
                    )}
                    <div className={classes.siderSettingsItem}>
                        <Tooltip
                            trigger={['hover']}
                            arrowPointAtCenter={true}
                            title={collapsed && (!darkMode ? 'Dark mode' : 'Light mode')}
                            placement={'right'}
                            overlayClassName={getTooltipOverlayClass(darkMode)}
                        >
                            <div>
                                <DarkModeSwitch collapsed={collapsed} />
                            </div>
                        </Tooltip>
                    </div>
                </div>
                <div className={classes.siderAccSettingsItem}>
                    <Tooltip
                        trigger={['hover']}
                        arrowPointAtCenter={true}
                        title={collapsed && `My Account`}
                        placement={'right'}
                        overlayClassName={getTooltipOverlayClass(darkMode)}
                    >
                        <div>
                            <AccountNavDropdown collapsed={collapsed} />
                        </div>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

export default SiderSettings;
