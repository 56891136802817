import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Col, Form, Row } from 'antd';

import MultipleCreateArea from '../inputs/MultipleCreateArea';
import DebounceSelect from '../selects/DebounceSelect';
import KeywordSelect from '../selects/KeywordSelect';
import DuplicateOptimizerModal from './DuplicateOptimizerModal';
import NoCreditsModal from './NoCreditsModal';
import RunOptimizerButton from './RunOptimizerButton';

import { axiosAPI } from '../../../utils/axiosAPI';
import geoLocationSelectHelper from '../../../utils/helpers/geoLocationSelectHelper';
import { getArrayOptionsFromObject } from '../../../utils/helpers/getArrayOptionsFromObject';
import { getAxiosHeaders } from '../../../utils/helpers/getAxiosHeaders';
import {
    getKeywordLengthErrorMessage,
    getKeywordUrlErrorMessage,
    isKeywordLengthValid,
    normalizeKeyword,
} from '../../../utils/helpers/keywordHelpers';
import { openNotification } from '../../../utils/helpers/openNotification';
import { isUrlValid, removeUrlExtraSpaces } from '../../../utils/helpers/urlHelpers';
import { useOpen } from '../../../utils/hooks/useOpen';

import { countries, defaultCountry, featuredCountries } from '../../../constants/countries';
import { defaultLanguage, featuredLanguages, languages } from '../../../constants/languages';
import { errorNotificationMessage, notificationType } from '../../../constants/notificationType';
import { contentBriefPathes } from '../../../constants/queryPathes';
import { statusCodes } from '../../../constants/statusCodes';
import { subscriptionLimits } from '../../../constants/subscriptionLimits';

import { increaseSubscriptionLimits } from '../../pages/account/billing/store/billingSettings.actions';
import {
    selectOptimizersAdded,
    selectOptimizersLimit,
    selectOptimizersUsed,
} from '../../pages/account/billing/store/billingSettings.selectors';
import { setContentOptimizerMainTotalCount } from '../../pages/contentOptimizer/mainPage/store/contentOptimizerMain.actions';
import {
    selectContentOptimizerData,
    selectContentOptimizerMainQueryParams,
    selectIsImportURLActive,
} from '../../pages/contentOptimizer/mainPage/store/contentOptimizerMain.selectors';

import {
    selectUserGoogleSearchEnginePreference,
    selectUserLanguagePreference,
} from '../../../userBrowserSettings/store/browserSettings.selectors';

import { checkRecentIdenticalOptimizerPresence } from '../../../utils/helpers/checkRecentIdenticalOptimizersPresence';
import ContentOptimizerService from '../../../services/contentOptimizer.service';

import './CreateNew.scss';

const classes = {
    createAreaWrapper: 'create-area-wrapper create-multiple-section',
    optionsWrapper: 'options-wrapper keyword-header-wrapper w-100 d-flex justify-content-between',
    keywordLocationSelect: 'keyword-location-select',
    keywordLanguageSelect: 'keyword-language-select',
    runBriefBtn: 'run-brief-btn',
};

const CreateMultipleSection = ({ getOptimizers, runBtnText, handleCreateMultiple }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const existingOptimizers = useSelector(selectContentOptimizerData);
    const queryParams = useSelector(selectContentOptimizerMainQueryParams);
    const optimizersLimit = useSelector(selectOptimizersLimit);
    const optimizersUsed = useSelector(selectOptimizersUsed);
    const optimizersAdded = useSelector(selectOptimizersAdded);
    const userLanguagePreference = useSelector(selectUserLanguagePreference) || defaultLanguage;
    const userSearchEnginePreference =
        useSelector(selectUserGoogleSearchEnginePreference) || defaultCountry;
    const isImportURLActive = useSelector(selectIsImportURLActive);

    const [contentBriefData, setContentBriefData] = useState({
        country_code: userSearchEnginePreference,
        language_code: userLanguagePreference,
        keywords: '',
        urls: '',
    });

    const [location, setLocation] = useState(userSearchEnginePreference);
    const [isLoading, setIsLoading] = useState(false);
    const [duplicatedKeyword, setDuplicatedKeyword] = useState(null);
    const [keywordsArray, setKeywordsArray] = useState([]);
    const [urlsArray, setUrlsArray] = useState([]);
    const [isKeywordsInputError, setKeywordsIsInputError] = useState(false);
    const [isUrlInputError, setUrlIsInputError] = useState(false);

    const { isOpen: isNoCreditModalOpen, handleOpen: handleNoCreditModalOpen } = useOpen();
    const { isOpen: isDuplicateOptimizerModalOpen, handleOpen: handleDuplicateOptimizerModalOpen } =
        useOpen();

    useEffect(() => {
        if (contentBriefData.keywords?.includes(',')) {
            form.setFields([
                {
                    name: 'keywords',
                    errors: ['Please, use new line for each keyword instead of comma'],
                },
            ]);
        } else if (
            contentBriefData.keywords?.split('\n')?.filter((item) => item !== '').length >
                optimizersLimit + optimizersAdded - optimizersUsed &&
            optimizersLimit + optimizersAdded - optimizersUsed !== 0
        ) {
            form.setFields([
                {
                    name: 'keywords',
                    errors: ['You have reached the limit of credits'],
                },
            ]);
        } else if (contentBriefData.keywords?.split('\n').length > 100) {
            setKeywordsIsInputError(true);
            form.setFields([
                {
                    name: 'keywords',
                    errors: ['You can add up to 100 keywords'],
                },
            ]);
        } else {
            form.setFields([
                {
                    name: 'keywords',
                    errors: [],
                },
            ]);
        }

        // eslint-disable-next-line
    }, [contentBriefData]);

    useEffect(() => {
        if (contentBriefData.keywords) {
            setKeywordsArray(contentBriefData.keywords.split('\n'));
        }

        if (contentBriefData.urls) {
            setUrlsArray(contentBriefData.urls.split('\n'));
        }
    }, [contentBriefData]);

    const handleChangeKeywords = (value) => {
        setContentBriefData((prev) => ({
            ...prev,
            keywords: value,
        }));
        setKeywordsIsInputError(false);
        setUrlIsInputError(false);

        form.setFields([
            { name: 'keywords', errors: [] },
            { name: 'urls', errors: [] },
        ]);
    };

    const handleChangeUrls = (value) => {
        setContentBriefData((prev) => ({
            ...prev,
            urls: value,
        }));
        setKeywordsIsInputError(false);
        setUrlIsInputError(false);

        form.setFields([
            { name: 'keywords', errors: [] },
            { name: 'urls', errors: [] },
        ]);
    };

    const handleChangeLanguage = (value) => {
        setContentBriefData((prev) => ({
            ...prev,
            language_code: value,
        }));
    };

    const handleChangeLocation = (newLocation) => {
        const { country_code, geo_location } = geoLocationSelectHelper.getGeoData(newLocation);

        setContentBriefData((prev) => ({
            ...prev,
            country_code,
            geo_location,
        }));

        const newValue = geo_location ? `${country_code}_${geo_location}` : country_code;

        setLocation(newValue);
    };

    const validateRequestKeywords = (keywordList = []) => {
        let keywordFieldIsCorrect = true;

        if (!keywordList.length) {
            setKeywordsIsInputError(true);

            return false;
        }

        keywordList.forEach((keyword) => {
            if (!isKeywordLengthValid(keyword)) {
                setKeywordsIsInputError(true);

                form.setFields([
                    {
                        name: 'keywords',
                        errors: [getKeywordLengthErrorMessage()],
                    },
                ]);

                keywordFieldIsCorrect = false;
            }
        });

        return keywordFieldIsCorrect;
    };

    const validateFormAndCredits = () => {
        return (
            form.getFieldsError().some((item) => item.errors.length > 0) ||
            optimizersUsed >= optimizersLimit + optimizersAdded ||
            !optimizersLimit
        );
    };

    const validateKeywordUrlCount = () => {
        const keywordCount = contentBriefData.keywords?.split('\n').length;
        const urlCount = contentBriefData.urls?.split('\n').length;

        if (urlCount > keywordCount) {
            form.setFields([
                {
                    name: 'keywords',
                    errors: ['Please add the same number of keywords and URLs'],
                },
            ]);

            return false;
        }

        return true;
    };

    const validateUrls = () => {
        const urls = contentBriefData.urls;

        if (
            !urls
                ?.split('\n')
                ?.map(removeUrlExtraSpaces)
                ?.every((link) => isUrlValid(link) || link === '')
        ) {
            form.setFields([
                {
                    name: 'urls',
                    errors: [getKeywordUrlErrorMessage()],
                },
            ]);
            return false;
        }
        return true;
    };

    const createContentBriefModel = (contentBriefData, parsedKeywords) => ({
        country_code: contentBriefData.country_code,
        language_code: contentBriefData.language_code,
        geo_location: contentBriefData.geo_location,
        keywords: parsedKeywords.join('\n'),
        url: contentBriefData.urls,
        user_created_at_time: new Date().toISOString(),
    });

    const handleSuccessfulResponse = (result) => {
        if (result?.status === statusCodes.create) {
            handleCreateMultiple(result?.data?.reverse());

            batch(() => {
                dispatch(
                    increaseSubscriptionLimits({
                        limit: subscriptionLimits.optimizersUsed,
                        value: result?.data?.length,
                    })
                );
                dispatch(
                    setContentOptimizerMainTotalCount(
                        result?.headers?.['x-total-pages'] * queryParams?.per_page
                    )
                );
            });

            setContentBriefData((prev) => ({
                ...prev,
                keywords: '',
                urls: '',
            }));
            setKeywordsArray([]);
            setUrlsArray([]);

            form.resetFields();
        }
    };

    const getParsedKeywords = () => {
        const parsedKeywords = contentBriefData.keywords
            .split('\n')
            .map(normalizeKeyword)
            .filter((item) => item !== '');

        if (validateFormAndCredits()) {
            handleNoCreditModalOpen();

            return null;
        }

        if (!validateKeywordUrlCount() || !validateUrls()) {
            setKeywordsIsInputError(true);
            setUrlIsInputError(true);

            return null;
        }

        if (!parsedKeywords?.length || !validateRequestKeywords(parsedKeywords)) {
            setKeywordsIsInputError(true);

            return null;
        }

        return parsedKeywords;
    };

    const createContentBrief = (parsedKeywords = getParsedKeywords()) => {
        if (!parsedKeywords) {
            return;
        }

        if (duplicatedKeyword) {
            setDuplicatedKeyword(null);
        }

        setIsLoading(true);

        const briefModel = createContentBriefModel(contentBriefData, parsedKeywords);

        axiosAPI
            .post(contentBriefPathes.createContentBrief, briefModel, {
                ...getAxiosHeaders(),
                params: {
                    get_optimizers: getOptimizers,
                    per_page: queryParams?.per_page,
                },
            })
            .then((result) => {
                handleSuccessfulResponse(result);
            })
            .catch(({ response }) => {
                openNotification(
                    notificationType.error,
                    'Error',
                    response?.data?.message || errorNotificationMessage
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleRunBrief = () => {
        const parsedKeywords = getParsedKeywords();

        if (!parsedKeywords) {
            return;
        }

        let isAnyRecentOptimizerPresent = false;

        parsedKeywords.forEach((parsedKeyword) => {
            if (!isAnyRecentOptimizerPresent) {
                const isRecentIdenticalOptimizerPresent = checkRecentIdenticalOptimizerPresence(
                    existingOptimizers,
                    { ...contentBriefData, keywords: parsedKeyword },
                    location
                );

                if (isRecentIdenticalOptimizerPresent) {
                    isAnyRecentOptimizerPresent = isRecentIdenticalOptimizerPresent;
                    setDuplicatedKeyword(parsedKeyword);
                }
            }
        });

        if (isAnyRecentOptimizerPresent) {
            handleDuplicateOptimizerModalOpen();

            return;
        }

        createContentBrief(parsedKeywords);
    };

    return (
        <>
            <Form
                form={form}
                layout='vertical'
                className={classes.createAreaWrapper}
                initialValues={contentBriefData}
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label='Keywords' name='keywords'>
                            <MultipleCreateArea
                                isInputError={isUrlInputError || isKeywordsInputError}
                                placeholder='Enter multiple keywords or phrases separated on a new line by pressing Enter — one keyword per line, maximum 100 (e.g. best running shoes)…'
                                handleChange={handleChangeKeywords}
                                value={contentBriefData.keywords}
                                keywordArray={keywordsArray}
                            />
                        </Form.Item>
                    </Col>
                    {isImportURLActive && (
                        <Col span={12}>
                            <Form.Item label='URLs (optional)' name='urls'>
                                <MultipleCreateArea
                                    isInputError={isUrlInputError || isKeywordsInputError}
                                    placeholder='Enter associated URL for each keyword to import URL content — one URL per line, maximum 100 (e.g. https://www.domain.com/best-running-shoes)…'
                                    handleChange={handleChangeUrls}
                                    value={contentBriefData.urls}
                                    keywordArray={urlsArray}
                                />
                            </Form.Item>
                        </Col>
                    )}
                </Row>
                <div className={classes.optionsWrapper}>
                    <div className='d-flex'>
                        <div className={classes.keywordLocationSelect}>
                            <DebounceSelect
                                placeholder='Enter city, state/province, or country'
                                onChange={handleChangeLocation}
                                showSearch={true}
                                value={location}
                                setValue={setLocation}
                                defaultValue={contentBriefData.country_code}
                                fetchOptions={(value) =>
                                    ContentOptimizerService.getGeoTargets(value)
                                }
                                initialOptions={getArrayOptionsFromObject({
                                    values: countries,
                                    featured: featuredCountries,
                                })}
                            />
                        </div>
                        <div className={classes.keywordLanguageSelect}>
                            <KeywordSelect
                                placeholder='Select Language'
                                options={getArrayOptionsFromObject({
                                    values: languages,
                                    featured: featuredLanguages,
                                })}
                                defaultValue={contentBriefData.language_code}
                                onChange={handleChangeLanguage}
                            />
                        </div>
                        <Form.Item shouldUpdate className={classes.runBriefBtn}>
                            {() => (
                                <RunOptimizerButton
                                    isLoading={isLoading}
                                    runBtnText={runBtnText}
                                    handleRunBrief={handleRunBrief}
                                    isDisabled={isKeywordsInputError || isUrlInputError}
                                />
                            )}
                        </Form.Item>
                    </div>
                </div>
            </Form>
            <DuplicateOptimizerModal
                isOpen={isDuplicateOptimizerModalOpen}
                optimizerData={{
                    keyword: duplicatedKeyword,
                    countryCode: contentBriefData.country_code,
                    languageCode: contentBriefData.language_code,
                }}
                handleOpen={handleDuplicateOptimizerModalOpen}
                handleRunOptimizer={createContentBrief}
            />
            <NoCreditsModal isOpen={isNoCreditModalOpen} handleOpen={handleNoCreditModalOpen} />
        </>
    );
};

export default CreateMultipleSection;
