import { sidebarActions } from './sidebar.actions';

const sidebarInitialState = {
    isCollapsed: false,
};

export const sidebarReducer = (state = sidebarInitialState, { type }) => {
    switch (type) {
        case sidebarActions.OPEN: {
            return {
                isCollapsed: false,
            };
        }
        case sidebarActions.COLLAPSE: {
            return {
                isCollapsed: true,
            };
        }

        default:
            return state;
    }
};
