import { useDispatch, useSelector } from 'react-redux';

import { setTableOfContentItems } from '../store/tiptapEditor.actions';
import { selectEditorTableOfContents } from '../store/tiptapEditor.selectors';

const transformTableOfContentsData = (data) => {
    return data.map((item) => {
        const { id, textContent, originalLevel, pos } = item;

        return {
            id,
            label: textContent,
            level: originalLevel,
            pos,
        };
    });
};

const useTableOfContentsConfiguration = () => {
    const dispatch = useDispatch();

    const editorTableOfContents = useSelector(selectEditorTableOfContents);

    const handleTableOfContentsUpdate = (data) => {
        if (!data) {
            return;
        }

        const transformedData = transformTableOfContentsData(data);

        dispatch(setTableOfContentItems(transformedData));
    };

    return {
        handleTableOfContentsUpdate,
        tableOfContentsTurnedOn: editorTableOfContents,
    };
};

export default useTableOfContentsConfiguration;
