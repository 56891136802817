import React, { useCallback, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Layout, theme } from 'antd';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../utils/helpers/getAxiosHeaders';
import { openBasicErrorNotification } from '../../../../../utils/helpers/openBasicErrorNotification';

import { techAdminPathes } from '../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../constants/statusCodes';

import {
    setQuotasData,
    setQuotasIsDataLoading,
    setQuotasTotalPages,
} from '../store/adminQuotasSettings.actions';
import { selectQuotasQueryParams } from '../store/adminQuotasSettings.selectors';

import AdminQuotasTable from './AdminQuotasTable';

const { Content } = Layout;

const classes = {
    content: 'container quotas-container',
    contentDark: 'container-dark',
};

const QuotaPageContent = () => {
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const queryParams = useSelector(selectQuotasQueryParams);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const fetchQuotas = useCallback(() => {
        dispatch(setQuotasIsDataLoading(true));

        axiosAPI
            .get(techAdminPathes.getQuotas, { params: queryParams }, { ...getAxiosHeaders() })
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    batch(() => {
                        dispatch(setQuotasData(result?.data));
                        dispatch(setQuotasTotalPages(result?.headers?.['x-total-pages']));
                    });
                }
            })
            .catch(() => {
                openBasicErrorNotification();
            })
            .finally(() => {
                dispatch(setQuotasIsDataLoading(false));
            });

        // eslint-disable-next-line
    }, [queryParams]);

    useEffect(() => {
        fetchQuotas();
    }, []);

    return (
        <Content
            style={{ background: colorBgContainer }}
            className={`${classes.content} ${darkMode ? classes.contentDark : ''}`}
        >
            <AdminQuotasTable />
        </Content>
    );
};

export default QuotaPageContent;
