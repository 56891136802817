import React, { useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Button, Form } from 'antd';

import {
    selectDarkMode,
    selectIsGracePeriod,
    selectIsSubscriptionExpired,
} from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../utils/helpers/openNotification';
import { useScrollToId } from '../../../../utils/hooks/useScrollToId';
import { openBasicErrorNotification } from '../../../../utils/helpers/openBasicErrorNotification';

import { notificationType } from '../../../../constants/notificationType';
import { paymentsPathes } from '../../../../constants/queryPathes';
import { ScrollIds } from '../../../../constants/scrollIds';
import { statusCodes } from '../../../../constants/statusCodes';
import { creditType } from '../../../../constants/creditsConsts';

import { selectScrollSection } from '../store/accountSettings.selectors';
import { setPaymentMethod, updateBillingEmail } from './store/billingSettings.actions';
import { selectPaymentMethod } from './store/billingSettings.selectors';

import { elementsStyles } from '../../../../resources/styles/elementsStyles';

import BillingInfoSection from './content/BillingInfoSection';
import CancelSubscriptionSection from './content/CancelSubscriptionSection';
import InvoiceHistorySection from './content/InvoiceHistorySection';
import PaymentSection from './content/PaymentSection';
import ReportCreditsSection from './content/ReportCreditsSection';
import SubscriptionSection from './content/SubscriptionSection';

import './BillingContent.scss';

const classes = {
    billingContentWrapper: 'account-content-wrapper billing-content-wrapper',
    billingForm: 'd-flex flex-column justify-content-between h-100',
    billingFormFieldsWrapper: 'form-fields-wrapper h-100 d-flex flex-column',
    billingFormButtonsWrapper:
        'form-buttons-wrapper d-flex justify-content-center align-items-center',
    billingFormCancelButton: 'btn-secondary',
};

const BillingContent = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const paymentMethod = useSelector(selectPaymentMethod);
    const isSubscriptionExpired = useSelector(selectIsSubscriptionExpired);
    const isGracePeriod = useSelector(selectIsGracePeriod);

    const scrollSection = useSelector(selectScrollSection);

    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    useScrollToId(scrollSection);

    const handleFormChange = (allFields) => {
        const emailFormValue = allFields.find((field) => field?.name?.[0] === 'email')?.value;
        const extraFormValue = allFields.find((field) => field?.name?.[0] === 'extra')?.value;
        const valuesAreEqual =
            emailFormValue === paymentMethod?.billing_details?.email &&
            extraFormValue === paymentMethod?.billing_details?.extra;

        const formHasErrors = form.getFieldsError().some((item) => item.errors.length > 0);

        if (valuesAreEqual || formHasErrors) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    };

    const handleUpdateBillingInfo = (values) => {
        if (
            values?.email !== paymentMethod?.billing_details?.email ||
            values?.extra !== paymentMethod?.billing_details?.extra
        ) {
            setIsLoading(true);

            axiosAPI
                .patch(
                    paymentsPathes.editPaymentMethod,
                    {
                        email: values?.email,
                        extra: values?.extra,
                    },
                    { ...getAxiosHeaders() }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        batch(() => {
                            dispatch(setPaymentMethod(result?.data));
                            dispatch(updateBillingEmail(values.email));
                        });
                        openNotification(
                            notificationType.success,
                            'Success',
                            'Billing information has been updated successfully'
                        );
                        setIsDisabled(true);
                    }
                })
                .catch(() => {
                    openBasicErrorNotification();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const handleCancel = () => {
        form.setFieldsValue({
            email: paymentMethod?.billing_details?.email,
            extra: paymentMethod?.billing_details?.extra,
        });
        setIsDisabled(true);
    };

    return (
        <div className={classes.billingContentWrapper}>
            <Form
                form={form}
                layout='vertical'
                className={classes.settingsForm}
                initialValues={{
                    email: paymentMethod?.billing_details?.email,
                    extra: paymentMethod?.billing_details?.extra,
                }}
                onFinish={handleUpdateBillingInfo}
                onFieldsChange={handleFormChange}
            >
                <div
                    className={classes.billingFormFieldsWrapper}
                    style={{
                        bottom: !isDisabled
                            ? `${elementsStyles.AccountButtonsPanel.height}px`
                            : '0',
                    }}
                >
                    <SubscriptionSection />
                    {!isSubscriptionExpired && !isGracePeriod && (
                        <ReportCreditsSection
                            id={ScrollIds.credits}
                            creditTypeParam={creditType.OPTIMIZER}
                        />
                    )}
                    {/* TODO: Uncomment when functionality will be needed */}
                    {/* {!isSubscriptionExpired && !isGracePeriod && (
                        <ReportCreditsSection
                            id={ScrollIds.credits}
                            creditTypeParam={creditType.AI}
                        />
                    )} */}
                    <BillingInfoSection form={form} />
                    <PaymentSection id={ScrollIds.payment} />
                    <InvoiceHistorySection />
                    {!isSubscriptionExpired && !isGracePeriod && <CancelSubscriptionSection />}
                </div>
                {!isDisabled && (
                    <div className={classes.billingFormButtonsWrapper}>
                        <Button
                            type='primary'
                            size='large'
                            ghost
                            className={`${classes.billingFormCancelButton} ${
                                darkMode ? 'dark-mode' : ''
                            }`}
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='primary'
                            size='large'
                            htmlType='submit'
                            disabled={isDisabled}
                            loading={isLoading}
                        >
                            Save
                        </Button>
                    </div>
                )}
            </Form>
        </div>
    );
};

export default BillingContent;
