export const selectPaymentMethod = (state) => state?.billingSettings?.paymentMethod;
export const selectCurrentSubscription = (state) => state?.billingSettings?.currentSubscription;
export const selectCurrentSubscriptionCustomerEmail = (state) =>
    state?.billingSettings?.currentSubscription?.customer?.email;
export const selectIsCurrentSubscriptionLoading = (state) =>
    state?.billingSettings?.isCurrentSubscriptionLoading;
export const selectPlanToSwitch = (state) => state?.billingSettings?.planToSwitch;
export const selectPlanType = (state) => state?.billingSettings?.planType;
export const selectSubscriptionLimits = (state) => state?.billingSettings?.subscriptionLimits;
export const selectContentMonitorsLimits = (state) =>
    state?.billingSettings?.subscriptionLimits?.content_monitors;
export const selectContentMonitorsUsed = (state) =>
    state?.billingSettings?.subscriptionLimits?.content_monitors_used;
export const selectUsersLimit = (state) => state?.billingSettings?.subscriptionLimits?.user_seats;
export const selectUsedUsers = (state) =>
    state?.billingSettings?.subscriptionLimits?.user_seats_used;
export const selectKeywordResearchesLimit = (state) =>
    state?.billingSettings?.subscriptionLimits?.keyword_researches;
export const selectKeywordResearchesUsed = (state) =>
    state?.billingSettings?.subscriptionLimits?.keyword_researches_used;
export const selectOptimizersLimit = (state) =>
    state?.billingSettings?.subscriptionLimits?.optimizers;
export const selectOptimizersUsed = (state) =>
    state?.billingSettings?.subscriptionLimits?.optimizers_used;
export const selectOptimizersAdded = (state) =>
    state?.billingSettings?.subscriptionLimits?.optimizers_added;
export const selectOptimizersAddedSource = (state) =>
    state?.billingSettings?.subscriptionLimits?.optimizers_added_source;
export const selectOpenAIBriefsLimit = (state) =>
    state?.billingSettings?.subscriptionLimits?.openai_briefs;
export const selectOpenAIBriefsUsed = (state) =>
    state?.billingSettings?.subscriptionLimits?.openai_briefs_used;
export const selectOpenAIBriefsAddedSource = (state) =>
    state?.billingSettings?.subscriptionLimits?.openai_briefs_added_source;
export const selectCurrentSubscriptionCancelAtPeriodEnd = (state) =>
    state?.billingSettings?.currentSubscription?.cancel_at_period_end;
export const selectCurrentSubscriptionCanceledAt = (state) =>
    state?.billingSettings?.currentSubscription?.canceled_at;
export const selectIsPaymentMethodModalOpen = (state) =>
    state?.billingSettings?.isPaymentMethodModalOpen;
export const selectCreditsPurchaseType = (state) => state?.billingSettings?.creditsPurchaseType;
