import { combineReducers } from 'redux';

import { sidebarReducer } from '../components/common/sidebar/store/sidebar.reducer';
import { billingSettingsReducer } from '../components/pages/account/billing/store/billingSettings.reducer';
import { accountSettingsReducer } from '../components/pages/account/store/accountSettings.reducer';
import { adminReducer } from '../components/pages/admin/store/adminReducers';
import { usageSettingsReducer } from '../components/pages/admin/usage/store/usageSettings.reducer';
import { authSettingsReducer } from '../components/pages/auth/store/authSettings.reducer';
import { contentMonitorReducer } from '../components/pages/contentMonitor/store/contentMonitorReducers';
import { viewOnlySettingsReducer } from '../components/pages/contentOptimizer/viewOnly/store/viewOnlySettings.reducer';
import { contentOptimizerReducer } from '../components/pages/contentOptimizer/store/contentOptimizerReducers';
import { keywordResearchSettingsReducer } from '../components/pages/keywordResearch/store/keywordResearchSettings.reducer';
import { browserSettingsReducer } from '../userBrowserSettings/store/browserSettings.reducer';

export const rootReducer = combineReducers({
    browserSettings: browserSettingsReducer,
    authSettings: authSettingsReducer,
    accountSettings: accountSettingsReducer,
    billingSettings: billingSettingsReducer,
    keywordResearchSettings: keywordResearchSettingsReducer,
    viewOnlySettings: viewOnlySettingsReducer,
    contentOptimizer: contentOptimizerReducer,
    contentMonitor: contentMonitorReducer,
    admin: adminReducer,
    usageSettings: usageSettingsReducer,
    sidebar: sidebarReducer,
});
