import React from 'react';
import { useSelector } from 'react-redux';

import KeywordExtraBreadcrumb from '../../../../common/header/content/KeywordExtraBreadcrumb';

import { selectCurrentContentOptimizer } from '../store/contentOptimizerContent.selectors';

import './OptimizerContent.scss';

const ContentOptimizerHeaderContent = () => {
    const currentContentOptimizer = useSelector(selectCurrentContentOptimizer);

    return (
        <KeywordExtraBreadcrumb
            keyword={currentContentOptimizer?.content_brief?.keywords}
            countryCode={currentContentOptimizer?.content_brief?.country_code}
            languageCode={currentContentOptimizer?.content_brief?.language_code}
            concreteLocation={currentContentOptimizer?.content_brief?.geo_location_obj}
        />
    );
};

export default ContentOptimizerHeaderContent;
