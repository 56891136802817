import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useLocation } from 'react-router-dom';

import { useBreadcrumbItem } from '../../../utils/hooks/useBreadcrumbItem';
import { useOrganizationSlug } from '../../../utils/hooks/useOrganizationSlug';

import { accountTabs } from '../../../constants/accountTabs';
import { billingBreadcrumbPaths } from '../../../constants/bredcrumbPaths';
import { creditType } from '../../../constants/creditsConsts';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';
import { selectCreditsPurchaseType } from '../../pages/account/billing/store/billingSettings.selectors';
import { updateAccountTab } from '../../pages/account/store/accountSettings.actions';

import CustomHeader from '../header/Header';
import CommonPageLayout from './CommonPageLayout';

const classes = {
    link: 'billing-breadcrumb-link',
};

const BillingLayout = () => {
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);

    const billingBreadcrumbItem = useBreadcrumbItem(billingBreadcrumbPaths);
    const organizationSlug = useOrganizationSlug();

    const creditPurchaseType = useSelector(selectCreditsPurchaseType);

    const { pathname } = useLocation();

    const handleBillingBreadcrumbClick = () => {
        dispatch(updateAccountTab(accountTabs['billing-credits'].key));
    };

    const getBillingBreadcrumbItems = () => {
        let billingBreadcrumbs = [
            <Link
                className={`${classes.link}
            ${darkMode ? 'dark-mode' : ''}`}
                to={`/${organizationSlug}/account/billing-credits`}
                onClick={handleBillingBreadcrumbClick}
            >
                Billing & Credits
            </Link>,
        ];

        if (pathname.includes('checkout')) {
            billingBreadcrumbs.push(
                <Link
                    className={`${classes.link}
                ${darkMode ? 'dark-mode' : ''}`}
                    to={`/${organizationSlug}/account/plans`}
                >
                    Plans
                </Link>
            );
            billingBreadcrumbs.push('Checkout');
        } else if (pathname.includes('credits')) {
            billingBreadcrumbs.push(
                creditPurchaseType === creditType.AI ? 'AI Credits' : billingBreadcrumbItem.name
            );
        } else {
            billingBreadcrumbs.push(billingBreadcrumbItem.name || '');
        }

        return billingBreadcrumbs;
    };

    return (
        <>
            <CustomHeader
                // TODO: — Hide all “How To Use” buttons (temporary)
                //showUseSetting
                extraBreadcrumbs={getBillingBreadcrumbItems()}
            />
            <CommonPageLayout>
                <Outlet />
            </CommonPageLayout>
        </>
    );
};

export default BillingLayout;
