import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import { TextSelection } from '@tiptap/pm/state';

import { createTransaction } from '../utils/editorTransactionHelpers';

const SCROLL_EVENT_NAME = 'scroll';
const VIEWPORT_HEIGHT_OFFSET_PERCENTAGE = 0.05;
const BOTTOM_SCROLL_THRESHOLD = 10;
const SCROLL_DEBOUNCE_MS = 50;
const BASE_TIMEOUT_MS = 150;
const TIMEOUT_PER_PIXEL = 0.5;
const MAX_TIMEOUT_MS = 800;

const useActiveHeading = (editor, tableOfContentsAttr) => {
    const [activeHeading, setActiveHeading] = useState(null);
    const [clickedHeadingId, setClickedHeadingId] = useState(null);
    const [isScrolling, setIsScrolling] = useState(false);
    const headingsRef = useRef([]);
    const editorDomRef = useRef(null);
    const scrollTimeoutRef = useRef(null);

    const updateHeadings = useCallback(() => {
        if (!editorDomRef.current) return;

        headingsRef.current = Array.from(
            editorDomRef.current.querySelectorAll(`[${tableOfContentsAttr}]`)
        );
    }, [tableOfContentsAttr]);

    const isEditorAtBottom = useCallback(() => {
        const scrollTop = editorDomRef.current.scrollTop;
        const viewportHeight = editorDomRef.current.offsetHeight;
        const scrollHeight = editorDomRef.current.scrollHeight;

        const isAtBottom =
            Math.abs(scrollTop + viewportHeight - scrollHeight) < BOTTOM_SCROLL_THRESHOLD;

        return isAtBottom;
    }, []);

    const getVisibleHeadings = () => {
        const viewportHeight = editorDomRef.current.offsetHeight;
        const editorRect = editorDomRef.current.getBoundingClientRect();

        return headingsRef.current.filter((heading) => {
            const headingRect = heading.getBoundingClientRect();
            const headingTop = headingRect.top - editorRect.top;

            return headingTop >= 0 && headingTop <= viewportHeight;
        });
    };

    const calculateScrollTimeout = (sourceElement, targetElement) => {
        if (!sourceElement || !targetElement) return BASE_TIMEOUT_MS;

        const sourceRect = sourceElement.getBoundingClientRect();
        const targetRect = targetElement.getBoundingClientRect();
        const distance = Math.abs(targetRect.top - sourceRect.top);
        const isAtBottom = isEditorAtBottom();
        const visibleHeadings = getVisibleHeadings();

        const timeout =
            isAtBottom && visibleHeadings.find((heading) => heading.id === targetElement.id)
                ? 0
                : BASE_TIMEOUT_MS + distance * TIMEOUT_PER_PIXEL;

        return Math.min(timeout, MAX_TIMEOUT_MS);
    };

    const findActiveHeading = useCallback(() => {
        if (!headingsRef.current.length || !editorDomRef.current) return null;

        const editorRect = editorDomRef.current.getBoundingClientRect();
        const viewportHeight = editorDomRef.current.offsetHeight;
        const offset = viewportHeight * VIEWPORT_HEIGHT_OFFSET_PERCENTAGE;

        const isAtBottom = isEditorAtBottom();

        if (isAtBottom) {
            const visibleHeadings = getVisibleHeadings();

            return visibleHeadings.length > 0 ? visibleHeadings[0] : headingsRef.current[0];
        }

        const activeHeading = headingsRef.current.find((heading) => {
            const headingRect = heading.getBoundingClientRect();
            const headingTop = headingRect.top - editorRect.top;

            return headingTop >= -offset && headingTop <= offset;
        });

        if (!activeHeading) {
            const headingsAbove = headingsRef.current.filter((heading) => {
                const rect = heading.getBoundingClientRect();

                return rect.top - editorRect.top < 0;
            });

            return headingsAbove.length
                ? headingsAbove[headingsAbove.length - 1]
                : headingsRef.current[0];
        }

        return activeHeading;
    }, []);

    const scrollToHeading = useCallback(
        (id, pos) => {
            if (!editor?.view?.dom) return;

            createTransaction(
                editor,
                (tr) => {
                    tr.setSelection(new TextSelection(tr.doc.resolve(pos)));
                },
                false
            );

            editor.view.focus();

            const heading = headingsRef.current.find((h) => h.id === id);

            if (heading) {
                const activeElement = findActiveHeading();
                const scrollTimeout = calculateScrollTimeout(activeElement, heading);

                setIsScrolling(true);
                heading.scrollIntoView({ behavior: 'smooth' });
                setClickedHeadingId(heading.id);

                if (scrollTimeoutRef.current) {
                    clearTimeout(scrollTimeoutRef.current);
                }

                scrollTimeoutRef.current = setTimeout(() => {
                    setIsScrolling(false);
                }, scrollTimeout);
            }
        },
        [editor, findActiveHeading]
    );

    const handleScroll = useCallback(() => {
        if (isScrolling) return;

        const activeElement = findActiveHeading();

        if (activeElement) {
            const newActiveId = activeElement.getAttribute(tableOfContentsAttr);
            setActiveHeading((prev) => (prev !== newActiveId ? newActiveId : prev));
        }
    }, [findActiveHeading, tableOfContentsAttr, isScrolling]);

    const handleContentChange = useCallback(() => {
        updateHeadings();
        handleScroll();
    }, [updateHeadings, handleScroll]);

    const debouncedScrollHandler = useMemo(
        () =>
            debounce(handleScroll, SCROLL_DEBOUNCE_MS, {
                leading: true,
                maxWait: SCROLL_DEBOUNCE_MS,
            }),
        [handleScroll]
    );

    useEffect(() => {
        const editorDom = editor?.view?.dom;

        if (!editorDom) return;

        editorDomRef.current = editorDom;

        handleContentChange();

        const observer = new MutationObserver(handleContentChange);

        observer.observe(editorDomRef.current, {
            childList: true,
            subtree: true,
            characterData: true,
        });
        editorDomRef.current.addEventListener(SCROLL_EVENT_NAME, debouncedScrollHandler, {
            passive: true,
        });

        return () => {
            observer.disconnect();
            debouncedScrollHandler.cancel();
            editorDomRef.current.removeEventListener(SCROLL_EVENT_NAME, debouncedScrollHandler);
            editorDomRef.current = null;
        };
    }, [editorDomRef, handleContentChange, debouncedScrollHandler]);

    useEffect(() => {
        if (clickedHeadingId && !isScrolling) {
            setActiveHeading(clickedHeadingId);
            setClickedHeadingId(null);
        }
    }, [clickedHeadingId, isScrolling]);

    useEffect(() => {
        return () => {
            if (scrollTimeoutRef.current) {
                clearTimeout(scrollTimeoutRef.current);
            }
        };
    }, []);

    return { activeHeading, scrollToHeading };
};

export default useActiveHeading;
