import React, { useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Popconfirm } from 'antd';

import { selectDarkMode } from '../../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../../../utils/helpers/getAxiosHeaders';
import { useOrganizationSlug } from '../../../../../../../../utils/hooks/useOrganizationSlug';
import { openBasicErrorNotification } from '../../../../../../../../utils/helpers/openBasicErrorNotification';
import { getOptimizerRightBlockWrapper } from '../optimizerRightBlockHelpers';

import { contentOptimizerPathes } from '../../../../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../../../../constants/statusCodes';

import { updateEditorCurrentVersionScore } from '../../../../../../../common/tiptapEditor/store/tiptapEditor.actions';
import { setContentOptimizerKeywords } from '../../../../store/contentOptimizerContent.actions';
import { selectContentOptimizerKeywords } from '../../../../store/contentOptimizerContent.selectors';

const classes = {
    deletePopconfirm: 'delete-popconfirm keyword-delete-popconfirm',
    deletePopconfirmDark: 'delete-popconfirm-dark',
};

const KeywordDeletePopconfirm = ({ keywordsToDelete = [], successCallback, children }) => {
    const dispatch = useDispatch();
    const { contentOptimizerId } = useParams();

    const darkMode = useSelector(selectDarkMode);
    const optimizerKeywords = useSelector(selectContentOptimizerKeywords);

    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const organizationSlug = useOrganizationSlug();

    const handleDelete = () => {
        if (keywordsToDelete.length) {
            setIsDeleteLoading(true);

            const keywordsModel = optimizerKeywords.filter(
                (keyword) => !keywordsToDelete.includes(keyword?.keyword)
            );

            axiosAPI
                .patch(
                    `${contentOptimizerPathes.editContentOptimizer}/${contentOptimizerId}`,
                    {
                        keywords: JSON.stringify(keywordsModel),
                    },
                    {
                        ...getAxiosHeaders(),
                        params: { slug: organizationSlug },
                    }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        batch(() => {
                            dispatch(
                                setContentOptimizerKeywords(JSON.parse(result?.data?.keywords))
                            );
                            dispatch(
                                updateEditorCurrentVersionScore(result?.data?.rankability_score)
                            );
                        });

                        successCallback && successCallback();
                    }
                })
                .catch(() => {
                    openBasicErrorNotification();
                })
                .finally(() => {
                    setIsDeleteLoading(false);
                });
        }
    };

    return (
        <Popconfirm
            placement='topRight'
            title={`Are you sure you want to delete ${
                keywordsToDelete.length > 1 ? 'these keywords' : 'this keyword'
            }?`}
            onConfirm={handleDelete}
            okText='Yes'
            cancelText='Cancel'
            loading={isDeleteLoading}
            overlayClassName={`${classes.deletePopconfirm} ${
                darkMode ? 'delete-popconfirm-dark' : ''
            }`}
            getPopupContainer={getOptimizerRightBlockWrapper}
        >
            {children}
        </Popconfirm>
    );
};

export default KeywordDeletePopconfirm;
