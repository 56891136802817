import { useSelector } from 'react-redux';

import useAutoVersioning from '../../../../../../../../../common/tiptapEditor/hooks/useAutoVersioning';
import useBriefVersions from './useBriefVersions';

import {
    selectAIBriefLoading,
    selectCurrentBrief,
    selectCurrentBriefLoading,
} from '../../store/contentBriefsOutline.selectors';

const useBriefAutoVersioning = () => {
    const currentBrief = useSelector(selectCurrentBrief);
    const briefLoading = useSelector(selectCurrentBriefLoading);
    const aiLoading = useSelector(selectAIBriefLoading);

    const { createNewBriefVersion } = useBriefVersions();

    useAutoVersioning({
        content: currentBrief?.extras,
        isBlocked: aiLoading || briefLoading,
        versionHandlers: {
            create: createNewBriefVersion,
        },
    });
};

export default useBriefAutoVersioning;
