import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { axiosAPI } from '../../utils/axiosAPI';
import { getAxiosHeaders } from '../../utils/helpers/getAxiosHeaders';
import { openBasicErrorNotification } from '../../utils/helpers/openBasicErrorNotification';

import { contentOptimizerPathes } from '../../constants/queryPathes';
import { statusCodes } from '../../constants/statusCodes';

export const ContentOptimizerContext = createContext('');

const ContentOptimizerProvider = ({ children }) => {
    const { contentOptimizerId, shareLinkUrlCode, organizationSlug } = useParams();
    const [currentContentOptimizerId, setCurrentContentOptimizerId] = useState(contentOptimizerId);

    useEffect(() => {
        if (contentOptimizerId) {
            setCurrentContentOptimizerId(contentOptimizerId);
        }
    }, [contentOptimizerId]);

    const fetchContentOptimizerId = useCallback(() => {
        if (shareLinkUrlCode) {
            axiosAPI
                .get(`${contentOptimizerPathes.getShare}/${shareLinkUrlCode}`, {
                    ...getAxiosHeaders(),
                    params: { slug: organizationSlug },
                })
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        setCurrentContentOptimizerId(result?.data?.content_optimizer_id);
                    }
                })
                .catch(() => {
                    openBasicErrorNotification();
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shareLinkUrlCode]);

    useEffect(() => {
        if (!currentContentOptimizerId) {
            fetchContentOptimizerId();
        }
    }, [currentContentOptimizerId, fetchContentOptimizerId]);

    return (
        <ContentOptimizerContext.Provider value={currentContentOptimizerId}>
            {children}
        </ContentOptimizerContext.Provider>
    );
};

export default ContentOptimizerProvider;
