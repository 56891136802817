import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Input } from 'antd';
import parse from 'html-react-parser';

import { getThemeColorClass } from '../../../utils/helpers/themeHelpers';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

const { TextArea } = Input;

const classes = {
    areaWrapper: 'area-multiple-wrapper d-flex',
    areaListNumberWrapper:
        'area-multiple-list-number-wrapper d-flex flex-column align-items-center',
    areaListNumber: 'area-multiple-list-number',
    textAreaWrapper: 'text-area-wrapper',
    textArea: 'area-multiple-textarea',
    highlighterWrapper: 'area-multiple-textarea highlighter-wrapper',
    highlightedText: 'highlighted-text',
    inputError: 'input-error',
};

const MultipleCreateArea = ({
    placeholder,
    keywordArray,
    handleChange,
    isInputError,
    keywordsToHighlight = null,
}) => {
    const lineCounterRef = useRef(null);
    const textareaRef = useRef(null);
    const highlighterRef = useRef(null);

    const darkMode = useSelector(selectDarkMode);

    useEffect(() => {
        handleTextareaScroll();
    }, [keywordArray]);

    const handleTextareaScroll = () => {
        if (lineCounterRef?.current && textareaRef?.current) {
            const textAreaScrollTop = textareaRef.current.resizableTextArea.textArea.scrollTop;

            lineCounterRef.current.scrollTop = textAreaScrollTop;

            if (highlighterRef?.current) {
                highlighterRef.current.scrollTop = textAreaScrollTop;
            }
        }
    };

    const onChange = (e) => {
        handleChange(e.target.value);
    };

    const textWithHighlight = useMemo(() => {
        let formattedText = '';

        if (keywordsToHighlight) {
            keywordArray.forEach((keyword) => {
                if (keywordsToHighlight.includes(keyword)) {
                    formattedText += `<span class="${classes.highlightedText} ${getThemeColorClass(
                        darkMode
                    )}">${keyword}</span>\n`;
                } else {
                    formattedText += `${keyword}\n`;
                }
            });
        }

        return formattedText;
    }, [keywordArray, keywordsToHighlight, darkMode]);

    return (
        <div className={`${classes.areaWrapper} ${isInputError ? classes.inputError : ''}`}>
            <div className={classes.areaListNumberWrapper}>
                <div className={classes.areaListNumber} ref={lineCounterRef}>
                    {keywordArray.length > 0
                        ? keywordArray.map((_, index) => <div key={index}>{index + 1}</div>)
                        : '1'}
                </div>
            </div>
            <div className={classes.textAreaWrapper}>
                {keywordsToHighlight && (
                    <div className={classes.highlighterWrapper} ref={highlighterRef}>
                        {parse(textWithHighlight)}
                    </div>
                )}
                <TextArea
                    ref={textareaRef}
                    onChange={onChange}
                    placeholder={placeholder}
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    className={classes.textArea}
                    onScroll={handleTextareaScroll}
                />
            </div>
        </div>
    );
};

export default MultipleCreateArea;
