import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useOutlineVersions from './hooks/useOutlineVersions';
import useOutlineAutoVersioning from './hooks/useOutlineAutoVersioning';

import OutlineContainer from './content/outline/OutlineContainer';
import OutlineVersionsBar from './content/outline/OutlineVersionsBar/OutlineVersionsBar';

import { selectCurrentOutlineVersion } from '../../store/contentBriefsOutline.selectors';

import './Optimizer.scss';

const classes = {
    optimizerContainer: 'optimizer-container',
};

const OutlineOptimizerContainer = () => {
    const currentOutlineVersion = useSelector(selectCurrentOutlineVersion);

    const { contentOptimizerId, createNewOutlineVersion, fetchOutlineVersions } =
        useOutlineVersions();

    useOutlineAutoVersioning();

    useEffect(() => {
        fetchOutlineVersions();

        // eslint-disable-next-line
    }, [contentOptimizerId]);

    useEffect(() => {
        if (currentOutlineVersion?.ai_job_id) {
            const interval = setInterval(() => {
                fetchOutlineVersions();
            }, 1000 * 5);

            return () => {
                clearInterval(interval);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOutlineVersion?.ai_job_id]);

    return (
        <div className={`${classes.optimizerContainer}`}>
            <OutlineVersionsBar
                fetchOutlineVersions={fetchOutlineVersions}
                createNewOutlineVersion={createNewOutlineVersion}
            />
            <OutlineContainer createNewOutlineVersion={createNewOutlineVersion} />
        </div>
    );
};

export default OutlineOptimizerContainer;
