const geoLocationSelectHelper = {
    getGeoData: (newLocation) => {
        let country_code, geo_location;

        if (newLocation?.value?.includes('_')) {
            const spliitedItems = newLocation.value.split('_');

            country_code = spliitedItems[0];
            geo_location = spliitedItems[1];
        } else {
            country_code = newLocation?.value;
        }

        return { country_code, geo_location };
    },
};

export default geoLocationSelectHelper;
