import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import useOutlineVersions from './useOutlineVersions';

import {
    selectCurrentOutline,
    selectOutlineVersions,
} from '../../../store/contentBriefsOutline.selectors';

import { editorVersionAutoCreationDelay } from '../../../../../../../../../../common/tiptapEditor/constants/tiptapEditorConstants';

const useOutlineAutoVersioning = () => {
    const versioningTimeoutRef = useRef(null);
    const serializedContentRef = useRef(null);
    const isInitialMount = useRef(true);

    const currentOutline = useSelector(selectCurrentOutline);
    const outlineVersions = useSelector(selectOutlineVersions);

    const { createNewOutlineVersion } = useOutlineVersions();

    useEffect(() => {
        if (!!!currentOutline) {
            return;
        }

        const sortedVersions = outlineVersions.sort(
            (a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf()
        );

        const latestVersion = sortedVersions[0];

        if (isInitialMount.current) {
            isInitialMount.current = false;
            serializedContentRef.current = JSON.stringify(latestVersion?.outline || currentOutline);
            return;
        }

        const currentSerializedContent = JSON.stringify(currentOutline);

        if (serializedContentRef.current === currentSerializedContent) {
            return;
        }

        if (versioningTimeoutRef.current) {
            clearTimeout(versioningTimeoutRef.current);
        }

        versioningTimeoutRef.current = setTimeout(() => {
            createNewOutlineVersion();
            serializedContentRef.current = currentSerializedContent;
        }, editorVersionAutoCreationDelay);

        return () => {
            if (versioningTimeoutRef.current) {
                clearTimeout(versioningTimeoutRef.current);
                versioningTimeoutRef.current = null;
            }
        };
    }, [currentOutline, outlineVersions]);
};

export default useOutlineAutoVersioning;
