const SEARCH_SANITIZATION_PATTERN = /[\s.\-]/g;
const DEFAULT_SANITIZED_VALUE = '';

/**
 * Sanitizes a given search term by converting it to lowercase and removing specified characters.
 *
 * @param {string} [searchTerm=''] - The search term to sanitize.
 *    - Default value: An empty string (`''`).
 *    - This string will be converted to lowercase and have spaces, dots, and hyphens removed.
 *
 * @returns {string} The sanitized search term.
 *
 * @example
 * // Removes spaces, dots, and hyphens, and converts to lowercase
 * sanitizeSearchValue('Example-Search.Term');
 * // Returns: 'examplesearchterm'
 *
 * @example
 * // Handles empty input by returning the default sanitized value
 * sanitizeSearchValue();
 * // Returns: ''
 */
export const sanitizeSearchValue = (searchTerm = DEFAULT_SANITIZED_VALUE) => {
    return searchTerm.toLowerCase().replace(SEARCH_SANITIZATION_PATTERN, DEFAULT_SANITIZED_VALUE);
};
