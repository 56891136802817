import BulletList from '@tiptap/extension-bullet-list';
import { CharacterCount } from '@tiptap/extension-character-count';
import { Color } from '@tiptap/extension-color';
import { Dropcursor } from '@tiptap/extension-dropcursor';
import Heading from '@tiptap/extension-heading';
import Highlight from '@tiptap/extension-highlight';
import Image from '@tiptap/extension-image';
import ListItem from '@tiptap/extension-list-item';
import ListKeymap from '@tiptap/extension-list-keymap';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';

import { AiSpellCheckerNode } from './aiExtensions';
import {
    CustomClipboardExtension,
    CustomDocument,
    CustomImageResize,
    CustomLinkExtension,
    GoogleDocsPasteExtension,
    HoverElementExtension,
} from './customExtensions';

export const tiptapEditorExtensions = [
    StarterKit.configure({
        bulletList: {
            keepMarks: true,
            keepAttributes: false,
        },
        orderedList: {
            keepMarks: true,
            keepAttributes: false,
        },
        document: false,
        dropcursor: false,
    }),
    Placeholder.configure({
        placeholder: 'Start writing or paste your content here…',
    }),
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    TextStyle.configure({ types: [ListItem.name] }),
    CustomImageResize.configure({
        allowBase64: true,
        inline: true,
    }),
    Underline,
    CustomDocument.configure({
        HTMLAttributes: {
            class: 'my-custom-paragraph',
        },
    }),
    Paragraph,
    Text,
    Heading,
    TextAlign.configure({
        types: ['heading', 'paragraph'],
    }),
    OrderedList,
    BulletList,
    ListItem,
    CustomLinkExtension,
    Image.configure({
        inline: true,
    }),
    Dropcursor.configure({
        width: 1,
        class: 'dropcursor-border',
    }),
    AiSpellCheckerNode,
    Highlight.configure({ multicolor: true }),
    HoverElementExtension,
    CharacterCount,
    CustomClipboardExtension,
    ListKeymap,
    GoogleDocsPasteExtension,
];
