import React from 'react';
import { useSelector } from 'react-redux';

import { Button, Col, Modal, Row } from 'antd';

import FlagCountrySection from '../mainPagesLists/FlagCountrySection';

import AlertIcon from '../../../resources/icons/AlertIcon';
import CloseIcon from '../../../resources/icons/CloseIcon';

import { colorPalette } from '../../../resources/styles/colorPalette';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import { getThemeColorClass } from '../../../utils/helpers/themeHelpers';

const classes = {
    actionsWrapper: 'actions-wrapper',
    modalWrapper: 'duplicate-optimizer-modal-wrapper',
    titleWrapper: 'ant-modal-title duplicate-optimizer-header',
    keywordWrapper: 'keyword-wrapper',
    iconWrapper: 'icon-wrapper',
    boldText: 'fw-700',
};
const ICON_SIZE = 18;
const COLUMN_SIZE = 30;

const DuplicateOptimizerModal = ({
    title = 'Duplicate Optimizer Alert',
    optimizerData,
    isOpen,
    handleOpen,
    handleRunOptimizer,
}) => {
    const darkMode = useSelector(selectDarkMode);

    const closeModalIconColor = darkMode
        ? colorPalette.textColor.dark
        : colorPalette.textColor.light;

    const runOptimizerCreation = () => {
        handleRunOptimizer();
        handleOpen();
    };

    const DuplicateOptimizerModal = () => {
        return (
            <Row wrap={false} justify='center' align='top'>
                <Col flex={`${COLUMN_SIZE}px`}>
                    <div className={classes.iconWrapper}>
                        <AlertIcon color={colorPalette.colorPrimary} size={ICON_SIZE} />
                    </div>
                </Col>
                <Col flex='auto'>
                    <div className={classes.titleWrapper}>{title}</div>
                    <div className={classes.keywordWrapper}>
                        <span>{optimizerData?.keyword}</span>
                        <FlagCountrySection
                            countryCode={optimizerData?.countryCode}
                            languageCode={optimizerData?.languageCode}
                        />
                    </div>
                </Col>
            </Row>
        );
    };

    return (
        <Modal
            centered
            forceRender
            open={isOpen}
            title={<DuplicateOptimizerModal />}
            onCancel={handleOpen}
            footer={null}
            closeIcon={<CloseIcon color={closeModalIconColor} />}
            wrapClassName={`${classes.modalWrapper} ${getThemeColorClass(darkMode)}`}
        >
            <Row wrap={false} justify='center' align='top'>
                <Col flex={`${COLUMN_SIZE}px`}></Col>
                <Col flex='auto'>
                    <div>This Optimizer has already been run within the last 30 days.</div>
                    <div>Would you like to proceed and run it again?</div>
                </Col>
            </Row>
            <div className={classes.actionsWrapper}>
                <Button onClick={handleOpen} type='primary' ghost>
                    Cancel
                </Button>
                <Button onClick={runOptimizerCreation} type='primary'>
                    Run
                </Button>
            </div>
        </Modal>
    );
};

export default DuplicateOptimizerModal;
