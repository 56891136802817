import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectDarkMode } from '../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { generateTipTapAIJwtToken } from '../../../../../../../utils/helpers/editor/generateJWT';
import { getThemeColorClass } from '../../../../../../../utils/helpers/themeHelpers';

import { ContentOptimizerContext } from '../../../../../../hocs/ContentOptimizerProvider';

import SkeletonLoading from '../../../../../../common/SkeletonLoading';
import TipTapEditor from '../../../../../../common/tiptapEditor/TipTapEditor';
import useEditorVersions from '../../../../../../common/tiptapEditor/hooks/useEditorVersions';

import {
    selectCurrentEditorContent,
    selectCurrentEditorLoading,
    selectEditorFullWidth,
    selectEditorHighlightKeywords,
    selectEditorHtmlTags,
    selectEditorTableOfContents,
    selectGeneratedAIToken,
} from '../../../../../../common/tiptapEditor/store/tiptapEditor.selectors';
import { selectContentOptimizerKeywords } from '../../../store/contentOptimizerContent.selectors';

import { setGeneratedAIToken } from '../../../../../../common/tiptapEditor/store/tiptapEditor.actions';

import './TipTapEditorWrapper.scss';

const classes = {
    tiptapEditorWrapper: 'tiptap-editor-wrapper',
};

export const HighlightedKeywordContext = React.createContext([]);

const TipTapEditorWrapper = () => {
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);

    const currentEditorLoading = useSelector(selectCurrentEditorLoading);
    const currentEditorContent = useSelector(selectCurrentEditorContent);

    const keywords = useSelector(selectContentOptimizerKeywords);

    const editorHighlightKeywords = useSelector(selectEditorHighlightKeywords);
    const editorFullWidth = useSelector(selectEditorFullWidth);
    const editorHtmlTags = useSelector(selectEditorHtmlTags);
    const editorTableOfContents = useSelector(selectEditorTableOfContents);

    const generatedToken = useSelector(selectGeneratedAIToken);

    const [fetchEditorVersions] = useEditorVersions();

    const contentOptimizerId = useContext(ContentOptimizerContext);

    const [mappedKeywords, setMappedKeywords] = useState([]);
    const [isTokenGenerating, setIsTokenGenerating] = useState(false);

    useEffect(() => {
        if (contentOptimizerId) {
            fetchEditorVersions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentOptimizerId]);

    useEffect(() => {
        if (keywords) {
            setMappedKeywords(
                keywords?.map((item) => {
                    return {
                        regex: item?.regex,
                        keyword: item?.keyword?.toLowerCase(),
                    };
                })
            );
        }
    }, [keywords]);

    useEffect(() => {
        const getToken = async () => {
            if (!generatedToken) {
                setIsTokenGenerating(true);

                let token = await generateTipTapAIJwtToken();
                dispatch(setGeneratedAIToken(token));
                setIsTokenGenerating(false);
            }
        };

        getToken();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generatedToken]);

    return (
        <HighlightedKeywordContext.Provider value={mappedKeywords}>
            {!currentEditorLoading &&
            currentEditorContent &&
            mappedKeywords &&
            !isTokenGenerating ? (
                <div
                    className={`${classes.tiptapEditorWrapper} ${getThemeColorClass(darkMode)} ${
                        editorHighlightKeywords ? '' : 'turn-off-highlight-keywords'
                    } ${editorFullWidth ? 'turn-on-editor-full-width' : ''} ${
                        editorHtmlTags ? 'turn-on-editor-html-tags' : ''
                    } ${editorTableOfContents ? 'turn-on-editor-table-of-contents' : ''}`}
                >
                    <TipTapEditor currentEditorContent={currentEditorContent} />
                </div>
            ) : (
                <SkeletonLoading width='98%' />
            )}
        </HighlightedKeywordContext.Provider>
    );
};

export default TipTapEditorWrapper;
