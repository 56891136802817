import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Card, Typography, Button, Spin } from 'antd';
import moment from 'moment';

import {
    selectCreditsPurchaseType,
    selectCurrentSubscription,
    selectIsCurrentSubscriptionLoading,
} from '../../store/billingSettings.selectors';

import { openBasicErrorNotification } from '../../../../../../utils/helpers/openBasicErrorNotification';
import { openNotification } from '../../../../../../utils/helpers/openNotification';
import { getAxiosHeaders } from '../../../../../../utils/helpers/getAxiosHeaders';
import { usePaymentMethod } from '../../../../../../utils/hooks/usePaymentMethod';
import { useOrganizationSlug } from '../../../../../../utils/hooks/useOrganizationSlug';
import { axiosAPI } from '../../../../../../utils/axiosAPI';

import { paymentsPathes } from '../../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../../constants/statusCodes';
import { notificationType } from '../../../../../../constants/notificationType';
import {
    creditType,
    optimizerCreditsOptions,
    aiCreditsOptions,
} from '../../../../../../constants/creditsConsts';

import {
    updateOptimizersAddedSource,
    updateAIAddedSource,
} from '../../store/billingSettings.actions';

import Loader from '../../../../../common/loader/Loader';
import PaymentInfoSection from '../../content/PaymentInfoSection';
import CreditsSelectSection from './CreditsSelectSection';

const { Title } = Typography;

const classes = {
    creditsCardWrapper: 'credits-card-wrapper',
    creditsInfoWrapper: 'credits-info-wrapper credits-section-wrapper',
    creditsInfoBlock: 'credits-info-block d-flex justify-content-between align-items-center',
    creditsPrice: 'credits-price',
    creditsPaymentInfoWrapper: 'credits-payment-info-wrapper',
    creditsButtonWrapper: 'credits-button-wrapper d-flex justify-content-end',
    label: 'label',
};

const CreditsCard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const organizationSlug = useOrganizationSlug();

    const currentSubscription = useSelector(selectCurrentSubscription);
    const isSubscriptionLoading = useSelector(selectIsCurrentSubscriptionLoading);
    const creditPurchaseType = useSelector(selectCreditsPurchaseType);

    const { paymentMethod, isPaymentMethodLoaded } = usePaymentMethod();

    const [creditsPrice, setCreditsPrice] = useState({});
    const [creditsValue, setCreditsValue] = useState(
        creditPurchaseType === creditType.OPTIMIZER
            ? optimizerCreditsOptions[0].value
            : aiCreditsOptions[0].value
    );
    const [isLoading, setIsLoading] = useState(false);
    const [isCreditsLoading, setIsCreditsLoading] = useState(false);

    useEffect(() => {
        if (currentSubscription?.plan) {
            setIsLoading(true);

            axiosAPI
                .get(`${paymentsPathes.getCreditPrice}/${creditPurchaseType}`, {
                    ...getAxiosHeaders(),
                })
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        setCreditsPrice(result.data);
                    }
                })
                .catch(() => {
                    openBasicErrorNotification();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [currentSubscription?.plan, creditPurchaseType]);

    const handleCreditsChange = (value) => {
        setCreditsValue(value);
    };

    const handleCreditsPurchase = () => {
        setIsCreditsLoading(true);

        axiosAPI
            .post(
                paymentsPathes.addCredits,
                {
                    price_id: creditsPrice?.id,
                    quantity: creditsValue,
                    credit_type: creditPurchaseType,
                },
                {
                    ...getAxiosHeaders(),
                }
            )
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    openNotification(
                        notificationType.success,
                        'Success',
                        'Credits were successfully added'
                    );

                    if (creditPurchaseType === creditType.AI) {
                        dispatch(
                            updateAIAddedSource({
                                ai: result?.data?.openai_briefs_added_source,
                                ai_added: result?.data?.openai_briefs_added,
                            })
                        );
                    } else {
                        dispatch(
                            updateOptimizersAddedSource({
                                optimizers: result?.data?.optimizers_added_source,
                                optimizers_added: result?.data?.optimizers_added,
                            })
                        );
                    }

                    navigate(`/${organizationSlug}/account/billing-credits`);
                }
            })
            .catch(() => {
                openBasicErrorNotification();
            })
            .finally(() => {
                setIsCreditsLoading(false);
            });
    };

    const perCreditPrice = (creditsPrice?.unit_amount / 100 || 0)?.toFixed(2);

    return (
        <Card className={classes.creditsCardWrapper}>
            <Spin
                spinning={isSubscriptionLoading || isPaymentMethodLoaded || isLoading}
                indicator={<Loader />}
            >
                <CreditsSelectSection
                    creditsValue={creditsValue}
                    handleChange={handleCreditsChange}
                />
                <div className={classes.creditsInfoWrapper}>
                    <div className={classes.creditsInfoBlock}>
                        <Title level={5}>
                            {creditsValue}{' '}
                            {creditPurchaseType === creditType.AI ? 'AI' : 'Optimizer'} credits at $
                            {perCreditPrice}
                            /credit
                        </Title>
                    </div>
                    <div className={classes.creditsInfoBlock}>
                        <Title level={4}>Total</Title>
                        <Title level={4} className={classes.creditsPrice}>
                            ${(creditsValue * perCreditPrice).toFixed(2)}
                        </Title>
                    </div>
                    <Typography className={classes.label}>
                        {creditPurchaseType === creditType.AI ? 'AI ' : ''}Credits are added
                        immediately and will expire on{' '}
                        {moment().add(1, 'M').format('MMM DD, YYYY') || ''}
                    </Typography>
                </div>

                <PaymentInfoSection paymentMethod={paymentMethod} />

                <div className={classes.creditsButtonWrapper}>
                    <Button
                        type='primary'
                        loading={isCreditsLoading}
                        onClick={handleCreditsPurchase}
                    >
                        Complete purchase
                    </Button>
                </div>
            </Spin>
        </Card>
    );
};

export default CreditsCard;
