import React from 'react';
import { useSelector } from 'react-redux';

import { useCurrentEditor } from '@tiptap/react';
import { Typography } from 'antd';

import { getThemeColorClass } from '../../../../utils/helpers/themeHelpers';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';
import { selectOptimizerTableOfContentsItems } from '../store/tiptapEditor.selectors';

import BasicOutlineTag from '../../outlineTags/BasicOutlineTag';

import useActiveHeading from '../hooks/useActiveHeading';

const classes = {
    tableOfContentsWrapper: 'table-of-contents',
};

const TableOfContentsAttr = 'data-toc-id';

const TableOfContents = () => {
    const { editor } = useCurrentEditor();
    const darkMode = useSelector(selectDarkMode);
    const tableOfContentsItems = useSelector(selectOptimizerTableOfContentsItems);
    const { activeHeading, scrollToHeading } = useActiveHeading(editor, TableOfContentsAttr);

    return (
        <div className={`${classes.tableOfContentsWrapper} ${getThemeColorClass(darkMode)}`}>
            <Typography.Title level={4}>Table of Contents</Typography.Title>
            {tableOfContentsItems.length > 0 ? (
                tableOfContentsItems.map((item) => (
                    <BasicOutlineTag
                        key={item.id}
                        tag={`h${item.level}`}
                        label={item.label}
                        addButtonOption={false}
                        isBulletedTag={false}
                        isTagActive={activeHeading === item.id}
                        handleRowClick={() => scrollToHeading(item.id, item.pos)}
                    />
                ))
            ) : (
                <Typography>
                    Add headings to your document to generate a table of contents.
                </Typography>
            )}
        </div>
    );
};

export default React.memo(TableOfContents);
