export const tiptapEditorActions = {
    // Main editor content
    CLEAR_TIPTAP_EDITOR: 'TIPTAP_EDITOR/CLEAR_TIPTAP_EDITOR',
    UPDATE_CURRENT_EDITOR_CONTENT: 'TIPTAP_EDITOR/UPDATE_CURRENT_EDITOR_CONTENT',
    ADDITIONAL_CONTENT_TO_EDITOR: 'TIPTAP_EDITOR/ADDITIONAL_CONTENT_TO_EDITOR',
    UPDATE_CURRENT_EDITOR_TEXT: 'TIPTAP_EDITOR/UPDATE_CURRENT_EDITOR_TEXT',

    // Loadings
    UPDATE_CURRENT_EDITOR_LOADING: 'TIPTAP_EDITOR_LOADING/UPDATE_CURRENT_EDITOR_LOADING',
    UPDATE_SAVE_EDITOR_LOADING: 'TIPTAP_EDITOR_LOADING/UPDATE_SAVE_EDITOR_LOADING',

    // Versions
    UPDATE_EDITOR_VERSIONS: 'TIPTAP_EDITOR_VERSIONS/UPDATE_EDITOR_VERSIONS',
    ADD_EDITOR_VERSION: 'TIPTAP_EDITOR_VERSIONS/ADD_EDITOR_VERSION',
    UPDATE_EDITOR_DATA_AFTER_SAVE: 'TIPTAP_EDITOR_VERSIONS/UPDATE_EDITOR_DATA_AFTER_SAVE',
    UPDATE_EDITOR_BASIC_DATA: 'TIPTAP_EDITOR_VERSIONS/UPDATE_EDITOR_BASIC_DATA',
    UPDATE_EDITOR_CURRENT_VERSION_SCORE:
        'TIPTAP_EDITOR_VERSIONS/UPDATE_EDITOR_CURRENT_VERSION_SCORE',
    UPDATE_EDITOR_CURRENT_VERSION_WORD_COUNT:
        'TIPTAP_EDITOR_VERSIONS/UPDATE_EDITOR_CURRENT_VERSION_WORD_COUNT',
    UPDATE_EDITOR_VERSION_IMPORTED_URL: 'TIPTAP_EDITOR_VERSIONS/UPDATE_EDITOR_VERSION_IMPORTED_URL',
    SET_EDITOR_PREVIEW_VERSION: 'TIPTAP_EDITOR_VERSIONS/SET_EDITOR_PREVIEW_VERSION',
    UPDATE_EDITOR_VERSION_NAME: 'TIPTAP_EDITOR_VERSIONS/UPDATE_EDITOR_VERSION_NAME',
    DELETE_EDITOR_VERSION: 'TIPTAP_EDITOR_VERSIONS/DELETE_EDITOR_VERSION',

    // Settings
    UPDATE_HTML_TAGS: 'TIPTAP_EDITOR_SETTINGS/UPDATE_HTML_TAGS',
    UPDATE_HIGHLIGHT_KEYWORDS: 'TIPTAP_EDITOR_SETTINGS/UPDATE_HIGHLIGHT_KEYWORDS',
    UPDATE_EDITOR_FULL_WIDTH: 'TIPTAP_EDITOR_SETTINGS/UPDATE_EDITOR_FULL_WIDTH',
    UPDATE_TABLE_OF_CONTENTS: 'TIPTAP_EDITOR_SETTINGS/UPDATE_TABLE_OF_CONTENTS',
    SET_TABLE_OF_CONTENT_ITEMS: 'TIPTAP_EDITOR_SETTINGS/SET_TABLE_OF_CONTENT_ITEMS',

    // AI Token generation
    SET_GENERATED_AI_TOKEN: 'TIPTAP_AI/SET_GENERATED_AI_TOKEN',
    SET_AI_SPELL_CHECKER_VISIBLE: 'TIPTAP_AI/SET_AI_SPELL_CHECKER_VISIBLE',
    SET_AI_STATE: 'TIPTAP_AI/SET_AI_STATE',
    RESET_AI_STATE: 'TIPTAP_AI/RESET_AI_STATE',
    SET_AI_OPTIMIZED_ID: 'TIPTAP_AI/SET_AI_OPTIMIZED_ID',
};

// Settings
export const updateEditorHtmlTags = (payload) => ({
    type: tiptapEditorActions.UPDATE_HTML_TAGS,
    payload,
});
export const updateEditorHighlightKeywords = (payload) => ({
    type: tiptapEditorActions.UPDATE_HIGHLIGHT_KEYWORDS,
    payload,
});
export const updateEditorFullWidth = (payload) => ({
    type: tiptapEditorActions.UPDATE_EDITOR_FULL_WIDTH,
    payload,
});
export const updateEditorTableOfContents = (payload) => ({
    type: tiptapEditorActions.UPDATE_TABLE_OF_CONTENTS,
    payload,
});
export const setTableOfContentItems = (payload) => ({
    type: tiptapEditorActions.SET_TABLE_OF_CONTENT_ITEMS,
    payload,
});

// Versions
export const updateEditorVersions = (payload) => ({
    type: tiptapEditorActions.UPDATE_EDITOR_VERSIONS,
    payload,
});
export const addEditorVersion = (payload) => ({
    type: tiptapEditorActions.ADD_EDITOR_VERSION,
    payload,
});
export const updateEditorDataAfterSave = (payload) => ({
    type: tiptapEditorActions.UPDATE_EDITOR_DATA_AFTER_SAVE,
    payload,
});
export const updateEditorBasicData = (payload) => {
    const {
        readability,
        rankability_score,
        word_count,
        optimal_readability,
        optimal_rankability_score,
        optimal_word_count,
    } = payload;

    return {
        type: tiptapEditorActions.UPDATE_EDITOR_BASIC_DATA,
        payload: {
            readability,
            rankability_score,
            word_count,
            optimal_readability,
            optimal_rankability_score,
            optimal_word_count,
        },
    };
};
export const updateEditorOptimalsBasicData = (payload) => {
    const { optimal_readability, optimal_rankability_score, optimal_word_count } = payload;

    return {
        type: tiptapEditorActions.UPDATE_EDITOR_BASIC_DATA,
        payload: {
            optimal_readability,
            optimal_rankability_score,
            optimal_word_count,
        },
    };
};
export const updateEditorCurrentVersionScore = (payload) => ({
    type: tiptapEditorActions.UPDATE_EDITOR_CURRENT_VERSION_SCORE,
    payload,
});
export const updateEditorCurrentVersionWordCount = (payload) => ({
    type: tiptapEditorActions.UPDATE_EDITOR_CURRENT_VERSION_WORD_COUNT,
    payload,
});
export const updateEditorVersionImportedUrl = (payload) => ({
    type: tiptapEditorActions.UPDATE_EDITOR_VERSION_IMPORTED_URL,
    payload,
});
export const setEditorPreviewVersion = (payload) => ({
    type: tiptapEditorActions.SET_EDITOR_PREVIEW_VERSION,
    payload,
});
export const updateEditorVersionName = (payload) => ({
    type: tiptapEditorActions.UPDATE_EDITOR_VERSION_NAME,
    payload,
});
export const deleteEditorVersion = (payload) => ({
    type: tiptapEditorActions.DELETE_EDITOR_VERSION,
    payload,
});

// Loadings
export const updateSaveEditorLoading = (payload) => ({
    type: tiptapEditorActions.UPDATE_SAVE_EDITOR_LOADING,
    payload,
});
export const updateCurrentEditorLoading = (payload) => ({
    type: tiptapEditorActions.UPDATE_CURRENT_EDITOR_LOADING,
    payload,
});

// Main editor content
export const updateCurrentEditorContent = (payload) => ({
    type: tiptapEditorActions.UPDATE_CURRENT_EDITOR_CONTENT,
    payload,
});
export const clearContentOptimizerEditor = () => ({
    type: tiptapEditorActions.CLEAR_TIPTAP_EDITOR,
});
export const additionalContentToEditor = (payload) => ({
    type: tiptapEditorActions.ADDITIONAL_CONTENT_TO_EDITOR,
    payload,
});
export const updateCurrentEditorText = (payload) => ({
    type: tiptapEditorActions.UPDATE_CURRENT_EDITOR_TEXT,
    payload,
});

// AI Token Generation
export const setGeneratedAIToken = (payload) => ({
    type: tiptapEditorActions.SET_GENERATED_AI_TOKEN,
    payload,
});
export const setAIState = (payload) => ({
    type: tiptapEditorActions.SET_AI_STATE,
    payload,
});
export const setAISpellCheckerVisible = (payload) => ({
    type: tiptapEditorActions.SET_AI_SPELL_CHECKER_VISIBLE,
    payload,
});
export const resetAIState = () => ({
    type: tiptapEditorActions.RESET_AI_STATE,
});
export const setAIOptimizerId = (payload) => ({
    type: tiptapEditorActions.SET_AI_OPTIMIZED_ID,
    payload,
});
