import moment from 'moment';

import { addNewVersionToArray } from '../../../../utils/helpers/contentOptimizerVersionsHelper';

import { defaultExtractContentEditorContent } from '../toolbar/constants';

import { tiptapEditorActions } from './tiptapEditor.actions';

const contentOptimizerEditorInitialState = {
    // Main editor content
    currentEditorContent: null,
    currentEditorText: null,
    currentEditorId: null,
    additionalEditorContent: null,

    // Loadings
    currentEditorLoading: false,
    saveEditorLoading: false,

    // Versions
    editorVersions: null,
    currentEditorVersion: null,
    currentVersionImportedUrl: null,
    previewVersion: null,

    // BasicData contains rs score, readability, word count
    basicData: {
        readability: null,
        rankability_score: null,
        word_count: 0,
        optimal_readability: {
            min: 100,
            max: 100,
        },
        optimal_rankability_score: {
            min: 0,
            max: 85,
        },
        optimal_word_count: 0,
    },

    // Settings
    highlightKeywords: true,
    htmlTags: JSON.parse(localStorage.getItem('editorHtmlTags')) || false,
    fullWidth: JSON.parse(localStorage.getItem('editorFullWidth')) || false,
    tableOfContents: JSON.parse(localStorage.getItem('editorTableOfContents')) || false,
    tableOfContentsItems: [],

    // AI
    generatedAIToken: null,
    aiState: {
        isLoading: false,
        errorMessage: null,
        response: null,
        optimizerId: null,
    },
    aiSpellCheckerVisible: false,
};

export const contentOptimizerEditorReducer = (
    state = contentOptimizerEditorInitialState,
    { type, payload }
) => {
    switch (type) {
        // Main editor content
        case tiptapEditorActions.UPDATE_CURRENT_EDITOR_CONTENT: {
            return {
                ...state,
                currentEditorContent: payload,
            };
        }
        case tiptapEditorActions.ADDITIONAL_CONTENT_TO_EDITOR: {
            return {
                ...state,
                additionalEditorContent: payload,
            };
        }
        case tiptapEditorActions.UPDATE_CURRENT_EDITOR_TEXT: {
            return {
                ...state,
                currentEditorText: payload,
            };
        }
        case tiptapEditorActions.CLEAR_TIPTAP_EDITOR: {
            const tableOfContents = state.tableOfContents;
            const htmlTags = state.htmlTags;
            const fullWidth = state.fullWidth;

            return {
                ...contentOptimizerEditorInitialState,
                tableOfContents,
                htmlTags,
                fullWidth,
            };
        }

        // Loadings
        case tiptapEditorActions.UPDATE_SAVE_EDITOR_LOADING: {
            return {
                ...state,
                saveEditorLoading: payload,
            };
        }
        case tiptapEditorActions.UPDATE_CURRENT_EDITOR_LOADING: {
            return {
                ...state,
                currentEditorLoading: payload,
            };
        }

        // Versions
        case tiptapEditorActions.UPDATE_EDITOR_VERSIONS: {
            const currentEditorVersion = payload?.find((item) => item?.active);
            const editorVersions = payload?.sort(
                (a, b) => moment(b?.created_at) - moment(a?.created_at)
            );

            let parsedEditorContent;

            try {
                parsedEditorContent = JSON.parse(currentEditorVersion?.content);
            } catch (e) {
                parsedEditorContent = null;
            }

            return {
                ...state,
                currentEditorContent: parsedEditorContent
                    ? parsedEditorContent
                    : defaultExtractContentEditorContent,
                currentEditorId: currentEditorVersion?.id,

                editorVersions: editorVersions,
                currentEditorVersion: currentEditorVersion,
                currentVersionImportedUrl: currentEditorVersion?.import_url,
                previewVersion: null,
                basicData: currentEditorVersion
                    ? {
                          ...state?.basicData,
                          rankability_score: currentEditorVersion.rankability_score,
                          word_count: currentEditorVersion.word_count,
                          readability: currentEditorVersion.readability,
                          optimal_readability: currentEditorVersion.optimal_readability,
                          optimal_rankability_score: currentEditorVersion.optimal_rankability_score,
                          optimal_word_count: currentEditorVersion.optimal_word_count,
                      }
                    : { ...state?.basicData },
            };
        }
        case tiptapEditorActions.ADD_EDITOR_VERSION: {
            return {
                ...state,
                editorVersions: addNewVersionToArray(payload, state.editorVersions),
            };
        }
        case tiptapEditorActions.UPDATE_EDITOR_DATA_AFTER_SAVE: {
            const newEditorVersions = [
                ...state.editorVersions?.map((version) =>
                    version.active
                        ? {
                              ...version,
                              word_count: payload.word_count,
                              rankability_score: payload.rankability_score,
                              readability: payload.readability,
                          }
                        : version
                ),
            ];

            return {
                ...state,
                currentEditorVersion: {
                    ...state.currentEditorVersion,
                    word_count: payload.word_count,
                    rankability_score: payload.rankability_score,
                    readability: payload.readability,
                },
                editorVersions: newEditorVersions,
            };
        }
        case tiptapEditorActions.UPDATE_EDITOR_CURRENT_VERSION_SCORE: {
            return {
                ...state,
                editorVersions: state.editorVersions?.map((item) => {
                    if (item?.active) {
                        return {
                            ...item,
                            rankability_score: payload,
                        };
                    }

                    return item;
                }),
                currentEditorVersion: {
                    ...state.currentEditorVersion,
                    rankability_score: payload,
                },
                basicData: {
                    ...state.basicData,
                    rankability_score: payload,
                },
            };
        }
        case tiptapEditorActions.UPDATE_EDITOR_BASIC_DATA: {
            return {
                ...state,
                basicData: {
                    ...state.basicData,
                    ...payload,
                },
            };
        }
        case tiptapEditorActions.UPDATE_EDITOR_CURRENT_VERSION_WORD_COUNT: {
            return {
                ...state,
                editorVersions: state.editorVersions?.map((item) => {
                    if (item?.active) {
                        return {
                            ...item,
                            word_count: payload,
                        };
                    }

                    return item;
                }),
                currentEditorVersion: {
                    ...state.currentEditorVersion,
                    word_count: payload,
                },
            };
        }
        case tiptapEditorActions.UPDATE_EDITOR_VERSION_IMPORTED_URL: {
            return {
                ...state,
                currentVersionImportedUrl: payload,
            };
        }
        case tiptapEditorActions.SET_EDITOR_PREVIEW_VERSION: {
            return {
                ...state,
                previewVersion: payload
                    ? {
                          ...state.editorVersions.find((item) => item?.id === payload),
                      }
                    : null,
                basicData: payload
                    ? {
                          ...state.editorVersions.find((item) => item?.id === payload),
                      }
                    : {
                          ...state.currentEditorVersion,
                      },
            };
        }
        case tiptapEditorActions.UPDATE_EDITOR_VERSION_NAME: {
            return {
                ...state,
                editorVersions: state.editorVersions?.map((item) => {
                    if (item?.id === payload?.id) {
                        return {
                            ...item,
                            name: payload?.name,
                        };
                    }

                    return item;
                }),
            };
        }
        case tiptapEditorActions.DELETE_EDITOR_VERSION: {
            return {
                ...state,
                editorVersions: state.editorVersions?.filter((item) => item?.id !== payload),
            };
        }

        // Settings
        case tiptapEditorActions.UPDATE_HIGHLIGHT_KEYWORDS: {
            return {
                ...state,
                highlightKeywords: payload,
            };
        }
        case tiptapEditorActions.UPDATE_EDITOR_FULL_WIDTH: {
            localStorage.setItem('editorFullWidth', payload || false);

            return {
                ...state,
                fullWidth: payload || false,
            };
        }
        case tiptapEditorActions.UPDATE_HTML_TAGS: {
            localStorage.setItem('editorHtmlTags', payload || false);

            return {
                ...state,
                htmlTags: payload || false,
            };
        }
        case tiptapEditorActions.UPDATE_TABLE_OF_CONTENTS: {
            localStorage.setItem('editorTableOfContents', payload || false);

            return {
                ...state,
                tableOfContents: payload || false,
            };
        }
        case tiptapEditorActions.SET_TABLE_OF_CONTENT_ITEMS: {
            if (JSON.stringify(state.tableOfContentsItems) === JSON.stringify(payload)) {
                return state;
            }

            return {
                ...state,
                tableOfContentsItems: payload,
            };
        }

        // AI
        case tiptapEditorActions.SET_GENERATED_AI_TOKEN: {
            return {
                ...state,
                generatedAIToken: payload,
            };
        }
        case tiptapEditorActions.SET_AI_STATE: {
            return {
                ...state,
                aiState: {
                    ...state.aiState,
                    ...payload,
                },
            };
        }
        case tiptapEditorActions.SET_AI_SPELL_CHECKER_VISIBLE: {
            return {
                ...state,
                aiSpellCheckerVisible: payload,
            };
        }
        case tiptapEditorActions.RESET_AI_STATE: {
            return {
                ...state,
                aiState: contentOptimizerEditorInitialState.aiState,
                aiSpellCheckerVisible: false,
            };
        }
        case tiptapEditorActions.SET_AI_OPTIMIZED_ID: {
            return {
                ...state,
                aiState: {
                    ...state.aiState,
                    optimizerId: payload,
                },
            };
        }

        default:
            return state;
    }
};
