import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Switch, Typography } from 'antd';

import { selectContentOptimizerViewOnlyMode } from '../../../../../../../viewOnly/store/viewOnlySettings.selectors';

import {
    updateEditorFullWidth,
    updateEditorHighlightKeywords,
    updateEditorHtmlTags,
    updateEditorTableOfContents,
} from '../../../../../../../../../common/tiptapEditor/store/tiptapEditor.actions';
import {
    selectEditorFullWidth,
    selectEditorHighlightKeywords,
    selectEditorHtmlTags,
    selectEditorTableOfContents,
} from '../../../../../../../../../common/tiptapEditor/store/tiptapEditor.selectors';
import {
    collapseSidebar,
    openSidebar,
} from '../../../../../../../../../common/sidebar/store/sidebar.actions';

const { Title } = Typography;

const classes = {
    EditorMoreSettingsWrapper: 'editor-more-settings-wrapper',
    editorMoreSettingItem: 'editor-more-setting-item d-flex space-no-wrap',
};

const EditorMoreSettings = () => {
    const dispatch = useDispatch();

    const editorHtmlTags = useSelector(selectEditorHtmlTags);
    const editorHighlightKeywords = useSelector(selectEditorHighlightKeywords);
    const editorFullWidth = useSelector(selectEditorFullWidth);
    const editorTableOfContents = useSelector(selectEditorTableOfContents);
    const viewOnlyMode = useSelector(selectContentOptimizerViewOnlyMode);

    const toggleTableOfContents = (value) => {
        const isTableOfContentsToBeToggled = !!value;

        batch(() => {
            dispatch(updateEditorTableOfContents(value));
            dispatch(updateEditorFullWidth(isTableOfContentsToBeToggled));
            isTableOfContentsToBeToggled ? dispatch(collapseSidebar()) : dispatch(openSidebar());
        });
    };

    const editorSettingsItems = [
        {
            title: 'Highlight Keywords',
            value: editorHighlightKeywords,
            onChange: (value) => dispatch(updateEditorHighlightKeywords(value)),
        },
        {
            title: 'HTML Tags',
            value: editorHtmlTags,
            onChange: (value) => dispatch(updateEditorHtmlTags(value)),
        },
        {
            title: 'Table of Contents',
            value: editorTableOfContents,
            onChange: toggleTableOfContents,
        },
        {
            title: 'Full Width',
            value: editorFullWidth,
            onChange: (value) => dispatch(updateEditorFullWidth(value)),
        },
    ];

    return (
        <div
            className={`${classes.EditorMoreSettingsWrapper}
                         ${viewOnlyMode && 'view-only'}`}
        >
            {editorSettingsItems.map((item) => (
                <div key={item.title} className={classes.editorMoreSettingItem}>
                    <Switch size='small' onChange={item.onChange} checked={item.value} />
                    <Title level={5}>{item.title}</Title>
                </div>
            ))}
        </div>
    );
};

export default EditorMoreSettings;
