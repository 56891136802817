import React from 'react';
import MastermindCallIcon from '../../../../resources/icons/MastermindCallIcon';
import { colorPalette } from '../../../../resources/styles/colorPalette';
import { useIconColor } from '../../../../utils/hooks/useIconColor';
import { useOpen } from '../../../../utils/hooks/useOpen';
import MastermindCallModal from './mastermindCallModal/MastermindCallModal';
import MastermindTimer, { MASTERMIND_MEETING_ID } from './mastermindCallModal/MastermindTimer';

import { useSelector } from 'react-redux';
import {
    selectDarkMode,
    selectIsSubscriptionExpired,
} from '../../../../userBrowserSettings/store/browserSettings.selectors';

import './MastermindCountdown.scss';

const CallIsActiveComponent = () => {
    const labelText = 'Click to join live call';
    const isSubscriptionExpired = useSelector(selectIsSubscriptionExpired);

    return isSubscriptionExpired ? (
        <span className='live-call-link'>{labelText}</span>
    ) : (
        <a
            className='live-call-link'
            href={`https://us02web.zoom.us/j/${MASTERMIND_MEETING_ID}`}
            target='_blank'
            rel='noreferrer'
        >
            {labelText}
        </a>
    );
};

const MastermindCountdown = ({ collapsed }) => {
    const iconColor = useIconColor();
    const darkMode = useSelector(selectDarkMode);
    const isSubscriptionExpired = useSelector(selectIsSubscriptionExpired);

    const { isOpen: isMastermindCallOpen, handleOpen: handleMastermindCallOpen } = useOpen();

    const preventClick = (e) => {
        e?.preventDefault();
    };

    const openMastermindCallModal = () => {
        handleMastermindCallOpen();
    };

    return (
        <div
            className={`mastermind-item-container cursor-pointer ${
                isMastermindCallOpen ? 'modal-open' : ''
            } ${isSubscriptionExpired ? 'mastermind-call-disabled' : ''} ${
                darkMode ? 'dark-mode' : ''
            } ${collapsed ? 'collapsed' : ''}`}
            onClick={isSubscriptionExpired ? preventClick : openMastermindCallModal}
        >
            <div className='mastermind-item-label'>
                <MastermindCallIcon
                    color={isMastermindCallOpen ? colorPalette.colorPrimary : iconColor}
                    className={'mastermind-icon'}
                />
                {!collapsed && <span className='title ml-8px'>Live Mastermind</span>}
            </div>
            {!collapsed && (
                <MastermindTimer
                    showTitle={false}
                    shortizeTimePeriodWord={true}
                    callIsActiveCustomComponent={<CallIsActiveComponent />}
                    timePartsClassName='mastermind-sidebar-countdown'
                    timeItemsWrapperClassName='time-items-wrapper d-flex'
                />
            )}
            {!isSubscriptionExpired && isMastermindCallOpen && (
                <MastermindCallModal
                    isModalOpen={isMastermindCallOpen}
                    handleCancel={openMastermindCallModal}
                />
            )}
        </div>
    );
};

export default MastermindCountdown;
