import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import { hTagsLines } from '../../../constants/hTagsLines';

import { getThemeColorClass } from '../../../utils/helpers/themeHelpers';

import AddButton from './AddButton';

import './OutlineTags.scss';

const classes = {
    basicOutlineTag: 'basic-outline-tag',
    prefixTag: 'prefix-tag',
    basicOutlineTagWrapper: 'basic-outline-tag-wrapper',
    addButtonWrapper: 'add-button-wrapper',
    bulletedTag: 'bulleted-tag',
    activeHeading: 'active-heading',
};

const BasicOutlineTag = ({
    tag = 'h2',
    label = 'Basic Outline Tag',
    addButtonOption,
    handleRowClick,
    isBulletedTag,
    isTagActive = false,
}) => {
    const darkMode = useSelector(selectDarkMode);
    const themeColorClass = getThemeColorClass(darkMode);

    return (
        <div
            className={`${classes.basicOutlineTagWrapper} ${themeColorClass}`}
            onClick={handleRowClick}
        >
            {hTagsLines[tag]}
            <div className={`${classes.basicOutlineTag}`}>
                <div className='d-flex flex-baseline'>
                    <div
                        className={`${classes.prefixTag} ${tag} ${themeColorClass} ${
                            isBulletedTag ? classes.bulletedTag : ''
                        }`}
                    >
                        {isBulletedTag ? '•' : tag.toUpperCase()}
                    </div>
                    <Typography
                        className={`${tag} ${themeColorClass} ${
                            isTagActive ? classes.activeHeading : ''
                        }`}
                    >
                        {label}
                    </Typography>
                </div>

                {addButtonOption && (
                    <div className={classes.addButtonWrapper}>
                        <AddButton />
                    </div>
                )}
            </div>
        </div>
    );
};

export default BasicOutlineTag;
