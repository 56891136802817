export const invoicesLimit = 4;

export const billingPlanIntervals = {
    monthly: 'month',
    yearly: 'year',
};

export const priceCurrency = {
    usd: 'usd',
};

export const planFeatures = {
    user_seats: {
        title: 'User Seats',
        shortTitle: 'Users',
        value: 1,
        yearlyCoefficient: 1,
        trialValue: 1,
    },
    keyword_researches: {
        title: 'Keyword Searches',
        value: 100,
        yearlyCoefficient: 12,
        trialValue: 5,
    },
    optimizers: {
        title: 'Content Optimizers',
        value: 10,
        yearlyCoefficient: 12,
        trialValue: 5,
    },
    content_monitors: {
        title: 'Content Monitors',
        value: 10,
        yearlyCoefficient: 1,
        trialValue: 2,
    },
    openai_briefs: {
        title: 'AI Credits',
        value: 20,
        yearlyCoefficient: 12,
        trialValue: 10,
    },
    monthRollover: {
        title: 'Month Rollover',
        value: 1,
        yearlyCoefficient: 12,
        hideIfTrial: true,
    },
};

export const trialLengthInDays = 7;
