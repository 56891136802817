import { useDispatch, useSelector } from 'react-redux';

import { GoogleOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';

import { difficultyBadgeSize } from '../../../../../../../constants/difficultyBadgeSize';
import { rankabilityScoreCategories } from '../../../../../../../constants/rankabilityScoreCategories';
import { sortOrders } from '../../../../../../../constants/sortOrders';

import ContentOptimizerIcon from '../../../../../../../resources/icons/ContentOptimizer';
import DecreaseArrowIcon from '../../../../../../../resources/icons/DecreaseArrowIcon';
import IncreaseArrowIcon from '../../../../../../../resources/icons/IncreaseArrowIcon';

import { selectDarkMode } from '../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { getNewOptimizerUrl } from '../../../../../../../utils/helpers/getNewOptimizerUrl';
import {
    getThemeColorClass,
    getTooltipOverlayClass,
} from '../../../../../../../utils/helpers/themeHelpers';
import { useOrganizationSlug } from '../../../../../../../utils/hooks/useOrganizationSlug';

import CompleteBadge from '../../../../../../common/completeBadge/CompleteBadge';
import FlagCountrySection from '../../../../../../common/mainPagesLists/FlagCountrySection';
import TableInfoModal from '../../../../../../common/tableInfo/TableInfoModal';
import TableHeaderSortIcons from '../../../../../../common/tables/TableHeaderSortIcons';
import MonitorPageRowColumn from './MonitorPageRowColumn';

import { monitorsSortKeys } from '../constants';

import { updateMonitorsSortKey } from '../../../store/contentMonitorMain.actions';
import {
    selectMonitorsSortDescending,
    selectMonitorsSortKey,
} from '../../../store/contentMonitorMain.selectors';

const { Link } = Typography;

const classes = {
    tableHeaderTitle:
        'd-flex align-items-center competitors-table-header-title cursor-pointer space-no-wrap',
    monitoredPageTitle: 'monitored-page-title',
    active: 'active',
    textDotted: 'text-decoration-dotted',

    reletedSearches: 'd-flex',
    actionsButtons: 'actions-buttons',
    magicPen: 'magic-pen-icon',

    alignValue: 'd-flex align-items-center',
    disabledText: 'monitor-disabled-text',
};

export const useContentMonitorTableColumns = () => {
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);

    const monitorsSortKey = useSelector(selectMonitorsSortKey);
    const monitorsSortDescending = useSelector(selectMonitorsSortDescending);

    const organizationSlug = useOrganizationSlug();

    const handleSorting = (sortKey) => () => {
        dispatch(updateMonitorsSortKey(sortKey));
    };

    const getColumnTitle = ({
        label,
        sortKey,
        customClassName = '',
        tooltipTitle = '',
        modalTitle = '',
    }) => (
        <div
            className={`${customClassName} ${classes.tableHeaderTitle} ${getThemeColorClass(
                darkMode
            )} ${monitorsSortKey === sortKey ? classes.active : ''}`}
            onClick={handleSorting(sortKey)}
        >
            {tooltipTitle ? (
                <Tooltip title={tooltipTitle} overlayClassName={getTooltipOverlayClass(darkMode)}>
                    <div className={classes.textDotted}>{label}</div>
                </Tooltip>
            ) : (
                <div>{label}</div>
            )}
            {modalTitle && (
                <TableInfoModal title={modalTitle} infoCategories={rankabilityScoreCategories} />
            )}
            <TableHeaderSortIcons
                active={monitorsSortKey === sortKey}
                order={monitorsSortDescending ? sortOrders.asc : sortOrders.desc}
            />
        </div>
    );

    return [
        {
            title: getColumnTitle({
                label: 'MONITORED PAGE',
                sortKey: monitorsSortKeys.monitoredPage,
                customClassName: classes.monitoredPageTitle,
            }),
            dataIndex: 'monitoredPage',
            key: 'monitoredPage',
            width: '41%',
            fixed: 'left',
            render: (_, record) => <MonitorPageRowColumn record={record} />,
        },
        {
            title: getColumnTitle({
                label: 'MONITORED KEYWORD',
                sortKey: monitorsSortKeys.monitoredKeyword,
            }),
            dataIndex: 'monitoredKeyword',
            key: 'monitoredKeyword',
            width: '23%',
            render: (_, record) => {
                return (
                    <div className={`${classes.reletedSearches} `}>
                        <p className={record?.disabled ? classes.disabledText : ''}>
                            {record?.monitoredKeyword}
                        </p>
                        <FlagCountrySection
                            countryCode={record?.monitoredPage?.country_code}
                            languageCode={record?.monitoredPage?.language_code}
                            concreteLocation={record?.monitoredPage?.geo_location_obj}
                        />
                        {!record?.disabled && (
                            <>
                                <Tooltip
                                    placement='top'
                                    title={
                                        'Open Google search results for this keyword in a new tab.'
                                    }
                                    trigger={['hover']}
                                    overlayClassName={`${getTooltipOverlayClass(darkMode)}`}
                                >
                                    <Link
                                        href={`https://www.google.com/search?q=${record?.monitoredKeyword.replace(
                                            ' ',
                                            '+'
                                        )}`}
                                        target='_blank'
                                        rel='noreferrer'
                                        className={classes.actionsButtons}
                                    >
                                        <GoogleOutlined />
                                    </Link>
                                </Tooltip>
                                <Tooltip
                                    placement='top'
                                    title={'Open a new Optimizer for this keyword in a new tab.'}
                                    trigger={['hover']}
                                    overlayClassName={`${getTooltipOverlayClass(darkMode)}`}
                                >
                                    <Link
                                        href={getNewOptimizerUrl(
                                            organizationSlug,
                                            record?.monitoredKeyword
                                        )}
                                        target='_blank'
                                        rel='noreferrer'
                                        className={`${classes.actionsButtons} ${classes.magicPen}`}
                                    >
                                        <ContentOptimizerIcon size={14.5} />
                                    </Link>
                                </Tooltip>
                            </>
                        )}
                    </div>
                );
            },
        },
        {
            title: getColumnTitle({
                label: 'OPTIMAL RS',
                sortKey: monitorsSortKeys.optimalRankabilityScore,
                tooltipTitle:
                    'Optimal Rankability Score of the top-ranked competitors on Google ' +
                    'for the monitored keyword. Updated every three months.',
                modalTitle: 'Rankability Score',
            }),
            dataIndex: 'optimalRankabilityScore',
            key: 'optimalRankabilityScore',
            width: '8%',
            render: (_, record) => {
                return (
                    <CompleteBadge
                        size={difficultyBadgeSize.small}
                        progress={record?.optimalRankabilityScore}
                    />
                );
            },
        },
        {
            title: getColumnTitle({
                label: 'PAGE RS',
                sortKey: monitorsSortKeys.rankabilityScore,
                tooltipTitle:
                    'Rankability Score of the monitored page for the monitored keyword. Updated daily.',
                modalTitle: 'Rankability Score',
            }),
            dataIndex: 'rankabilityScore',
            key: 'rankabilityScore',
            width: '8%',
            render: (_, record) => {
                return (
                    <CompleteBadge
                        size={difficultyBadgeSize.small}
                        progress={record?.rankabilityScore}
                    />
                );
            },
        },
        {
            title: getColumnTitle({
                label: 'CHANGE',
                sortKey: monitorsSortKeys.change,
                tooltipTitle:
                    'Measures the Page RS change compared to the previous week. Updated daily.',
            }),
            dataIndex: 'change',
            key: 'change',
            width: '8%',
            render: (_, record) => {
                return (
                    <div className={classes.alignValue}>
                        <div className='mr-4px'>
                            {record?.change > 0 && '+'}
                            {record?.change || '—'}
                        </div>
                        {record?.change > 0 && <IncreaseArrowIcon />}
                        {record?.change < 0 && <DecreaseArrowIcon />}
                    </div>
                );
            },
        },
        {
            title: getColumnTitle({
                label: 'ADDED',
                sortKey: monitorsSortKeys.added,
            }),
            dataIndex: 'added',
            key: 'added',
            width: '12%',
            render: (_, record) => {
                return (
                    <p className={record?.disabled ? classes.disabledText : ''}>{record?.added}</p>
                );
            },
        },
    ];
};
