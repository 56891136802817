import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Typography, Spin } from 'antd';

import {
    selectIsGracePeriod,
    selectIsSubscriptionExpired,
} from '../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { statusCodes } from '../../../../../../constants/statusCodes';
import { paymentsPathes } from '../../../../../../constants/queryPathes';

import { billingPlanIntervals } from '../../BillingConstants';
import { billingPlanTypes, supportMail } from '../../../../../../constants/billingPlanConsts';

import { useOrganizationSlug } from '../../../../../../utils/hooks/useOrganizationSlug';
import { getAxiosHeaders } from '../../../../../../utils/helpers/getAxiosHeaders';
import { axiosAPI } from '../../../../../../utils/axiosAPI';
import { openBasicErrorNotification } from '../../../../../../utils/helpers/openBasicErrorNotification';

import Loader from '../../../../../common/loader/Loader';

import PlanTypesSwitcher from './PlanTypesSwitcher';
import BillingPlanCard from './BillingPlanCard';
import { selectPlanType } from '../../store/billingSettings.selectors';

const { Title, Link } = Typography;

const classes = {
    billingPlansContentWrapper:
        'billing-plans-content-wrapper d-flex flex-column align-items-center',
    billingPlansTitleWrapper: 'billing-plans-title-wrapper d-flex flex-column align-items-center',
    billingSwitchWrapper: 'billing-switch-wrapper',
    billingPlansCardsWrapper: 'billing-plans-cards-wrapper',
    billingFooterText: 'billing-footer-text',
};

const BillingPlansContent = () => {
    const navigate = useNavigate();

    const isSubscriptionExpired = useSelector(selectIsSubscriptionExpired);
    const isGracePeriod = useSelector(selectIsGracePeriod);
    const planType = useSelector(selectPlanType);

    const organizationSlug = useOrganizationSlug();

    const [plansData, setPlansData] = useState([]);
    const [filteredPlans, setFilteredPlans] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        axiosAPI
            .get(paymentsPathes.getProducts, {
                ...getAxiosHeaders(),
            })
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    setPlansData(result.data);
                }
            })
            .catch(() => {
                openBasicErrorNotification();
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [planType]);

    const filterAndSortPlanData = (plansData, planType) => {
        const singleCredit = 1;
        const sortOrder =
            planType === billingPlanTypes.monthly.value
                ? billingPlanIntervals.monthly
                : billingPlanIntervals.yearly;

        setFilteredPlans(
            plansData
                ?.filter(
                    (plan) =>
                        !plan.metadata.custom_solution &&
                        plan.metadata.credit !== singleCredit &&
                        plan.prices.find((x) => x.recurring.interval === sortOrder)
                )
                ?.sort(
                    (a, b) =>
                        a?.prices?.find((x) => x.recurring?.interval === sortOrder)?.unit_amount -
                        b?.prices?.find((x) => x.recurring?.interval === sortOrder)?.unit_amount
                )
        );
    };

    useEffect(() => {
        if (plansData) {
            filterAndSortPlanData(plansData, planType);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planType, plansData]);

    const handlePurchaseCredits = () => {
        navigate(`/${organizationSlug}/account/credits`);
    };

    return (
        <div className={classes.billingPlansContentWrapper}>
            <div className={classes.billingPlansTitleWrapper}>
                <Title level={4}>Plans & Pricing</Title>
                <PlanTypesSwitcher isLoading={isLoading} />
            </div>
            <Spin spinning={isLoading} indicator={<Loader />}>
                <div className={classes.billingPlansCardsWrapper}>
                    {filteredPlans.map((plan) => (
                        <BillingPlanCard key={plan.name} planData={plan} />
                    ))}
                </div>
                <div className={classes.billingFooterText}>
                    {!isSubscriptionExpired && !isGracePeriod && (
                        <Title level={5}>
                            Need more credits?{' '}
                            <Link onClick={handlePurchaseCredits}>Purchase additional credits</Link>
                        </Title>
                    )}
                    <Title level={5}>
                        Need a larger plan?{' '}
                        <Link href={`mailto:${supportMail}`}>
                            Ask us about our SEO Enterprise solution
                        </Link>
                    </Title>
                </div>
            </Spin>
        </div>
    );
};

export default BillingPlansContent;
