import moment from 'moment';

const DEFAULT_GEO_LOCATION_CODE = 0;
const GEO_LOCATION_CODE_SEPARATOR = '_';
const RECENT_DAYS_QUANTITY = 30;

/**
 * Checks for the presence of recent identical optimizers in the provided list.
 *
 * @param {Array} existingOptimizers - The list of existing optimizer objects.
 * @param {Object} keywordSearchData - The search criteria to match optimizers.
 * @param {string} location - A string representing the current geographical location.
 *  - Expected format: A string separated by `GEO_LOCATION_CODE_SEPARATOR` (e.g., "US_123").
 *  - Defaults to `DEFAULT_GEO_LOCATION_CODE` (0) if the separator is absent.
 * @returns {boolean} - Returns `true` if a matching optimizer exists and was created
 * within the last `RECENT_DAYS_QUANTITY` days; otherwise, `false`.
 */
export const checkRecentIdenticalOptimizerPresence = (
    existingOptimizers = [],
    keywordSearchData = {},
    location = ''
) => {
    const parsedLocation = location.includes(GEO_LOCATION_CODE_SEPARATOR)
        ? Number.parseInt(location.split(GEO_LOCATION_CODE_SEPARATOR)[1])
        : DEFAULT_GEO_LOCATION_CODE;

    const identicalOptimizers = existingOptimizers
        .filter(
            (optimizer) =>
                optimizer.content_brief.keywords === keywordSearchData.keywords &&
                optimizer.content_brief.geo_location === parsedLocation &&
                optimizer.content_brief.country_code === keywordSearchData.country_code &&
                optimizer.content_brief.language_code === keywordSearchData.language_code
        )
        .sort((a, b) => moment(b.created_at).diff(moment(a.created_at)));

    return identicalOptimizers.length > 0
        ? moment().diff(moment(identicalOptimizers[0].created_at), 'days') < RECENT_DAYS_QUANTITY
        : false;
};
