export const sidebarActions = {
    OPEN: 'SIDEBAR/OPEN',
    COLLAPSE: 'SIDEBAR/COLLAPSE',
};

export const openSidebar = () => ({
    type: sidebarActions.OPEN,
});

export const collapseSidebar = () => ({
    type: sidebarActions.COLLAPSE,
});
