import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row } from 'antd';

import { selectDarkMode } from '../../../../../../userBrowserSettings/store/browserSettings.selectors';
import { collapseSidebar } from '../../../../../common/sidebar/store/sidebar.actions';
import { selectEditorTableOfContents } from '../../../../../common/tiptapEditor/store/tiptapEditor.selectors';

// TODO: Remove after tiptap editor is done
// import EditorWrapper from './editor/EditorWrapper';

import KeywordsBriefWrapper from './rightBlock/brief/KeywordsBriefWrapper';
import TipTapEditorWrapper from './tiptapEditor/TipTapEditorWrapper';

import './OptimizerTab.scss';

const classes = {
    optimizerTab: 'optimizer-tab',
};

const OptimizerTabContainer = () => {
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const editorTableOfContents = useSelector(selectEditorTableOfContents);

    useEffect(() => {
        if (editorTableOfContents) {
            dispatch(collapseSidebar());
        }
    }, [editorTableOfContents]);

    return (
        <Row
            className={`${classes.optimizerTab} 
                        ${darkMode ? 'dark-mode' : ''}`}
        >
            <Col span={17}>
                {/* TODO: Remove after tiptap editor is done */}
                {/* <EditorWrapper /> */}
                <TipTapEditorWrapper />
            </Col>
            <Col span={7}>
                <KeywordsBriefWrapper />
            </Col>
        </Row>
    );
};

export default OptimizerTabContainer;
