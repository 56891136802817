import { useSelector } from 'react-redux';

import useAutoVersioning from './useAutoVersioning';
import useEditorVersions from './useEditorVersions';

import {
    selectAISpellCheckerVisible,
    selectAIStateLoading,
    selectCurrentEditorContent,
} from '../store/tiptapEditor.selectors';

import { selectContentOptimizerShared } from '../../../pages/contentOptimizer/contentPage/store/contentOptimizerContent.selectors';

const useEditorAutoVersioning = () => {
    const currentEditorContent = useSelector(selectCurrentEditorContent);
    const aiLoading = useSelector(selectAIStateLoading);
    const aiSpellCheckerVisible = useSelector(selectAISpellCheckerVisible);
    const isOptimizerShared = useSelector(selectContentOptimizerShared);

    const [createNewEditorVersion, fetchEditorVersions] = useEditorVersions();

    useAutoVersioning({
        content: currentEditorContent,
        isBlocked: aiLoading || aiSpellCheckerVisible || isOptimizerShared,
        versionHandlers: {
            create: createNewEditorVersion,
            fetch: fetchEditorVersions,
        },
    });
};

export default useEditorAutoVersioning;
