import React from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Layout } from 'antd';

import { elementsStyles } from '../../../resources/styles/elementsStyles';

import { useCurrentSubscription } from '../../../utils/hooks/useCurrentSubscription';

import Sidebar from '../sidebar/Sidebar';

import { selectSidebarOpeningState } from '../sidebar/store/sidebar.selectors';
import { collapseSidebar, openSidebar } from '../sidebar/store/sidebar.actions';

const classes = {
    layoutWrapper: 'min-h-100 layout-wrapper',
};

const ProtectedLayout = () => {
    const dispatch = useDispatch();

    const collapsed = useSelector(selectSidebarOpeningState);

    // eslint-disable-next-line
    const _currentSubscription = useCurrentSubscription();

    const handleCollapse = () => {
        if (collapsed) {
            dispatch(openSidebar());
        } else {
            dispatch(collapseSidebar());
        }
    };

    return (
        <Layout className={classes.layoutWrapper}>
            <Sidebar collapsed={collapsed} handleCollapse={handleCollapse} />
            <Layout
                className='site-layout'
                style={{
                    left: collapsed
                        ? `${elementsStyles.Sider.collapsedWidth}px`
                        : `${elementsStyles.Sider.width}px`,
                    width: collapsed
                        ? `calc(100% - ${elementsStyles.Sider.collapsedWidth}px)`
                        : `calc(100% - ${elementsStyles.Sider.width}px)`,
                }}
            >
                <Outlet />
            </Layout>
        </Layout>
    );
};

export default ProtectedLayout;
