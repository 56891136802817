import React from 'react';
import { useSelector } from 'react-redux';

import { Tooltip, Typography } from 'antd';

import ReactCountryFlag from 'react-country-flag';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import {
    formatLocation,
    isMatchingCountryCode,
} from '../../../utils/helpers/optimizerLocationNameHelper';
import { getArrayOptionsFromObject } from '../../../utils/helpers/getArrayOptionsFromObject';

import { countries, featuredCountries } from '../../../constants/countries';
import { geolocationConstants } from '../../../constants/geolocationConsts';
import { featuredLanguages, languages } from '../../../constants/languages';

import './FlagCountrySection.scss';

const classes = {
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
    flagWrapper: 'flag-wrapper',
};

const FlagCountrySection = ({ countryCode, languageCode, concreteLocation }) => {
    const darkMode = useSelector(selectDarkMode);
    const location = formatLocation(countryCode, concreteLocation, countries, featuredCountries);

    return (
        <div className='d-flex'>
            <Tooltip
                title={`${location} · ${
                    getArrayOptionsFromObject({
                        values: languages,
                        featured: featuredLanguages,
                    })?.find((language) => isMatchingCountryCode(language, languageCode))?.label ||
                    geolocationConstants.NOT_AVAILABLE_VALUE
                }`}
                overlayClassName={`${classes.tooltipOverlay}
                                   ${darkMode ? classes.tooltipOverlayDark : ''}`}
                className={classes.flagWrapper}
            >
                <ReactCountryFlag
                    countryCode={countryCode}
                    svg
                    style={{
                        width: '16px',
                        height: '16px',
                    }}
                />

                <Typography>
                    {concreteLocation ? `${concreteLocation['Name']} · ` : ''}
                    {languageCode?.toUpperCase()}
                </Typography>
            </Tooltip>
        </div>
    );
};

export default FlagCountrySection;
