import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { contentBriefPathes } from '../../../../../../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../../../../../../constants/statusCodes';

import { axiosAPI } from '../../../../../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../../../../../utils/helpers/getAxiosHeaders';
import { openBasicErrorNotification } from '../../../../../../../../../../utils/helpers/openBasicErrorNotification';

import { setBriefVersions, setCurrentBriefId } from '../../store/contentBriefsOutline.actions';
import { selectCurrentBrief } from '../../store/contentBriefsOutline.selectors';

const useBriefVersions = () => {
    const dispatch = useDispatch();

    const { contentOptimizerId } = useParams();

    const [isNewVersionsCreating, setIsNewVersionCreating] = useState(false);

    const currentBrief = useSelector(selectCurrentBrief);

    const createNewBriefVersion = () => {
        if (contentOptimizerId) {
            setIsNewVersionCreating(true);

            axiosAPI
                .post(
                    `${contentBriefPathes.getListOfContentBriefs}/${contentOptimizerId}/versions`,
                    {
                        extras: currentBrief?.extras,
                    },
                    {
                        ...getAxiosHeaders(),
                    }
                )
                .then(() => {
                    fetchBriefVersions();
                })
                .catch(() => {
                    setIsNewVersionCreating(false);
                    openBasicErrorNotification();
                });
        }
    };

    const fetchBriefVersions = () => {
        if (contentOptimizerId) {
            axiosAPI
                .get(
                    `${contentBriefPathes.getListOfContentBriefs}/${contentOptimizerId}/versions`,
                    {
                        ...getAxiosHeaders(),
                    }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        if (result?.data?.length !== 0) {
                            dispatch(setBriefVersions(result?.data));
                        } else {
                            createNewBriefVersion();
                        }

                        dispatch(setCurrentBriefId(Number.parseInt(contentOptimizerId)));
                    }
                })
                .catch(() => {
                    openBasicErrorNotification();
                })
                .finally(() => {
                    setIsNewVersionCreating(false);
                });
        }
    };

    return {
        contentOptimizerId,
        isNewVersionsCreating,
        createNewBriefVersion,
        fetchBriefVersions,
    };
};

export default useBriefVersions;
