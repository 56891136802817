import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Spin, Typography } from 'antd';
import moment from 'moment';

import {
    selectDarkMode,
    selectUserOrganization,
} from '../../../../../userBrowserSettings/store/browserSettings.selectors';
import { setCreditsPurchaseType } from '../store/billingSettings.actions';

import { useOrganizationSlug } from '../../../../../utils/hooks/useOrganizationSlug';
import { usePaymentMethod } from '../../../../../utils/hooks/usePaymentMethod';
import { useSubscriptionLimits } from '../../../../../utils/hooks/useSubscriptionLimits';

import SubscriptionHelper from '../../../auth/helpers/subscription.helper';

import {
    selectCurrentSubscription,
    selectCurrentSubscriptionCancelAtPeriodEnd,
    selectIsCurrentSubscriptionLoading,
    selectOpenAIBriefsAddedSource,
    selectOptimizersAddedSource,
} from '../store/billingSettings.selectors';

import { creditType } from '../../../../../constants/creditsConsts';

import CreditDetailsTable from './CreditDetailsTable';
import Loader from '../../../../common/loader/Loader';

const { Title, Link } = Typography;

const classes = {
    reportCreditsWrapper: 'report-credits-wrapper',
    reportCreditsSectiontitle: 'section-title',
    creditDetailsWrapper: 'credit-details-wrapper d-flex align-items-center',
    textAccent: 'text-accent',
    link: 'link-hover-highlight',
};

const ReportCreditsSection = ({ id, creditTypeParam }) => {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { paymentMethod } = usePaymentMethod();
    const { remainingOptimizers, remainingOpenAIBriefs, totalOptimizers, totalOpenAIBriefs } =
        useSubscriptionLimits();

    const organizationSlug = useOrganizationSlug();
    const organization = useSelector(selectUserOrganization);

    const darkMode = useSelector(selectDarkMode);
    const currentSubscription = useSelector(selectCurrentSubscription);
    const currentSubscriptionCanceled = useSelector(selectCurrentSubscriptionCancelAtPeriodEnd);
    const isSubscriptionLoading = useSelector(selectIsCurrentSubscriptionLoading);

    const optimizersAddedSource = useSelector(selectOptimizersAddedSource);
    const aiAddedSource = useSelector(selectOpenAIBriefsAddedSource);

    const addedSourceRolloverReason = 'rollover';

    const rolloverOptimizersTotal = useMemo(
        () =>
            optimizersAddedSource
                ?.filter((x) => x?.reason === addedSourceRolloverReason)
                ?.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0),
        [optimizersAddedSource]
    );

    const rolloverAITotal = useMemo(
        () =>
            aiAddedSource
                ?.filter((x) => x?.reason === addedSourceRolloverReason)
                ?.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0),
        [aiAddedSource]
    );

    const isAICreditTypeParam = creditTypeParam === creditType.AI;

    const rolloverTotal = isAICreditTypeParam ? rolloverAITotal : rolloverOptimizersTotal;

    const [showCreditDetails, setShowCreditDetails] = useState(false);

    const toggleCreditDetails = () => {
        setShowCreditDetails(!showCreditDetails);
    };

    const handlePurchaseCredits = () => {
        dispatch(setCreditsPurchaseType(creditTypeParam));
        navigate(`/${organizationSlug}/account/credits`);
    };

    const correctCreditsNaming = isAICreditTypeParam ? 'AI' : 'Optimizer';

    return (
        <section id={id} className={classes.reportCreditsWrapper}>
            <Title level={4} className={classes.reportCreditsSectiontitle}>
                {correctCreditsNaming} Credits
            </Title>
            <Spin spinning={isSubscriptionLoading} indicator={<Loader />}>
                <Typography>
                    <span className={classes.textAccent}>
                        {isAICreditTypeParam ? remainingOpenAIBriefs : remainingOptimizers}
                    </span>{' '}
                    of{' '}
                    <span className={classes.textAccent}>
                        {isAICreditTypeParam ? totalOpenAIBriefs : totalOptimizers}
                    </span>{' '}
                    {correctCreditsNaming} credits remaining
                    {rolloverTotal > 0 && (
                        <span>
                            {' '}
                            (includes <b>{rolloverTotal} rollover credits</b> from last month)
                        </span>
                    )}
                    {'. '}
                    Credits will {currentSubscriptionCanceled ? 'expire' : 'rollover'} on{' '}
                    <span className={classes.textAccent}>
                        {currentSubscription
                            ? moment(currentSubscription?.current_period_end).format('LL')
                            : ''}
                    </span>{' '}
                    at{' '}
                    <span className={classes.textAccent}>
                        {currentSubscription
                            ? moment(currentSubscription?.current_period_end).format('LT')
                            : ''}
                        .
                    </span>
                </Typography>
                <Typography className={classes.creditDetailsWrapper}>
                    <span className={classes.textAccent}>Сredit details</span>
                    {!showCreditDetails && paymentMethod && (
                        <Link
                            underline
                            className={`${classes.link}
                        ${darkMode ? 'dark-mode' : ''}`}
                            onClick={toggleCreditDetails}
                        >
                            View
                        </Link>
                    )}
                </Typography>
                {showCreditDetails && (
                    <CreditDetailsTable
                        toggleCreditDetails={toggleCreditDetails}
                        creditTypeParam={creditTypeParam}
                    />
                )}
                {!isSubscriptionLoading &&
                    !SubscriptionHelper.isTrial(currentSubscription, organization) && (
                        <Link
                            underline
                            className={`${classes.link}
                    ${darkMode ? 'dark-mode' : ''}`}
                            onClick={handlePurchaseCredits}
                        >
                            Purchase credits
                        </Link>
                    )}
            </Spin>
        </section>
    );
};

export default ReportCreditsSection;
