import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Select, Spin, Typography } from 'antd';
import debounce from 'lodash/debounce';
import { useSelector } from 'react-redux';

import SiderChevronDownIcon from '../../../resources/icons/SiderChevronDown';
import SiderChevronUpIcon from '../../../resources/icons/SiderChevronUp';
import { colorPalette } from '../../../resources/styles/colorPalette';

import geoLocationSelectHelper from '../../../utils/helpers/geoLocationSelectHelper';
import { sanitizeSearchValue } from '../../../utils/helpers/sanitizeSearchValue';
import { useIconColor } from '../../../utils/hooks/useIconColor';
import { useOpen } from '../../../utils/hooks/useOpen';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import './DebounceSelect.scss';

const classes = {
    debounceSelect: 'debounce-select',
    debounceSelectDark: 'debounce-select-dark',
};

const DebounceSelect = ({ fetchOptions, debounceTimeout = 400, ...props }) => {
    const featuredOptions = props.initialOptions
        .filter((option) => option.featuredPosition !== -1)
        .sort((a, b) => a.featuredPosition - b.featuredPosition);
    const nonFeaturedOptions = props.initialOptions.filter(
        (option) => option.featuredPosition === -1
    );
    const initialOptions = nonFeaturedOptions.length
        ? [...featuredOptions, ...nonFeaturedOptions]
        : featuredOptions;
    const [options, setOptions] = useState(initialOptions);
    const [fetching, setFetching] = useState(false);
    const [prevValue, setPrevValue] = useState(props.defaultValue);

    const fetchRef = useRef(0);
    const selectRef = useRef(null);

    const darkMode = useSelector(selectDarkMode);
    const { isOpen, handleOpen } = useOpen();
    const iconColor = useIconColor();

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            const normalizedValue = sanitizeSearchValue(value);
            fetchRef.current += 1;
            const fetchId = fetchRef.current;

            setFetching(true);
            fetchOptions(normalizedValue).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                const list = [
                    // we only append items to avoid cases when selected value is not in options list
                    ...options,
                    ...newOptions.filter(
                        (x) => !options.some((s) => s.value.toLowerCase() === x.value.toLowerCase())
                    ),
                ].sort((a, b) => {
                    // Here we sort items by most relevant to search term

                    // Since we use 2 sources for dropdown we need to distinguish items
                    // from initalOptions and geolocations from csv from BE (item from BE has 'Canonical Name')
                    const nameA = sanitizeSearchValue(
                        a.hasOwnProperty('Canonical Name') ? a['Canonical Name'] : a.label
                    );
                    const nameB = sanitizeSearchValue(
                        b.hasOwnProperty('Canonical Name') ? b['Canonical Name'] : b.label
                    );
                    const searchLower = normalizedValue;

                    const aIncludes = nameA.startsWith(searchLower);
                    const bIncludes = nameB.startsWith(searchLower);

                    // Sort by relevance (presence of search term first)
                    if (aIncludes && !bIncludes) return -1;
                    if (!aIncludes && bIncludes) return 1;

                    // If both or neither includes, sort alphabetically
                    return nameA.localeCompare(nameB);
                });
                setOptions(list);
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);

    useEffect(() => {
        if (!props.value && prevValue && !isOpen) {
            const { country_code, geo_location } = geoLocationSelectHelper.getGeoData({
                value: prevValue,
            });

            // we set value in this format cause for example for special city in Canada using
            // just country_code as value will select just country, but we need to be mre specific: country_code + city ID
            const newValue = geo_location ? `${country_code}_${geo_location}` : country_code;

            props.setValue(newValue);
        }

        // eslint-disable-next-line
    }, [props.value, isOpen]);

    const handleDropdownVisibleChange = (isVisible) => {
        isVisible && setPrevValue(props.value);

        // NOTE: Optimizer locations > keep the default country when dropdown is not engaged and change text after dropdown is clicked
        handleOpen(isVisible);

        setTimeout(() => {
            if (isVisible && props.value) {
                props.setValue(null);
                selectRef.current.focus();
            }
        });
    };

    const handleDropdownOptionChange = (value) => {
        props.onChange(value);
        selectRef.current.blur();
    };

    return (
        <Select
            {...props}
            ref={selectRef}
            style={{
                '.antSelectItem.antSelectItemGroup': { height: '100px' },
            }}
            suffixIcon={
                isOpen ? (
                    <SiderChevronUpIcon color={colorPalette.colorPrimary} />
                ) : (
                    <SiderChevronDownIcon color={iconColor} />
                )
            }
            labelInValue
            filterOption={(input, option) => {
                return sanitizeSearchValue(option?.label).includes(sanitizeSearchValue(input));
            }}
            onSearch={debounceFetcher}
            notFoundContent={
                fetching ? (
                    <Spin size='small' />
                ) : (
                    <Typography.Text className='empty-list-text'>No data</Typography.Text>
                )
            }
            options={options}
            popupClassName={`${classes.debounceSelect} ${
                darkMode ? `${classes.debounceSelectDark}` : ''
            }`}
            open={isOpen}
            onDropdownVisibleChange={handleDropdownVisibleChange}
            onClear={() => setOptions([...props.initialOptions])}
            onChange={handleDropdownOptionChange}
        />
    );
};

export default DebounceSelect;
