import React from 'react';
import { useSelector } from 'react-redux';

import { Table, Typography } from 'antd';
import moment from 'moment';

import {
    selectOpenAIBriefsAddedSource,
    selectOptimizersAddedSource,
} from '../store/billingSettings.selectors';
import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import { sortOrders } from '../../../../../constants/sortOrders';
import { creditType } from '../../../../../constants/creditsConsts';

import TableHeaderSortIcons from '../../../../common/tables/TableHeaderSortIcons';

const { Link } = Typography;

const classes = {
    creditDetailsTable: 'credit-details-table',
    creditDetailsTableColumnHeader: 'credit-details-table-column-header',
    link: 'link-hover-highlight',
};

const sorterColumn = (key, titleProps, darkMode = false) => {
    const sortedColumn = titleProps.sortColumns?.find(({ column }) => column.key === key);

    return (
        <div className={`${classes.creditDetailsTableColumnHeader} ${darkMode ? 'dark-mode' : ''}`}>
            {key}
            <TableHeaderSortIcons
                order={sortedColumn?.order === 'ascend' ? sortOrders.asc : sortOrders.desc}
                active={sortedColumn?.order}
            ></TableHeaderSortIcons>
        </div>
    );
};

const CreditDetailsTable = ({ toggleCreditDetails, creditTypeParam }) => {
    const creditsSource = useSelector(
        creditTypeParam === creditType.OPTIMIZER
            ? selectOptimizersAddedSource
            : selectOpenAIBriefsAddedSource
    );

    const darkMode = useSelector(selectDarkMode);

    const columns = [
        {
            title: (titleProps) => sorterColumn('amount', titleProps, darkMode),
            dataIndex: 'amount',
            width: '25%',
            sorter: (a, b) => a?.amount - b?.amount,
            key: 'amount',
            render: (_, record) => {
                return record.key !== 'close' ? (
                    `${record.amount} credits`
                ) : (
                    <Link underline onClick={toggleCreditDetails} className={classes.link}>
                        Close
                    </Link>
                );
            },
        },
        {
            title: (titleProps) => sorterColumn('type', titleProps, darkMode),
            dataIndex: 'reason',
            width: '25%',
            key: 'reason',
            sorter: (a, b) => a?.reason?.localeCompare(b?.reason),
            render: (_, record) => {
                return record.key !== 'close'
                    ? record?.reason === 'Purchase'
                        ? 'Additional purchase'
                        : record?.reason
                    : null;
            },
        },
        {
            title: (titleProps) => sorterColumn('created', titleProps, darkMode),
            dataIndex: 'created',
            width: '25%',
            key: 'created',
            sorter: (a, b) => a?.timestamp?.localeCompare(b?.timestamp),
            render: (_, record) => {
                return record.key !== 'close'
                    ? moment.utc(record?.timestamp).local().format('MMM DD, YYYY, h:mm A')
                    : null;
            },
        },
        {
            title: (titleProps) => sorterColumn('expires', titleProps, darkMode),
            dataIndex: 'expires',
            key: 'expires',
            sorter: (a, b) => a?.expires?.localeCompare(b?.expires),
            render: (_, record) => {
                return record.key !== 'close'
                    ? moment.utc(record?.expires).local().format('MMM DD, YYYY, h:mm A')
                    : null;
            },
        },
    ];

    const data = [
        ...creditsSource
            ?.filter((x) => x?.amount > 0)
            ?.map((el) => {
                return {
                    ...el,
                    reason: el?.reason[0]?.toUpperCase() + el?.reason?.slice(1) || el?.reason,
                    key: el.timestamp,
                };
            })
            ?.reverse(),
        {
            key: 'close',
        },
    ];

    return (
        <Table
            className={classes.creditDetailsTable}
            columns={columns}
            showSorterTooltip={false}
            rowKey={'key'}
            dataSource={data}
            bordered
            pagination={false}
        />
    );
};

export default CreditDetailsTable;
