import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Form, Row } from 'antd';

import {
    countries,
    defaultCountry,
    featuredCountries,
} from '../../../../../../constants/countries';
import {
    defaultLanguage,
    featuredLanguages,
    languages,
} from '../../../../../../constants/languages';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../../constants/notificationType';
import { contentMonitorPathes } from '../../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../../constants/statusCodes';
import { subscriptionLimits } from '../../../../../../constants/subscriptionLimits';

import { axiosAPI } from '../../../../../../utils/axiosAPI';
import geoLocationSelectHelper from '../../../../../../utils/helpers/geoLocationSelectHelper';
import { getArrayOptionsFromObject } from '../../../../../../utils/helpers/getArrayOptionsFromObject';
import { getAxiosHeaders } from '../../../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../../../utils/helpers/openNotification';
import {
    getKeywordLengthErrorMessage,
    getKeywordUrlErrorMessage,
    isKeywordLengthValid,
    normalizeKeyword,
} from '../../../../../../utils/helpers/keywordHelpers';
import { isUrlValid, removeUrlExtraSpaces } from '../../../../../../utils/helpers/urlHelpers';
import { useOpen } from '../../../../../../utils/hooks/useOpen';

import ContentOptimizerService from '../../../../../../services/contentOptimizer.service';

import NoCreditsModal from '../../../../../common/createNew/NoCreditsModal';
import RunOptimizerButton from '../../../../../common/createNew/RunOptimizerButton';
import MultipleCreateArea from '../../../../../common/inputs/MultipleCreateArea';
import DebounceSelect from '../../../../../common/selects/DebounceSelect';
import KeywordSelect from '../../../../../common/selects/KeywordSelect';

import { increaseSubscriptionLimits } from '../../../../account/billing/store/billingSettings.actions';
import {
    selectContentMonitorsLimits,
    selectContentMonitorsUsed,
} from '../../../../account/billing/store/billingSettings.selectors';
import { addContentMonitorData } from '../../store/contentMonitorMain.actions';

import './AddURLs.scss';
import {
    selectUserGoogleSearchEnginePreference,
    selectUserLanguagePreference,
} from '../../../../../../userBrowserSettings/store/browserSettings.selectors';

const classes = {
    createAreaWrapper: 'create-area-wrapper create-multiple-section',
    optionsWrapper: 'options-wrapper keyword-header-wrapper w-100 d-flex justify-content-between',
    keywordLocationSelect: 'keyword-location-select',
    keywordLanguageSelect: 'keyword-language-select',
    runBriefBtn: 'run-brief-btn',
};

const getNotEmptyArrayLength = (keywordString) => {
    return keywordString?.split('\n')?.filter((item) => item.trim()).length || 0;
};

const AddURLsSection = () => {
    const dispatch = useDispatch();

    const [form] = Form.useForm();

    const monitorsLimit = useSelector(selectContentMonitorsLimits);
    const monitorsUsed = useSelector(selectContentMonitorsUsed);
    const userLanguagePreference = useSelector(selectUserLanguagePreference) || defaultLanguage;
    const userSearchEnginePreference =
        useSelector(selectUserGoogleSearchEnginePreference) || defaultCountry;
    const [monitorURLsData, setMonitorURLsData] = useState({
        country_code: userSearchEnginePreference,
        language_code: userLanguagePreference,
        keywords: '',
        urls: '',
    });

    const [location, setLocation] = useState(userSearchEnginePreference);
    const [isLoading, setIsLoading] = useState(false);
    const [keywordsArray, setKeywordsArray] = useState([]);
    const [urlsArray, setUrlsArray] = useState([]);
    const [isKeywordsInputError, setKeywordsIsInputError] = useState(false);
    const [isUrlInputError, setUrlIsInputError] = useState(false);

    const { isOpen, handleOpen } = useOpen();

    useEffect(() => {
        if (monitorURLsData.keywords?.includes(',')) {
            form.setFields([
                {
                    name: 'keywords',
                    errors: ['Please, use new line for each keyword instead of comma'],
                },
            ]);

            setKeywordsIsInputError(true);
        } else if (
            getNotEmptyArrayLength(monitorURLsData.keywords) > monitorsLimit - monitorsUsed &&
            monitorsLimit - monitorsUsed !== 0
        ) {
            form.setFields([
                {
                    name: 'keywords',
                    errors: ['You have reached the limit of credits'],
                },
            ]);

            setKeywordsIsInputError(true);
        } else if (monitorURLsData.keywords?.split('\n').length > 100) {
            form.setFields([
                {
                    name: 'keywords',
                    errors: ['You can add up to 100 keywords'],
                },
            ]);

            setKeywordsIsInputError(true);
        } else {
            form.setFields([
                {
                    name: 'keywords',
                    errors: [],
                },
            ]);

            setKeywordsIsInputError(false);
        }

        // eslint-disable-next-line
    }, [monitorURLsData]);

    useEffect(() => {
        if (monitorURLsData.keywords) {
            setKeywordsArray(monitorURLsData.keywords.split('\n'));
        }

        if (monitorURLsData.urls) {
            setUrlsArray(monitorURLsData.urls.split('\n'));
        }
    }, [monitorURLsData]);

    const handleChangeKeywords = (value) => {
        setMonitorURLsData((prev) => ({
            ...prev,
            keywords: value,
        }));

        setKeywordsIsInputError(false);
        setUrlIsInputError(false);
        form.setFields([
            { name: 'keywords', errors: [] },
            { name: 'urls', errors: [] },
        ]);
    };

    const handleChangeUrls = (value) => {
        setMonitorURLsData((prev) => ({
            ...prev,
            urls: value,
        }));

        setKeywordsIsInputError(false);
        setUrlIsInputError(false);
        form.setFields([
            { name: 'keywords', errors: [] },
            { name: 'urls', errors: [] },
        ]);
    };

    const handleChangeLanguage = (value) => {
        setMonitorURLsData((prev) => ({
            ...prev,
            language_code: value,
        }));
    };

    const handleChangeLocation = (value) => {
        const { country_code, geo_location } = geoLocationSelectHelper.getGeoData(value);

        setMonitorURLsData((prev) => ({
            ...prev,
            country_code,
            geo_location,
        }));

        setLocation(geo_location ? `${country_code}_${geo_location}` : country_code);
    };

    const validateRequestKeywords = (keywordListString = '') => {
        let keywordFieldIsCorrect = true;

        const keywordList = keywordListString.split('\n').filter((item) => item.trim() !== '');

        if (!keywordList.length) {
            setKeywordsIsInputError(true);
            return false;
        }

        keywordList.forEach((keyword) => {
            if (!isKeywordLengthValid(keyword)) {
                setKeywordsIsInputError(true);
                form.setFields([
                    {
                        name: 'keywords',
                        errors: [getKeywordLengthErrorMessage()],
                    },
                ]);
                keywordFieldIsCorrect = false;
            }
        });

        return keywordFieldIsCorrect;
    };

    const validateFormData = (formItem, urlsField = '', keywordsField = '') => {
        if (formItem.getFieldsError().some((item) => item.errors.length > 0)) {
            setKeywordsIsInputError(true);
            setUrlIsInputError(true);

            return false;
        }

        const keywordItemsLength = getNotEmptyArrayLength(keywordsField);
        const urlItemsLength = getNotEmptyArrayLength(urlsField);

        if (!keywordsField || !keywordItemsLength) {
            setKeywordsIsInputError(true);
            return false;
        }

        if (!urlsField || !urlItemsLength) {
            setUrlIsInputError(true);
            return false;
        }

        if (urlItemsLength !== keywordItemsLength) {
            formItem.setFields([
                {
                    name: 'keywords',
                    errors: ['Please add the same number of keywords and URLs'],
                },
            ]);

            setKeywordsIsInputError(true);
            setUrlIsInputError(true);

            return false;
        }

        if (
            !urlsField
                ?.split('\n')
                .map(removeUrlExtraSpaces)
                .every((link) => isUrlValid(link) || link.trim() === '')
        ) {
            formItem.setFields([
                {
                    name: 'urls',
                    errors: [getKeywordUrlErrorMessage()],
                },
            ]);

            setKeywordsIsInputError(true);
            setUrlIsInputError(true);

            return false;
        }

        return true;
    };

    const handleRunMonitorsURLs = () => {
        if (monitorsUsed >= monitorsLimit || !monitorsLimit) {
            handleOpen();
        } else if (
            validateFormData(form, monitorURLsData.urls, monitorURLsData.keywords) &&
            validateRequestKeywords(monitorURLsData.keywords)
        ) {
            setIsLoading(true);

            const monitorURLsModel = {
                country_code: monitorURLsData.country_code,
                language_code: monitorURLsData.language_code,
                geo_location: monitorURLsData.geo_location,
                monitored_keyword: monitorURLsData.keywords
                    .split('\n')
                    .map(normalizeKeyword)
                    .filter((item) => item !== '')
                    .join('\n'),
                url: monitorURLsData.urls,
            };

            axiosAPI
                .post(contentMonitorPathes.baseContentMonitorUrl, monitorURLsModel, {
                    ...getAxiosHeaders(),
                })
                .then((result) => {
                    if (result?.status === statusCodes.create) {
                        dispatch(addContentMonitorData(result?.data));

                        dispatch(
                            increaseSubscriptionLimits({
                                limit: subscriptionLimits.contentMonitorsUsed,
                                value: result?.data?.length,
                            })
                        );

                        setMonitorURLsData((prev) => ({
                            ...prev,
                            keywords: '',
                            urls: '',
                        }));
                        setKeywordsArray([]);
                        setUrlsArray([]);
                        form.resetFields();
                    }
                })
                .catch(({ response }) => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        response?.data?.message || errorNotificationMessage
                    );
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    return (
        <>
            <Form
                form={form}
                layout='vertical'
                className={classes.createAreaWrapper}
                initialValues={monitorURLsData}
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label='Keywords' name='keywords'>
                            <MultipleCreateArea
                                isInputError={isKeywordsInputError}
                                placeholder='Enter keyword or phrase you’d like to monitor on a URL — one keyword per line, maximum 100 (e.g. best running shoes)…'
                                handleChange={handleChangeKeywords}
                                value={monitorURLsData.keywords}
                                keywordArray={keywordsArray}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='URLs' name='urls'>
                            <MultipleCreateArea
                                isInputError={isUrlInputError}
                                placeholder='Enter target URL you’d like to monitor for each keyword — one URL per line, maximum 100 (e.g. https://www.domain.com/best-running-shoes)…'
                                handleChange={handleChangeUrls}
                                value={monitorURLsData.urls}
                                keywordArray={urlsArray}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <div className={classes.optionsWrapper}>
                    <div className='d-flex'>
                        <div className={classes.keywordLocationSelect}>
                            <DebounceSelect
                                placeholder='Enter city, state/province, or country'
                                onChange={handleChangeLocation}
                                showSearch={true}
                                value={location}
                                setValue={setLocation}
                                defaultValue={monitorURLsData.country_code}
                                fetchOptions={(value) =>
                                    ContentOptimizerService.getGeoTargets(value)
                                }
                                initialOptions={getArrayOptionsFromObject({
                                    values: countries,
                                    featured: featuredCountries,
                                })}
                            />
                        </div>
                        <div className={classes.keywordLanguageSelect}>
                            <KeywordSelect
                                placeholder='Select Language'
                                options={getArrayOptionsFromObject({
                                    values: languages,
                                    featured: featuredLanguages,
                                })}
                                defaultValue={monitorURLsData.language_code}
                                onChange={handleChangeLanguage}
                            />
                        </div>
                        <Form.Item shouldUpdate className={classes.runBriefBtn}>
                            {() => (
                                <RunOptimizerButton
                                    isLoading={isLoading}
                                    runBtnText={'Add URLs'}
                                    handleRunBrief={handleRunMonitorsURLs}
                                    isDisabled={isKeywordsInputError || isUrlInputError}
                                />
                            )}
                        </Form.Item>
                    </div>
                </div>
            </Form>
            <NoCreditsModal isOpen={isOpen} handleOpen={handleOpen} />
        </>
    );
};

export default AddURLsSection;
