import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useBriefVersions from './hooks/useBriefVersions';
import useBriefAutoVersioning from './hooks/useBriefAutoVersioning';

import { selectDarkMode } from '../../../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import BriefForm from './BriefForm';
import BriefVersionHistoryContainer from './BriefVersionHistoryContainer';

import './BriefSummary.scss';

const classes = {
    content: 'container content-briefs-keywords-container',
    contentDark: 'container-dark content-briefs-keywords-container-dark',

    headerWrapper: 'content-briefs-keywords-header-wrapper',
    progressWrapper: 'progress-wrapper',
    switcherWrapper: 'switcher-wrapper w-100',
    sectionWrapper: 'section-wrapper',
    rightMenuWrapper: 'right-menu-wrapper section-wrapper',
};

const BriefSummaryContainer = () => {
    const darkMode = useSelector(selectDarkMode);

    const { contentOptimizerId, isNewVersionsCreating, createNewBriefVersion, fetchBriefVersions } =
        useBriefVersions();

    useBriefAutoVersioning();

    useEffect(() => {
        fetchBriefVersions();

        // eslint-disable-next-line
    }, [contentOptimizerId]);

    return (
        <>
            <BriefVersionHistoryContainer
                createNewBriefVersion={createNewBriefVersion}
                fetchBriefVersions={fetchBriefVersions}
                newVersionCreating={isNewVersionsCreating}
            />
            <div className={`${classes.content} ${darkMode ? classes.contentDark : ''}`}>
                <div className={classes.sectionWrapper}>
                    <BriefForm createNewBriefVersion={createNewBriefVersion} />
                </div>
            </div>
        </>
    );
};

export default BriefSummaryContainer;
