import React, { useContext, useEffect, useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Button, Empty, Form, Modal, Tooltip, Typography } from 'antd';

import {
    MAX_CUSTOM_KEYWORD_LENGTH,
    MIN_KEYWORD_LENGTH,
} from '../../../../../../../../constants/constants';

import { selectDarkMode } from '../../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import ErrorMessage from '../../../../../../../common/errorMessage/ErrorMessage';
import MultipleCreateArea from '../../../../../../../common/inputs/MultipleCreateArea';

import CloseIcon from '../../../../../../../../resources/icons/CloseIcon';
import PlusIcon from '../../../../../../../../resources/icons/Plus';
import { colorPalette } from '../../../../../../../../resources/styles/colorPalette';

import { ContentOptimizerContext } from '../../../../../../../hocs/ContentOptimizerProvider';

import { axiosAPI } from '../../../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../../../utils/helpers/getAxiosHeaders';
import { normalizeKeyword } from '../../../../../../../../utils/helpers/keywordHelpers';
import {
    getThemeColorClass,
    getTooltipOverlayClass,
} from '../../../../../../../../utils/helpers/themeHelpers';
import { useDynamicPaddingRef } from '../../../../../../../../utils/hooks/useDynamicPaddingRef';
import { useOpen } from '../../../../../../../../utils/hooks/useOpen';
import { useOrganizationSlug } from '../../../../../../../../utils/hooks/useOrganizationSlug';
import { getOptimizerRightBlockWrapper } from '../optimizerRightBlockHelpers';

import { RIGHT_SINGLE_QUOTATION_MARK } from '../../../../../../../../constants/constants';
import { contentOptimizerKeywordSource } from '../../../../../../../../constants/contentOptimizerKeywordSource';
import { contentOptimizerPathes } from '../../../../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../../../../constants/statusCodes';

import { updateEditorCurrentVersionScore } from '../../../../../../../common/tiptapEditor/store/tiptapEditor.actions';
import { setContentOptimizerKeywords } from '../../../../store/contentOptimizerContent.actions';
import {
    selectContentOptimizerKeywords,
    selectContentOptimizerSuggestions,
} from '../../../../store/contentOptimizerContent.selectors';

const { Title } = Typography;

const classes = {
    addCustomKeywordBtn: 'optimizer-keywords-icon-btn',
    modalWrapper: 'modal-wrapper add-terms-modal',
    modalWrapperDark: 'modal-wrapper-dark add-terms-modal-dark dark-input-autofill',
    modalFormFieldsWrapper: 'form-fields-wrapper scroll-animated',
    modalFormButtonsWrapper: 'form-buttons-wrapper d-flex justify-content-end',
    modalSuggestionsWrapper: 'suggestions-wrapper',
    tooltipOverlay: 'optimizer-keywords-tooltip-overlay',
    addKeywordButton: 'add-keyword-button',
};

const MAX_FORM_HEIGHT = 440;

const AddCustomKeywordModal = () => {
    const formWrapperRef = useDynamicPaddingRef(MAX_FORM_HEIGHT);

    const [form] = Form.useForm();
    const contentOptimizerId = useContext(ContentOptimizerContext);
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const suggestions = useSelector(selectContentOptimizerSuggestions);
    const optimizerKeywords = useSelector(selectContentOptimizerKeywords);

    const [keywords, setKeywords] = useState('');
    const [keywordsArray, setKeywordsArray] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [randomSuggestions, setRandomSuggestions] = useState([]);
    const [isInputError, setIsInputError] = useState(false);

    const { isOpen, handleOpen } = useOpen();
    const organizationSlug = useOrganizationSlug();

    const closeModalIconColor = darkMode
        ? colorPalette.textColor.dark
        : colorPalette.textColor.light;

    useEffect(() => {
        if (suggestions?.length > 0) {
            const filteredRandomSuggestions = suggestions
                ?.filter((suggestion) => suggestion?.keyword?.length < 40)
                ?.sort(() => Math.random() - 0.5)
                ?.slice(0, 18)
                ?.map((suggestion) => suggestion?.keyword);

            setRandomSuggestions(filteredRandomSuggestions);
        }
    }, [isOpen, suggestions]);

    useEffect(() => {
        if (keywords) {
            if (keywords?.includes(',')) {
                form.setFields([
                    {
                        name: 'keywords',
                        errors: ['Please, use new line for each keyword instead of comma'],
                    },
                ]);

                setIsInputError(true);
            } else if (duplicatedKeywords?.length > 0) {
                form.setFields([
                    {
                        name: 'keywords',
                        errors: [
                            'Please remove highlighted keywords that are already on your keyword list.',
                        ],
                    },
                ]);

                setIsInputError(true);
            } else {
                form.setFields([
                    {
                        name: 'keywords',
                        errors: [],
                    },
                ]);

                setIsInputError(false);
            }
        } else {
            form.setFields([
                {
                    name: 'keywords',
                    errors: [],
                },
            ]);

            setIsInputError(false);
        }

        // eslint-disable-next-line
    }, [keywords]);

    useEffect(() => {
        if (keywords) {
            setKeywordsArray(keywords.split('\n'));
        }
    }, [keywords]);

    const handleChangeKeywords = (value) => {
        setKeywords(value);
    };

    const handleCloseModal = () => {
        setKeywords('');
        setKeywordsArray([]);
        setError(null);
        form.resetFields();
    };

    const transformKeyword = (value = '') => {
        // Replace one specific apostrophy symbol to "'"
        return value.toLocaleLowerCase().replaceAll(RIGHT_SINGLE_QUOTATION_MARK, "'");
    };

    const duplicatedKeywords = useMemo(() => {
        if (!optimizerKeywords || !keywords) {
            return [];
        }

        return keywords
            ?.split('\n')
            ?.filter((keyword) =>
                optimizerKeywords.some(
                    (optimizerKeyword) =>
                        transformKeyword(optimizerKeyword?.keyword) ===
                        transformKeyword(keyword?.trim())
                )
            );
    }, [keywords, optimizerKeywords]);

    const handleAddCustomKeyword = () => {
        const keywordList = keywords?.split('\n').map((keyword) => normalizeKeyword(keyword)) || [];

        if (keywordList.length === 0) {
            setError("Terms can't be blank");

            return;
        }

        if (
            keywordList.some(
                (keyword) =>
                    keyword.length < MIN_KEYWORD_LENGTH ||
                    keyword.length > MAX_CUSTOM_KEYWORD_LENGTH
            )
        ) {
            form.setFields([
                {
                    name: 'keywords',
                    errors: [
                        `Keyword must be between ${MIN_KEYWORD_LENGTH} and ${MAX_CUSTOM_KEYWORD_LENGTH} characters long`,
                    ],
                },
            ]);

            setIsInputError(true);

            return;
        }

        setIsLoading(true);

        const uniqueKeywords = new Set(
            keywordList.map((keyword) => transformKeyword(keyword).trim())
        );

        const keywordsModel = [...uniqueKeywords].map((keyword) => ({
            keyword,
            source: contentOptimizerKeywordSource.custom,
        }));

        axiosAPI
            .patch(
                `${contentOptimizerPathes.editContentOptimizer}/${contentOptimizerId}`,
                {
                    keywords: JSON.stringify([...optimizerKeywords, ...keywordsModel]),
                },
                {
                    ...getAxiosHeaders(),
                    params: { slug: organizationSlug },
                }
            )
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    batch(() => {
                        dispatch(setContentOptimizerKeywords(JSON.parse(result?.data?.keywords)));
                        dispatch(updateEditorCurrentVersionScore(result?.data?.rankability_score));
                    });

                    handleOpen();
                }
            })
            .catch((error) => {
                setError(error?.response?.data?.message || 'Please try again');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <Tooltip
                title='Add custom keywords'
                overlayClassName={`${classes.tooltipOverlay} ${getTooltipOverlayClass(darkMode)}`}
                placement='top'
                getPopupContainer={getOptimizerRightBlockWrapper}
            >
                <Button
                    className={`${classes.addCustomKeywordBtn} ${getThemeColorClass(darkMode)}`}
                    type='primary'
                    size='large'
                    ghost
                    onClick={handleOpen}
                >
                    <PlusIcon color={colorPalette.colorPrimary} size={18} />
                </Button>
            </Tooltip>
            <Modal
                centered
                forceRender
                title='Add Custom Keywords'
                open={isOpen}
                onCancel={handleOpen}
                footer={null}
                closeIcon={<CloseIcon color={closeModalIconColor} />}
                wrapClassName={`${classes.modalWrapper} ${
                    darkMode ? classes.modalWrapperDark : ''
                }`}
                afterClose={handleCloseModal}
                getContainer={getOptimizerRightBlockWrapper}
            >
                <Form form={form} layout='vertical' onFinish={handleAddCustomKeyword}>
                    <div className={classes.modalFormFieldsWrapper} ref={formWrapperRef}>
                        <Title level={5}>
                            Add specific words and phrases to the keyword list inside Content
                            Optimizer. Custom keywords will not impact your Rankability Score.
                        </Title>
                        {error && <ErrorMessage errorMessage={error} />}
                        <Form.Item name='keywords'>
                            <MultipleCreateArea
                                isInputError={isInputError}
                                placeholder='Separate one per line'
                                handleChange={handleChangeKeywords}
                                value={keywords}
                                keywordArray={keywordsArray}
                                keywordsToHighlight={duplicatedKeywords}
                            />
                        </Form.Item>
                        <Title level={5}>Keyword Suggestions:</Title>
                        <ul className={classes.modalSuggestionsWrapper}>
                            {randomSuggestions?.length > 0 ? (
                                randomSuggestions?.map((suggestion, index) => (
                                    <li key={index}>
                                        <Title level={5}>{suggestion}</Title>
                                    </li>
                                ))
                            ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                            )}
                        </ul>
                    </div>
                    <div className={classes.modalFormButtonsWrapper}>
                        <Button
                            className={`${getThemeColorClass(darkMode)}`}
                            type='primary'
                            size='large'
                            ghost
                            onClick={handleOpen}
                        >
                            Cancel
                        </Button>

                        <Form.Item shouldUpdate className={classes.runBriefBtn}>
                            {() => (
                                <Button
                                    className={classes.addKeywordButton}
                                    type='primary'
                                    size='large'
                                    htmlType='submit'
                                    loading={isLoading}
                                    disabled={
                                        form
                                            .getFieldsError()
                                            .some((item) => item.errors.length > 0) || !keywords
                                    }
                                >
                                    Add Keywords
                                </Button>
                            )}
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default AddCustomKeywordModal;
