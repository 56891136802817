import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, Card, Input, Spin, Typography } from 'antd';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripePublicKey } from '../../../../../../constants/stripePublicKey';

import {
    selectDarkMode,
    selectIsSubscriptionExpired,
    selectUserData,
    selectUserOrganization,
} from '../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../utils/helpers/getAxiosHeaders';
import { getNumberWithCommaAsThousandSeparator } from '../../../../../../utils/helpers/getNumberWithCommaAsThousandSeparator';
import { getPlanByCurrencyAndInterval } from '../../../../../../utils/helpers/getPlanByCurrencyAndInterval';
import { getPlanFeaturesArray } from '../../../../../../utils/helpers/getPlanFeaturesArray';
import { getPriceId } from '../../../../../../utils/helpers/getPriceId';
import { openNotification } from '../../../../../../utils/helpers/openNotification';
import { openBasicErrorNotification } from '../../../../../../utils/helpers/openBasicErrorNotification';
import { roundPercentagesInStringByRegex } from '../../../../../../utils/helpers/regexHelpers';
import { useOrganizationSlug } from '../../../../../../utils/hooks/useOrganizationSlug';
import { usePaymentMethod } from '../../../../../../utils/hooks/usePaymentMethod';

import SubscriptionHelper from '../../../../auth/helpers/subscription.helper';
import BillingHelper from '../../../../../../utils/helpers/billingHelper';

import Loader from '../../../../../common/loader/Loader';

import { accountTabs } from '../../../../../../constants/accountTabs';
import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../../constants/notificationType';
import { paymentsPathes } from '../../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../../constants/statusCodes';

import { billingPlanIntervals } from '../../BillingConstants';
import EditPaymentMethodModal from '../../content/EditPaymentMethodModal';
import PaymentInfoSection from '../../content/PaymentInfoSection';

import { updateAccountTab } from '../../../store/accountSettings.actions';
import {
    setCurrentSubscription,
    setPlanToSwitch,
    setSubscriptionLimits,
} from '../../store/billingSettings.actions';
import {
    selectCurrentSubscription,
    selectPlanToSwitch,
    selectPlanType,
} from '../../store/billingSettings.selectors';
import TickIcon from '../../../../../../resources/icons/versionHistory/TickIcon';

const { Title } = Typography;

const classes = {
    switchedPlanCardWrapper: 'switched-plan-card-wrapper',
    planTitle: 'plan-title d-flex align-items-center',
    infoSection: 'info-section',
    label: 'label',
    totalDueSection: 'total-due-section',
    completeCheckoutButton: 'complete-checkout-button',
    editPaymentLink: 'edit-payment-link',
    benefitsList: 'benefits-list',
    darkModeTypography: 'dark-theme-typography',
};

const SwitchedPlanCard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const organizationSlug = useOrganizationSlug();

    const planType = useSelector(selectPlanType);
    const planData = useSelector(selectPlanToSwitch);
    const userData = useSelector(selectUserData);
    const darkMode = useSelector(selectDarkMode);
    const organization = useSelector(selectUserOrganization);

    const { paymentMethod, isPaymentMethodLoaded } = usePaymentMethod();
    const subscription = useSelector(selectCurrentSubscription);
    const expired = useSelector(selectIsSubscriptionExpired);

    const [isLoading, setIsLoading] = useState(false);

    const subscriptionInfoListItems = getPlanFeaturesArray(planData?.metadata, planType)?.map(
        (feature) => ({
            success: true,
            text: `${BillingHelper.getFeatureValueIfNotBooleanType(feature)} ${feature?.title}`,
        })
    );

    const price = getPlanByCurrencyAndInterval(planData, planType)?.unit_amount / 100 || 0;

    const planPrice = getNumberWithCommaAsThousandSeparator(price);

    const [upcomingInvoice, setUpcomingInvoice] = useState();

    useEffect(() => {
        if (!planData || !planType) {
            return navigate(`${organizationSlug}/account/plans`);
        }

        if (planData && planType && !expired && !SubscriptionHelper.isCanceled(subscription)) {
            axiosAPI
                .get(paymentsPathes.getUpcomingInvoice, {
                    params: { price: getPriceId(planData, planType) },
                    ...getAxiosHeaders(),
                })
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        setUpcomingInvoice(result?.data);
                    }
                })
                .catch(() => {
                    openBasicErrorNotification();
                });
        }

        return () => {
            dispatch(setPlanToSwitch(null));
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getNewQuota = () => {
        axiosAPI
            .get(paymentsPathes.getQuotes, {
                ...getAxiosHeaders(),
            })
            .then((quotaResult) => {
                if (quotaResult?.status === statusCodes.success) {
                    dispatch(setSubscriptionLimits(quotaResult?.data));
                }
            })
            .catch(() => {
                openBasicErrorNotification();
            });
    };

    const handleCompleteCheckout = () => {
        const priceId = getPriceId(planData, planType);

        if (priceId) {
            setIsLoading(true);

            axiosAPI
                .post(
                    paymentsPathes.editSubscription,
                    { price_id: priceId },
                    { ...getAxiosHeaders() }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        batch(() => {
                            if (SubscriptionHelper.isTrial(subscription, organization)) {
                                getNewQuota();
                            }

                            dispatch(
                                setCurrentSubscription({
                                    ...result?.data,
                                    plan: {
                                        ...result?.data?.plan,
                                        metadata: planData?.metadata,
                                        name: planData?.name,
                                    },
                                })
                            );

                            dispatch(updateAccountTab(accountTabs['billing-credits'].key));
                        });

                        navigate(`/${organizationSlug}/account/billing-credits`);
                    }
                })
                .catch((error) => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        error?.response?.data?.message || errorNotificationMessage
                    );
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const shouldNotGetInvoiceInfo = () =>
        !subscription ||
        SubscriptionHelper.isCanceled(subscription) ||
        expired ||
        (SubscriptionHelper.isTrial(subscription, organization) &&
            `${planType}ly` !== subscription.plan.interval);

    const total_price =
        (shouldNotGetInvoiceInfo()
            ? getNumberWithCommaAsThousandSeparator(price + subscription?.customer?.balance / 100)
            : upcomingInvoice?.amount_due / 100) || 0;

    const successfulChangeOfCard = () => {
        openNotification(
            notificationType.success,
            'Success',
            'Your card was successfully updated.'
        );
    };

    const failedChangeOfCard = () => {
        openNotification(notificationType.error, 'Error', 'Please enter a valid credit card');
    };

    return (
        <Card className={classes.switchedPlanCardWrapper}>
            <Spin spinning={isPaymentMethodLoaded} indicator={<Loader />}>
                <section className={classes.infoSection}>
                    <div className={classes.planTitle}>
                        <Title level={5}>{planData?.name}</Title>
                        <Typography>
                            (${planPrice}/{billingPlanIntervals[planType]})
                        </Typography>
                    </div>
                    <div className={classes.benefitsList}>
                        {subscriptionInfoListItems?.map((item, index) => (
                            <Typography
                                key={`benefits-list-${index}`}
                                className={`${darkMode ? classes.darkModeTypography : ''}`}
                            >
                                <TickIcon />
                                {item.text}
                            </Typography>
                        ))}
                    </div>
                </section>
                <section className={classes.infoSection}>
                    <Typography className={classes.label}>Billing email</Typography>
                    <Input value={userData?.email} disabled />
                    <Typography className={classes.label}>Receipts are sent here</Typography>
                </section>
                <section className={classes.infoSection}>
                    {subscription?.customer?.balance ? (
                        <div className='d-flex justify-content-between align-item-center'>
                            <Typography>Your customer balance</Typography>
                            <Typography>${subscription?.customer?.balance / 100}</Typography>
                        </div>
                    ) : null}
                    {shouldNotGetInvoiceInfo() ? (
                        <div className='d-flex justify-content-between align-item-center'>
                            <Typography>{planData?.name}</Typography>
                            <Typography>${planPrice}</Typography>
                        </div>
                    ) : (
                        upcomingInvoice?.items?.map((item, index) => {
                            return (
                                <div
                                    className='d-flex justify-content-between align-item-center'
                                    key={index}
                                >
                                    <Typography>
                                        {roundPercentagesInStringByRegex(item?.description || '')}
                                    </Typography>
                                    <Typography>${item?.amount / 100 || 0}</Typography>
                                </div>
                            );
                        })
                    )}
                    <div className='d-flex justify-content-between align-item-center'>
                        <Title level={5}>Total due</Title>
                        <Title level={5}>${total_price}</Title>
                    </div>
                    <Typography className={classes.label}>
                        You will be charged ${total_price} today and your {planData?.name}{' '}
                        Subscription will automatically renew {billingPlanIntervals[planType]}ly
                        until canceled.
                    </Typography>
                </section>
                <PaymentInfoSection paymentMethod={paymentMethod} />
                <div className='d-flex justify-content-between align-items-center'>
                    <Elements stripe={loadStripe(stripePublicKey)}>
                        <EditPaymentMethodModal
                            onSuccess={successfulChangeOfCard}
                            onFailed={failedChangeOfCard}
                            linkClassName={classes.editPaymentLink}
                        />
                    </Elements>
                    <Button
                        type='primary'
                        className={classes.completeCheckoutButton}
                        onClick={handleCompleteCheckout}
                        loading={isLoading}
                    >
                        Complete checkout
                    </Button>
                </div>
            </Spin>
        </Card>
    );
};

export default SwitchedPlanCard;
