import { useCallback, useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

import { editorVersionAutoCreationDelay } from '../constants/tiptapEditorConstants';

/**
 * A hook that handles automatic versioning of content based on changes.
 * It creates a new version after a delay when content changes and is not blocked.
 * Skips initial content setup to avoid unnecessary version creation.
 *
 * @param {Object} params - The parameters object
 * @param {any} params.content - The content to track for changes. Can be any serializable value.
 * @param {boolean} params.isBlocked - When true, prevents version creation (e.g., during loading states)
 * @param {Object} params.versionHandlers - Object containing version management callbacks
 * @param {Function} params.versionHandlers.create - Async function to create a new version
 * @param {Function} [params.versionHandlers.fetch] - Optional async function to fetch versions after creation
 *
 * @example
 * useAutoVersioning({
 *   content: editorContent,
 *   isBlocked: isLoading || isAIProcessing,
 *   versionHandlers: {
 *     create: createNewVersion,
 *     fetch: fetchVersions
 *   }
 * });
 */
const useAutoVersioning = ({ content, isBlocked, versionHandlers }) => {
    const versioningTimeoutRef = useRef(null);
    const lastContentRef = useRef(null);
    const isInitialMount = useRef(true);

    const handleVersioning = useCallback(async () => {
        await versionHandlers.create();
        if (versionHandlers.fetch) {
            await versionHandlers.fetch();
        }
    }, [versionHandlers]);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            lastContentRef.current = content;

            return;
        }

        if (!content || isBlocked) {
            return;
        }

        if (isEqual(lastContentRef.current, content)) {
            return;
        }

        lastContentRef.current = content;

        if (versioningTimeoutRef.current) {
            clearTimeout(versioningTimeoutRef.current);
        }

        versioningTimeoutRef.current = setTimeout(() => {
            handleVersioning();
        }, editorVersionAutoCreationDelay);

        return () => {
            if (versioningTimeoutRef.current) {
                clearTimeout(versioningTimeoutRef.current);
                versioningTimeoutRef.current = null;
            }
        };
    }, [content, isBlocked, handleVersioning]);
};

export default useAutoVersioning;
