import React, { useState, useEffect } from 'react';

import { Button } from 'antd';

import { getAxiosHeaders } from '../../../../../../utils/helpers/getAxiosHeaders';
import { axiosAPI } from '../../../../../../utils/axiosAPI';
import { openBasicErrorNotification } from '../../../../../../utils/helpers/openBasicErrorNotification';

import { paymentsPathes } from '../../../../../../constants/queryPathes';

const DownloadReceiptButton = ({ invoiceId }) => {
    const [receiptUrl, setReceiptUrl] = useState('');

    useEffect(() => {
        if (!invoiceId) return;

        axiosAPI
            .get(`${paymentsPathes.getReceipt}/${invoiceId}/receipt`, {
                ...getAxiosHeaders(),
            })
            .then((result) => {
                setReceiptUrl(result.data?.receipt_url);
            })
            .catch(() => {
                openBasicErrorNotification();
            });
    }, [invoiceId]);

    return (
        <a
            className='w-100'
            href={receiptUrl}
            target='_blank'
            disabled={!receiptUrl}
            rel='noreferrer'
        >
            {/* 
               alternatively, you can move href to the Button component,
                but styles get screwed up when you do, so I decided to leave it inside <a>
            */}
            <Button type='primary' disabled={!receiptUrl}>
                Download receipt
            </Button>
        </a>
    );
};

export default DownloadReceiptButton;
