import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Checkbox, Tag, Typography, Tooltip } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { getNumberWithCommaSeparator } from '../../../../utils/helpers/getNumberWithCommaSeparator';
import { getThemeColorClass } from '../../../../utils/helpers/themeHelpers';

import MoreHorizontalIcon from '../../../../resources/icons/MoreHorizontalIcon';
import { colorPalette } from '../../../../resources/styles/colorPalette';

import CancelCreationButton from '../../../common/buttons/CancelCreationButton';
import CompleteBadge from '../../../common/completeBadge/CompleteBadge';
import CreateInfoSection from '../../../common/mainPagesLists/CreateInfoSection';
import FlagCountrySection from '../../../common/mainPagesLists/FlagCountrySection';
import RankabilityLoader from '../../../common/rankabilityLoader/RankabilityLoader';

import ContentOptimizerShareDropdown from '../mainPage/content/ContentOptimizerShareDropdown';
import { updateContentOptimizerShared } from '../mainPage/store/contentOptimizerMain.actions';

const { Link, Title } = Typography;

const classes = {
    cardWrapper: 'd-flex card-wrapper w-100',
    listCardWrapper: 'd-flex list-card-wrapper list-left-block',
    listCardHeader: 'd-flex card-header',
    listKeywordWrapper: 'd-flex w-100 align-items-center',
    listRightBlock: 'list-right-block d-flex align-items-center',
    listCompleteInfoBlock:
        'list-complete-info d-flex align-items-center justify-content-between w-100',
    listLinkWrapper: 'list-link-wrapper',
    listLinkItemTitle: 'list-link-item-title',
    listLink: 'list-link',
    itemNotCompleted: 'item-not-completed',
    listKanbanMoreButton: 'list-kanban-more-button',
    listMoreButton:
        'list-more-button d-flex flex-center align-items-center btn-hover-light-primary',
    listWordCount: 'list-word-count space-no-wrap',
    breakRow: 'flex-break-row',
    projectsListWrapper: 'd-flex w-100',
    projectsWrapper: 'projects-wrapper d-flex align-items-center',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
    emptySpace: 'empty-space',
    rankabilityLoaderWrapper: 'rankability-loader-wrapper',
};

const OptimizerListView = ({
    optimizerItem,
    handleContentOptimizerNavigate,
    isOptimizerItemCompleted,
    users,
    showEmptySpace,
    startedStatus,
    renderCancelButton,
    cancelItem,
    disabled,
}) => {
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const themeColorClass = getThemeColorClass(darkMode);

    const preventRedirect = (e) => {
        e?.preventDefault();
        handleContentOptimizerNavigate(e);
    };

    const manageContextMenuHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    };

    const optimizerProjects = optimizerItem?.content_brief?.projects || [];
    const isProjectOrUrlAvailable =
        optimizerProjects.length > 0 || optimizerItem?.content_brief?.url?.length > 0;

    return (
        <div className={classes.cardWrapper}>
            <div className={classes.listCardWrapper}>
                <div className={classes.listCardHeader}>
                    <div className={classes.listKeywordWrapper}>
                        <Checkbox value={optimizerItem.id} />
                        <div className={classes.listLinkWrapper}>
                            <div className={classes.listLinkItemTitle}>
                                <Link
                                    className={`${classes.listLink} ${
                                        !isOptimizerItemCompleted && classes.itemNotCompleted
                                    } ${themeColorClass}`}
                                    href={
                                        isOptimizerItemCompleted
                                            ? `optimizer/${optimizerItem?.id}`
                                            : 'optimizer'
                                    }
                                    onClick={preventRedirect}
                                    onContextMenu={
                                        !isOptimizerItemCompleted || disabled
                                            ? manageContextMenuHandler
                                            : undefined
                                    }
                                >
                                    {optimizerItem.content_brief.keywords}
                                </Link>
                                <FlagCountrySection
                                    countryCode={optimizerItem?.content_brief?.country_code}
                                    languageCode={optimizerItem?.content_brief?.language_code}
                                    concreteLocation={
                                        optimizerItem?.content_brief?.geo_location_obj
                                    }
                                />
                            </div>
                            <div className={classes.breakRow}></div>
                            <div className={classes.projectsListWrapper}>
                                {isProjectOrUrlAvailable ? (
                                    <div className={classes.projectsWrapper}>
                                        {optimizerProjects.map((project) => (
                                            <Tag color='processing' key={project.id}>
                                                {project.name}
                                            </Tag>
                                        ))}
                                        <div className={classes.listLinkWrapper}>
                                            {optimizerItem?.content_brief?.url && (
                                                <Tooltip
                                                    placement='top'
                                                    title={optimizerItem.content_brief.url}
                                                    trigger={['hover']}
                                                    overlayClassName={`${classes.tooltipOverlay} ${
                                                        darkMode ? classes.tooltipOverlayDark : ''
                                                    }`}
                                                >
                                                    <Typography className='text-overflow-ellipsis space-no-wrap'>
                                                        {optimizerItem.content_brief.url}
                                                    </Typography>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <CreateInfoSection
                    createdByUserId={optimizerItem.user_id}
                    createdAt={optimizerItem.created_at}
                    timeFromNow={!isOptimizerItemCompleted}
                />
            </div>
            <div className={classes.listRightBlock}>
                {!disabled ? (
                    isOptimizerItemCompleted ? (
                        <>
                            <div className={classes.listCompleteInfoBlock}>
                                <Title
                                    level={5}
                                    className={`${classes.listWordCount} ${themeColorClass}`}
                                >
                                    {`Word Count: ${
                                        getNumberWithCommaSeparator(optimizerItem.word_count) || 0
                                    }`}
                                </Title>
                                <CompleteBadge
                                    size={'small'}
                                    progress={Math.ceil(optimizerItem.rankability_score) || 0}
                                />
                            </div>
                            <div className={`${showEmptySpace ? classes.emptySpace : ''}`} />
                            {!disabled && (
                                <ContentOptimizerShareDropdown
                                    optimizerId={optimizerItem.id}
                                    briefId={optimizerItem?.content_brief_id}
                                    isShared={optimizerItem?.shared}
                                    shareLink={optimizerItem?.share_link?.url_code}
                                    onChangedSharedCallback={(checked) =>
                                        dispatch(
                                            updateContentOptimizerShared({
                                                id: optimizerItem.id,
                                                optimizer: checked,
                                            })
                                        )
                                    }
                                >
                                    <Button
                                        className={`${classes.listMoreButton} ${themeColorClass}`}
                                    >
                                        <MoreHorizontalIcon color={colorPalette.basicGray} />
                                    </Button>
                                </ContentOptimizerShareDropdown>
                            )}
                        </>
                    ) : (
                        <div
                            className={classes.rankabilityLoaderWrapper}
                            style={{
                                flexDirection: 'row',
                            }}
                        >
                            <RankabilityLoader
                                percentageStatus={optimizerItem?.queue_progress}
                                optimizerStatus={optimizerItem?.queue_status}
                                startedStatus={startedStatus}
                            />
                            {renderCancelButton ? (
                                <CancelCreationButton
                                    popconfirmTitle='Are you sure you want to cancel this Optimizer?'
                                    creationDate={
                                        optimizerItem?.content_brief?.user_created_at_time ||
                                        optimizerItem?.created_at
                                    }
                                    handleCancel={cancelItem}
                                />
                            ) : (
                                <div
                                    className={`d-flex align-items-center justify-content-between`}
                                >
                                    <Button
                                        className={`${classes.listMoreButton} ${themeColorClass}`}
                                    >
                                        <MoreHorizontalIcon color={colorPalette.basicGray} />
                                    </Button>
                                </div>
                            )}
                        </div>
                    )
                ) : (
                    <Typography className='content-optimizer-list-view-disabled-message'>
                        Unfortunately, your Content Optimizer request has encountered an issue.
                        Please delete this Optimizer and run it again.
                    </Typography>
                )}
            </div>
        </div>
    );
};

export default OptimizerListView;
